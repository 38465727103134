import Vue from "vue";
import eventBus from "../../../main";
import apiServices from "../../../../services/apiServices";

export default {
  state: {
    entity: {
      profile: {
        name: "",
        commercialName: "",
        nif: "",
        address: "",
        cellphone: "",
        subdomain: "",
      },
      entity: "",
    },
    gatewayKyami: Object,
    entityUsers: Array,
    namesToUpdate: Object,
    bankAccount: "",
    imageEntity: "",
  },
  mutations: {
    SET_ENTITY(state, value) {
      state.entity.profile.name = value.name;
      state.entity.profile.nif = value.nif;
      state.entity.profile.subdomain = value.url;
      state.entity.profile.commercialName = value.common;
      state.entity.profile.address = value.address.name;
      state.entity.profile.cellphone = value.address.cellphone;
      state.entity.entity = value;
    },

    SET_ALL_USERS(state, value) {
      state.entityUsers = value;
    },

    SET_BANK_ACCOUNT(state, value) {
      state.bankAccount = value;
    },

    SET_NAMES_USER_TO_UPDATE(state, value) {
      state.namesToUpdate = value;
    },

    SET_REMOVE_USER(state, value) {
      state.entityUsers.victoria.splice(value, 1);
    },

    SET_GATEWAY_KYAMI(state, value) {
      state.gatewayKyami = value;
    },

    SET_IMAGE_DOCUMENT(state, value) {
      state.imageEntity = value;
    },
  },
  actions: {
    /**
     * Edit Entity
     *
     */

    saveNamesUser(context, payload) {
      let names = {
        first_name: payload.first_name,
        last_name: payload.last_name,
        email: payload.email,
      };
      return context.commit("SET_NAMES_USER_TO_UPDATE", names);
    },

    callEntity(context, payload) {
      // eventBus.$emit("openSpin", true);
      apiServices
        .callEntity(payload)
        .then(function (response) {
          if (response.status === 200) {
            let obj = response.data;
            return context.commit("SET_ENTITY", obj.victoria);
          }
          if (response.status === 422) {
            let message = response.data.message;
            return Vue.toasted.error(message, {
              theme: "bubble",
              position: "top-center",
              duration: 4000,
            });
          }
          if (response.status === 401 || response.status === 403) {
            return "";
          }
          return Vue.toasted.error("Pedido não processado", {
            theme: "bubble",
            position: "top-center",
            duration: 4000,
          });
        })
        .catch(function (error) {
          return Vue.toasted.error(error, {
            theme: "bubble",
            position: "top-center",
            duration: 4000,
          });
        })
        .finally(function () {
          eventBus.$emit("openSpin", false);
        });
    },

    callEntityUsers(context, payload) {
      // eventBus.$emit("openSpin", true);
      apiServices
        .goEntityUsers(payload)
        .then(function (response) {
          if (response.status === 200) {
            let obj = response.data;
            //console.log(obj.victoria);
            return context.commit("SET_ALL_USERS", obj);
          }
          if (response.status === 422) {
            let message = response.data.message;
            return Vue.toasted.error(message, {
              theme: "bubble",
              position: "top-center",
              duration: 4000,
            });
          }
          if (response.status === 401 || response.status === 403) {
            return "";
          }
          return Vue.toasted.error("Pedido não processado", {
            theme: "bubble",
            position: "top-center",
            duration: 4000,
          });
        })
        .catch(function (error) {
          return Vue.toasted.error(error, {
            theme: "bubble",
            position: "top-center",
            duration: 4000,
          });
        })
        .finally(function () {
          eventBus.$emit("openSpin", false);
        });
    },

    callEntityUsersNext(context, payload) {
      eventBus.$emit("openSpin", true);
      apiServices
        .goEntityUsersNext(payload)
        .then(function (response) {
          if (response.status === 200) {
            let obj = response.data;
            //console.log(obj.victoria);
            context.commit("SET_ALL_USERS", obj);
            return;
          }
          if (response.status === 422) {
            let message = response.data.message;
            return Vue.toasted.error(message, {
              theme: "bubble",
              position: "top-center",
              duration: 4000,
            });
          }
          if (response.status === 401 || response.status === 403) {
            return "";
          }
          return Vue.toasted.success("Pedido não processado", {
            theme: "bubble",
            position: "top-center",
            duration: 4000,
          });
        })
        .catch(function () {
          return Vue.toasted.error("Dados actualizados com sucesso!", {
            theme: "bubble",
            position: "top-center",
            duration: 4000,
          });
        })
        .finally(function () {
          eventBus.$emit("openSpin", false);
        });
    },

    callUpdateUser(context, payload) {
      eventBus.$emit("openSpin", true);
      apiServices
        .updateUser(payload)
        .then(function (response) {
          if (response.status === 200) {
            return Vue.toasted.success("Dados actualizados com sucesso!", {
              theme: "bubble",
              position: "top-center",
              duration: 4000,
            });
          }
          if (response.status === 422) {
            let message = response.data.message;
            return Vue.toasted.error(message, {
              theme: "bubble",
              position: "top-center",
              duration: 4000,
            });
          }

          if (response.status === 401 || response.status === 403) {
            return "";
          }
          return Vue.toasted.error("Pedido não processado", {
            theme: "bubble",
            position: "top-center",
            duration: 4000,
          });
        })
        .catch(function () {
          return Vue.toasted.error("Pedido não processado", {
            theme: "bubble",
            position: "top-center",
            duration: 4000,
          });
        })
        .finally(function () {
          eventBus.$emit("openSpin", false);
        });
    },

    callUpdatePwd(context, payload) {
      eventBus.$emit("openSpin", true);
      apiServices
        .updatePwd(payload)
        .then(function (response) {
          if (response.status === 200) {
            return Vue.toasted.success(
              "Palavra-passe actualizada com sucesso!",
              {
                theme: "bubble",
                position: "top-center",
                duration: 4000,
              }
            );
          }
          if (response.status === 422) {
            let message = response.data.message;
            return Vue.toasted.error(message, {
              theme: "bubble",
              position: "top-center",
              duration: 4000,
            });
          }

          if (response.status === 401 || response.status === 403) {
            return "";
          }
          return Vue.toasted.error("Pedido não processado", {
            theme: "bubble",
            position: "top-center",
            duration: 4000,
          });
        })
        .catch(function () {
          return Vue.toasted.error("Error", {
            theme: "bubble",
            position: "top-center",
            duration: 4000,
          });
        })
        .finally(function () {
          eventBus.$emit("openSpin", false);
        });
    },

    callDeleteUser(context, payload) {
      eventBus.$emit("openSpin", true);
      apiServices
        .deleteUser(payload.id)
        .then(function (response) {
          if (response.status === 200) {
            context.commit("SET_REMOVE_USER", payload.index);
            return Vue.toasted.success("Utilizador apagado com sucesso!", {
              theme: "bubble",
              position: "top-center",
              duration: 4000,
            });
          }
          if (response.status === 422) {
            let message = response.data.message;
            return Vue.toasted.error(message, {
              theme: "bubble",
              position: "top-center",
              duration: 4000,
            });
          }

          if (response.status === 400) {
            let message = response.data;
            return Vue.toasted.error(message, {
              theme: "bubble",
              position: "top-center",
              duration: 4000,
            });
          }

          if (response.status === 401 || response.status === 403) {
            return "";
          }

          return Vue.toasted.error("Pedido não processado", {
            theme: "bubble",
            position: "top-center",
            duration: 4000,
          });
        })
        .catch(function () {
          Vue.toasted.error("Pedido não processado", {
            theme: "bubble",
            position: "top-center",
            duration: 4000,
          });
        })
        .finally(function () {
          eventBus.$emit("openSpin", false);
        });
    },

    callAddUser(context, payload) {
      eventBus.$emit("openSpin", true);
      apiServices
        .goAdduser(payload)
        .then(function (response) {
          if (response.status === 202) {
            return Vue.toasted.success("Utilizador adicionado com sucesso!", {
              theme: "bubble",
              position: "top-center",
              duration: 4000,
            });
          }
          if (response.status === 422) {
            let message = response.data.message;
            return Vue.toasted.error(message, {
              theme: "bubble",
              position: "top-center",
              duration: 4000,
            });
          }

          return Vue.toasted.error("Pedido não processado", {
            theme: "bubble",
            position: "top-center",
            duration: 4000,
          });
        })
        .catch(function () {
          return Vue.toasted.error("Pedido não processado", {
            theme: "bubble",
            position: "top-center",
            duration: 4000,
          });
        })
        .finally(function () {
          eventBus.$emit("openSpin", false);
        });
    },

    addAvatar(context, payload) {
      eventBus.$emit("openSpin", true);
      apiServices
        .addAvatarEntity(payload)
        .then(function (response) {
          if (response.status === 200) {
            return Vue.toasted.success("Imagem inserida com sucesso!", {
              theme: "bubble",
              position: "top-center",
              duration: 4000,
            });
          }
          if (response.status === 422) {
            let message = response.data.message;
            return Vue.toasted.error(message, {
              theme: "bubble",
              position: "top-center",
              duration: 4000,
            });
          }
          return Vue.toasted.error("Pedido não processado", {
            theme: "bubble",
            position: "top-center",
            duration: 4000,
          });
        })
        .catch(function () {
          return Vue.toasted.error("Pedido não processado", {
            theme: "bubble",
            position: "top-center",
            duration: 4000,
          });
        })
        .finally(function () {
          eventBus.$emit("openSpin", false);
        });
    },

    getLogoEntity(context) {
      apiServices
        .getAvatarEntity()
        .then(function (response) {
          if (response.status === 200) {
            let image = response.data.image;
            console.log(image);
            return context.commit("SET_IMAGE_DOCUMENT", image);
          }

          if (response.status === 401 || response.status === 403) {
            return "";
          }
        })
        .catch(function () {})
        .finally(function () {});
    },

    updateEntity(context, payload) {
      eventBus.$emit("openSpin", true);
      apiServices
        .goEditEntity(payload)
        .then(function (response) {
          if (response.status === 200) {
            return Vue.toasted.success("Dados actualizados com sucesso!", {
              theme: "bubble",
              position: "top-center",
              duration: 4000,
            });
          }
          if (response.status === 422) {
            let message = response.data.message;
            return Vue.toasted.error(message, {
              theme: "bubble",
              position: "top-center",
              duration: 4000,
            });
          }
          return Vue.toasted.error("Pedido não processado", {
            theme: "bubble",
            position: "top-center",
            duration: 4000,
          });
        })
        .catch(function () {
          return Vue.toasted.error("Pedido não processado", {
            theme: "bubble",
            position: "top-center",
            duration: 4000,
          });
        })
        .finally(function () {
          eventBus.$emit("openSpin", false);
        });
    },

    getBank(context) {
      apiServices
        .goGetBankAccount()
        .then(function (response) {
          if (response.status === 200) {
            let obj = response.data;
            return context.commit("SET_BANK_ACCOUNT", obj.victoria.account);
          }

          if (response.status === 401 || response.status === 403) {
            return "";
          }
        })
        .catch(function () {
          return Vue.toasted.error("Pedido não processado", {
            theme: "bubble",
            position: "top-center",
            duration: 4000,
          });
        })
        .finally(function () {
          eventBus.$emit("openSpin", false);
        });
    },

    updateBankAccount(context, payload) {
      eventBus.$emit("openSpin", true);
      apiServices
        .goUpdateBankAccount(payload)
        .then(function (response) {
          if (response.status === 200) {
            return Vue.toasted.success("Dados actualizados com sucesso!", {
              theme: "bubble",
              position: "top-center",
              duration: 4000,
            });
          }
          if (response.status === 422) {
            let message = response.data.message;
            return Vue.toasted.error(message, {
              theme: "bubble",
              position: "top-center",
              duration: 4000,
            });
          }
          return Vue.toasted.error("Pedido não processado", {
            theme: "bubble",
            position: "top-center",
            duration: 4000,
          });
        })
        .catch(function () {
          return Vue.toasted.error("Pedido não processado", {
            theme: "bubble",
            position: "top-center",
            duration: 4000,
          });
        })
        .finally(function () {
          eventBus.$emit("openSpin", false);
        });
    },

    saveGateway(context, payload) {
      eventBus.$emit("openSpin", true);
      apiServices
        .goGateway(payload)
        .then(function (response) {
          if (response.status === 200) {
            return Vue.toasted.success("Dados inseridos com sucesso!", {
              theme: "bubble",
              position: "top-center",
              duration: 4000,
            });
          }
          if (response.status === 422) {
            let message = response.data.message;
            return Vue.toasted.error(message, {
              theme: "bubble",
              position: "top-center",
              duration: 4000,
            });
          }
          return Vue.toasted.error("Pedido não processado", {
            theme: "bubble",
            position: "top-center",
            duration: 4000,
          });
        })
        .catch(function () {
          return Vue.toasted.error("Pedido não processado", {
            theme: "bubble",
            position: "top-center",
            duration: 4000,
          });
        })
        .finally(function () {
          eventBus.$emit("openSpin", false);
        });
    },

    getKGateway(context, payload) {
      // eventBus.$emit("openSpin", true);
      apiServices
        .getPropsKyamiGateway(payload)
        .then(function (response) {
          if (response.status === 200) {
            let obj = response.data;
            return context.commit("SET_GATEWAY_KYAMI", obj.victoria);
          }
          if (response.status === 422) {
            let message = response.data.message;
            return Vue.toasted.error(message, {
              theme: "bubble",
              position: "top-center",
              duration: 4000,
            });
          }

          if (response.status === 401 || response.status === 403) {
            return "";
          }
          return Vue.toasted.info("Sem informações por mostrar", {
            theme: "bubble",
            position: "top-center",
            duration: 4000,
          });
        })
        .catch(function () {
          return Vue.toasted.error("Pedido não processado", {
            theme: "bubble",
            position: "top-center",
            duration: 4000,
          });
        })
        .finally(function () {
          eventBus.$emit("openSpin", false);
        });
    },

    getSaftT(context, payload) {
      eventBus.$emit("openSpin", true);
      apiServices
        .getSaftTAgt(payload)
        .then(function (response) {
          if (response.status === 200) {
            Vue.toasted.success("A Gerar Documento SAFT", {
              theme: "bubble",
              position: "top-center",
              duration: 4000,
            });

            const blob = new Blob([response.data]);
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            const d = new Date();
            let year =
              d.getFullYear() +
              "_" +
              d.getMonth() +
              "_" +
              d.getDay() +
              "_" +
              d.getSeconds();
            const nameReport = "Victoria_saft_t_" + year + ".xml";
            link.setAttribute("download", nameReport);
            document.body.appendChild(link);
            link.click();
            return;
          }
          if (response.status === 400) {
            return Vue.toasted.error(
              "Não há Ficheiro SAFT para o ano solicitado",
              {
                theme: "bubble",
                position: "top-center",
                duration: 4000,
              }
            );
          }

          if (response.status === 401 || response.status === 403) {
            return "";
          }
          return Vue.toasted.info(
            "Não há Ficheiro SAFT para o ano solicitado",
            {
              theme: "bubble",
              position: "top-center",
              duration: 4000,
            }
          );
        })
        .catch(function () {
          return Vue.toasted.error("Pedido não processado", {
            theme: "bubble",
            position: "top-center",
            duration: 4000,
          });
        })
        .finally(function () {
          eventBus.$emit("openSpin", false);
        });
    },
  },

  getters: {
    getCommercialNameEntity(state) {
      return state.entity.profile.commercialName;
    },
    getNameEntity(state) {
      return state.entity.profile.name;
    },
    getNifEntity(state) {
      return state.entity.profile.nif;
    },
    getAddressEntity(state) {
      return state.entity.profile.address;
    },
    getEntityCellphone(state) {
      return state.entity.profile.cellphone;
    },
    getEntityDomain(state) {
      return state.entity.profile.subdomain;
    },

    getAllUsers(state) {
      return state.entityUsers;
    },

    getNamesToUpdate(state) {
      return state.namesToUpdate;
    },

    getGatewayKyami(state) {
      return state.gatewayKyami;
    },

    getBankAccount(state) {
      return state.bankAccount;
    },

    getImageToInvoice(state) {
      return state.imageEntity;
    },
    // getAddressEntity(state) {
    //     return state.entity.profile.nif;
    // },
  },
};
