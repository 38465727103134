import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/auth/Auth";
import ChangePassword from "../views/auth/ChangePassword";
import Home from "../views/application/Home";
import apiServices from "../../services/apiServices";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import CreateInvoice from "../views/invoice/PageCreateInvoice";
import Articles from "../views/articles/Articles";
import PageCreateArticle from "../views/articles/PageCreateArticle";
import PageEditArticle from "../views/articles/PageEditArticle";
import Documents from "../views/documnets/Documents";
import PageListDocumentDetails from "../views/documnets/PageListDocumentDetails";
import CreateClient from "../views/client/CreateClient";
import PageListClient from "../views/client/PageListClient";
import LandingPage from "../views/website/LandingPage";
import CreateAccount from "../views/auth/CreateAccount";
import Subscription from "../views/subscription/Subscription";
import EntityAccount from "../views/entity/EntityAccount";
import EntityLogo from "../views/entity/EntityLogo";
import EntityUsers from "../views/entity/EntityUsers";
import PageEditUser from "../views/entity/PageEditUser";
import PageAddUser from "../views/entity/PageAddUser";
import PageViewAndEditClient from "../views/client/PageViewAndEditClient";
import PageGateway from "../views/gateway/PageGateway";
import PageAddAccountBank from "../views/entity/PageAddAccountBank";
import PageProfile from "../views/profile/PageProfile";
import PageAgtSaft from "../views/agt/PageAgtSaft";
import ForceChangePassword from "../views/auth/ForceChangePassword";
import { genericAuth } from "../middleware/genericAuth";
import { middlewareSpecific } from "../middleware/specificAuth";
import { middlewareMultiStage } from "../middleware/middleware";
import Terms from "../views/website/Terms";
// import middleware from "../middleware/middleware";
// import { genericAuth } from "../middleware/genericAuth";

Vue.use(VueRouter);

const routes = [
  {
    path: "*",
    name: "NotFound",
    component: () =>
      import(
        /* webpackChunkName : NotFound */
        "../views/NotFound.vue"
      ),
  },

  {
    path: "/",
    name: "landing.page",
    component: LandingPage,
    meta: {
      title: "Vitória - Software de facturação Online",
    },
  },

  {
    path: "/login",
    name: "login",
    component: Login,
    meta: {
      title: "Vitória - Acesso",
    },
  },

  {
    path: "/change/password",
    name: "force",
    component: ForceChangePassword,
    meta: {
      title: "Vitória - Alterar Palavra-passe",
    },
  },
  {
    path: "/create/account",
    name: "create.account",
    component: CreateAccount,
    meta: {
      title: "Vitória - Criar Conta",
    },
  },

  {
    path: "/terms",
    name: "terms",
    component: Terms,
    meta: {
      title: "Vitória - Termos e Políticas de privacidade",
    },
  },
  {
    path: "/reset/password/:id",
    name: "change.login",
    component: ChangePassword,
    props: true,
    meta: {
      title: "Vitória - Recuperar palavra-passe",
    },
  },

  {
    path: "/console",
    name: "victoria",
    component: Home,
    meta: {
      title: "Console - Victória Invoice",
      requiresAuth: true,
      roles: ["admin", "gestor", "operator", "accounting"],
    },
    beforeEnter: (to, from, next) => {
      genericAuth(to, from, next, [
        "admin",
        "gestor",
        "operator",
        "accounting",
      ]);
    },
    children: [
      {
        path: "/create/document",
        name: "create.invoice",
        component: CreateInvoice,
        // props: true,
        beforeEnter: (to, from, next) => {
          middlewareSpecific(
            to,
            from,
            next,
            ["admin", "gestor", "operator"],
            "callArticles"
          );
        },
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/list/documents",
        name: "list.documents",
        component: Documents,
        beforeEnter: (to, from, next) => {
          middlewareSpecific(
            to,
            from,
            next,
            ["admin", "gestor", "operator", "accounting"],
            "getAllOrder"
          );
        },
        meta: {
          requiresAuth: true,
        },
      },

      {
        path: "/documents/view/:id",
        name: "documents.view",
        component: PageListDocumentDetails,
        props: true,
        beforeEnter: (to, from, next) => {
          middlewareSpecific(
            to,
            from,
            next,
            ["admin", "gestor", "operator", "accounting"],
            "callArticles"
          );
        },
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/articles",
        name: "articles",
        component: Articles,
        beforeEnter: (to, from, next) => {
          middlewareSpecific(
            to,
            from,
            next,
            ["admin", "gestor", "operator", "accounting"],
            "callArticles"
          );
        },
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/articles/create",
        name: "articles.create",
        component: PageCreateArticle,
        beforeEnter: (to, from, next) => {
          genericAuth(to, from, next, ["admin", "gestor"]);
        },
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/articles/edit/:id",
        name: "articles.edit",
        component: PageEditArticle,
        props: true,
        beforeEnter: (to, from, next) => {
          middlewareSpecific(to, from, next, ["admin", "gestor"], "callArticles");
        },
        meta: {
          requiresAuth: true,
        },
      },

      {
        path: "/client/create",
        name: "create.client",
        component: CreateClient,
        props: true,
        beforeEnter: (to, from, next) => {
          middlewareSpecific(
            to,
            from,
            next,
            ["admin", "gestor", "operator"],
            "callArticles"
          );
        },
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/client/list",
        name: "list.client",
        component: PageListClient,
        props: true,
        beforeEnter: (to, from, next) => {
          middlewareSpecific(
            to,
            from,
            next,
            ["admin", "gestor", "operator", "accounting"],
            "callClients"
          );
        },
        meta: {
          requiresAuth: true,
        },
      },

      {
        path: "/client/view/edit/:id",
        name: "edit.client",
        component: PageViewAndEditClient,
        props: true,
        beforeEnter: (to, from, next) => {
          middlewareSpecific(
            to,
            from,
            next,
            ["admin", "gestor", "operator"],
            "callClients"
          );
        },
        meta: {
          requiresAuth: true,
        },
      },

      {
        path: "/subscription/overview",
        name: "subscription.overview",
        component: Subscription,
        props: true,
        beforeEnter: (to, from, next) => {
          middlewareSpecific(
            to,
            from,
            next,
            ["admin", "gestor"],
            "getSubscription"
          );
        },
        meta: {
          requiresAuth: true,
        },
      },

      {
        path: "/entity/account",
        name: "entity.account",
        component: EntityAccount,
        props: true,
        beforeEnter: (to, from, next) => {
          middlewareSpecific(
            to,
            from,
            next,
            ["admin", "gestor", "operator"],
            "callEntity"
          );
        },
        meta: {
          requiresAuth: true,
        },
      },

      {
        path: "/entity/account/logo",
        name: "entity.logo",
        component: EntityLogo,
        props: true,
        beforeEnter: (to, from, next) => {
          middlewareMultiStage(
            to,
            from,
            next,
            ["admin", "gestor", "operator"],
            "callEntity",
            "getLogoEntity"
          );
        },
        meta: {
          requiresAuth: true,
        },
      },

      {
        path: "/entity/account/users",
        name: "entity.users",
        component: EntityUsers,
        props: true,
        beforeEnter: (to, from, next) => {
          middlewareSpecific(
            to,
            from,
            next,
            ["admin", "gestor", "operator"],
            "callEntityUsers"
          );
        },
        meta: {
          requiresAuth: true,
        },
      },

      {
        path: "/entity/account/users/edit/:id",
        name: "user.edit",
        component: PageEditUser,
        props: true,
        beforeEnter: (to, from, next) => {
          middlewareSpecific(
            to,
            from,
            next,
            ["admin", "gestor", "operator"],
            "callEntityUsers"
          );
        },

        meta: {
          requiresAuth: true,
        },
      },

      {
        path: "/entity/account/user/profile",
        name: "user.profile",
        component: PageProfile,
        props: true,
        beforeEnter: (to, from, next) => {
          genericAuth(to, from, next, [
            "admin",
            "gestor",
            "operator",
            "accounting",
          ]);
        },
        meta: {
          requiresAuth: true,
        },
      },

      {
        path: "/entity/account/users/add",
        name: "user.add",
        component: PageAddUser,
        props: true,
        beforeEnter: (to, from, next) => {
          middlewareSpecific(
            to,
            from,
            next,
            ["admin", "gestor", "operator"],
            "callEntityUsers"
          );
        },
        meta: {
          requiresAuth: true,
        },
      },

      {
        path: "/entity/bank",
        name: "entity.bank",
        component: PageAddAccountBank,
        props: true,
        beforeEnter: (to, from, next) => {
          middlewareMultiStage(
            to,
            from,
            next,
            ["admin", "gestor", "operator"],
            "callEntityUsers",
            "getBank"
          );
        },
        meta: {
          requiresAuth: true,
        },
      },

      {
        path: "/entity/gateway/payment",
        name: "gateway.payment",
        component: PageGateway,
        props: true,
        beforeEnter: (to, from, next) => {
          middlewareSpecific(
            to,
            from,
            next,
            ["admin", "gestor", "operator"],
            "getKGateway"
          );
        },
        meta: {
          requiresAuth: true,
        },
      },

      {
        path: "/entity/generate/saft",
        name: "fiscal.agt",
        component: PageAgtSaft,
        props: true,
        beforeEnter: (to, from, next) => {
          genericAuth(to, from, next, ["admin", "gestor"]);
        },
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
];

const router = new VueRouter({
  // scrollBehavior(to, from, savedPosition) {
  //   if (savedPosition) {
  //     return savedPosition;
  //   } else {
  //     return { x: 0, y: 0 };
  //   }
  // },
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const logged = localStorage.getItem("cookie_vt");
  if (to.matched.some((record) => record.meta.requiresAuth) && !logged) {
    next({
      name: "login",
    });
  } else {
    if (logged) {
      apiServices.customizeHeader(logged);
      apiServices.interceptionRoute();
    }
    NProgress.configure({ showSpinner: false });
    NProgress.start();
    return next();
  }
});

router.afterEach((to) => {
  Vue.nextTick(() => {
    document.title = to.meta.title || "Victória Invoice";
    NProgress.done();
  });
});

export default router;
