<template>
  <div>
    <v-form
      novalidate
      ref="formLogin"
      v-model="valid"
      lazy-validation
      autocomplete="off"
    >
      <v-text-field
        class="buda"
        v-model="user"
        :rules="emailRules"
        label="CORREIO ELECTRÓNICO"
        required
        color="amber lighten-4"
        filled
        append-icon="mdi-account-check"
      >
        <template v-slot:label>
          <label class="white--text" style="font-size: 13px">
            CORREIO ELECTRÓNICO
          </label>
        </template>

      </v-text-field>

      <v-text-field
        v-model="pwd"
        :rules="pwdRules"
        color="amber lighten-4"
        label="PALAVRA-PASSE"
        required
        filled
        :append-icon="value ? 'mdi-eye' : 'mdi-eye-off'"
        @click:append="() => (value = !value)"
        :type="value ? 'password' : 'text'"
      >

        <template v-slot:label>
          <label class="white--text" style="font-size: 13px">
            PALAVRA-PASSE
          </label>
        </template>
      </v-text-field>
      <input type="hidden" name="browser" value="" />
      <input type="hidden" name="ip" value="" />

      <v-btn color="white lighten-2" text block @click="submitLogin">
        Iniciar sessão
      </v-btn>

      <v-btn class="my-3" block color="white" text @click="recoverPwd"
        >Recuperar Palavra-passe</v-btn
      >
    </v-form>
  </div>
</template>

<script>
import takeIp from "../../../services/takeIp";

export default {
  name: "Login",
  data: () => ({
    value: true,
    valid: true,
    access: true,
    pwd: "",
    browser: "",
    pwdRules: [
      (v) => !!v || "Palavra-passe é requerida",
      (v) => (v && v.length >= 6) || "Deve ter no mínimo 6 caracter",
      (v) =>
        /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*\W+)(?=^.{6,15}$).*$/.test(v) ||
        "Deve ter minúsculas, maísculas e caracter especial",
    ],
    user: "",
    emailRules: [
      (v) => !!v || "Correio electrónico é requerido",
      (v) => /.+@.+\..+/.test(v) || "Utilizador inválido",
    ],
  }),
  methods: {
    recoverPwd() {
      this.$store.dispatch("setFormResetLogin", true);
    },
    submitLogin() {
      if (this.$refs.formLogin.validate()) {
        this.$store.dispatch("auth", {
          email: this.user,
          password: this.pwd,
          device:
            this.$store.getters.getYourBrowser + this.$store.getters.getYourIp,
          browser: this.$store.getters.getYourBrowser,
          ip: this.$store.getters.getYourIp,
        });
      }
    },
  },
  mounted() {
    takeIp.getIpFromAmazon();
    takeIp.getNameBrowser();
  },
};
</script>
<style scoped>
.theme--light.v-input input,
.theme--light.v-input textarea {
  color: #ffffff !important;
}

.theme--light.v-text-field >>> .v-input__control > .v-input__slot:before {
  border-color: #ffffff4f !important;
}

.v-text-field .v-label {
  font-size: 13px !important;
}


.theme--light.v-input >>> input {
  color: #ffffff !important;
}

.theme--light.v-input >>> textarea {
  color: #ffffff !important;
}

/**
Background input
 */
.theme--light.v-text-field--filled >>> .v-input__control > .v-input__slot {
  background: #81873114 !important;
}
</style>
