<template>
  <div style="padding: 0; margin-top: 15px">
    <v-row no-gutters>
      <v-col class="">
        <v-card
          class="pa-0"
          style="background-color: #ffffff"
          tile
          elevation="0"
          width="100%"
        >
          <v-sheet color="transparent" min-height="50" elevation="0">
            <v-card elevation="0" width="800" class="mx-auto m-txt-center">
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      dense
                      outlined
                      v-model="name"
                      :rules="nameRules"
                      hint="Nome da Empresa"
                      label="Nome da Empresa"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-text-field
                      dense
                      outlined
                      v-model="common_name"
                      hint="Nome da Comercial da Empresa"
                      label="Nome Comercial (Opcional)"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="6">
                    <v-text-field
                      dense
                      outlined
                      v-model="nif"
                      :rules="nifRules"
                      hint="NIF"
                      label="Número de Identificação Fiscal"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="6">
                    <v-text-field
                      dense
                      outlined
                      v-model="cae"
                      hint="CAE (Não é obrigatório)"
                      label="CAE"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="6">
                    <v-text-field
                      dense
                      outlined
                      v-model="cellphone"
                      hint="Telefone Principal"
                      label="Número de Telefone"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col cols="6">
                    <v-text-field
                      dense
                      outlined
                      v-model="email"
                      hint="Correio electrónico"
                      label="Correio Electrónico"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      dense
                      outlined
                      v-model="address"
                      hint="Endereço"
                      label="Endereço da Empresa"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-form>
              <div class="mt-6 text-left">
                <v-btn @click="createAccount" depressed color="success">
                  Adicionar
                </v-btn>
              </div>
            </v-card>
            <div
              style="
                border-bottom: 1px dashed #f6f6f6;
                padding: 10px;
                background-color: #ffffff;
              "
            ></div>
            <div
              style="
                border-bottom: 1px dashed #f6f6f6;
                padding: 10px;
                background-color: #ffffff;
              "
            ></div>
            <div style="padding-top: 100px"></div>
          </v-sheet>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "CardCreateClient",
  data: () => ({
    name: "",
    common_name: "",
    nif: "",
    address: "",
    cellphone: "",
    email: "",
    valid: true,

    nameRules: [
      (v) => v.length <= 60 || "Máximo 10 caracteres",
      (v) => !!v || "Campo obrigatório",
    ],

    nifRules: [
      (v) => /^(?!.*(\d)\1{9})[A-Za-z0-9]{10,}$/.test(v) || "Campo requerido",
    ],
    // wordsRules: [
    //   (v) => v.trim().split(" ").length <= 25 || "Máximo 25 caracteres",
    //   (v) => !!v || "Campo obrigatório",
    // ],
    // emailRules: [
    //   (v) => v.trim().split(" ").length <= 40 || "Máximo 40 caracteres",
    //   (v) => /.+@.+\..+/.test(v) || "Correio inválido",
    //   (v) => !!v || "Campo obrigatório",
    // ],
    // cellphoneRules: [
    //   (v) => v.trim().split(" ").length <= 9 || "Máximo 9 caracteres",
    //   (v) => !!v || "Campo obrigatório",
    // ],
    // birthRules: [
    //   (v) => v.trim().split(" ").length <= 10 || "Máximo 10 caracteres",
    //   (v) => !!v || "Campo obrigatório",
    // ],
  }),
  methods: {
    createAccount() {
      if (this.$refs.form.validate()) {
        let obj = {
          name: this.name,
          common_name: this.common_name,
          nif: this.nif,
          cae: this.cae,
          cellphone: this.cellphone,
          email: this.email,
          address: this.address,
        };
        this.$store.dispatch("createNewClient", obj);
      }
    },
  },
};
</script>

<style scoped></style>
