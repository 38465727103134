import eventBus from "../../../main";
import apiServices from "../../../../services/apiServices";
import Vue from "vue";

export default {
  state: {
    client: null,
    DocumentType: "Factura",
    store: [],
    order: [],
  },
  mutations: {
    SET_STORE_CLIENT(state, value) {
      state.client = value;
    },
    SET_STORE_INVOICE(state, value) {
      state.store.push(value);
    },
    SET_STORE_DOCTYPE(state, value) {
      state.DocumentType = value;
    },
    REMOVE_ITEM_ON_STORE(state, value) {
      state.store.splice(value, 1);
    },
    SET_ORDER(state, value) {
      state.order = value;
    },
  },
  actions: {
    setStoreInvoice(context, object) {
      context.commit("SET_STORE_INVOICE", object);
    },
    setStoreClient(context, object) {
      context.commit("SET_STORE_CLIENT", object);
    },

    setStoreDocType(context, doc) {
      context.commit("SET_STORE_DOCTYPE", doc);
    },
    removeItemOnStore(context, object) {
      context.commit("REMOVE_ITEM_ON_STORE", object);
    },

    getAllOrder(context, payload) {
      // eventBus.$emit("openSpin", true);
      apiServices
        .getAllOrder(payload)
        .then(function (response) {
          if (response.status === 200) {
            let obj = response.data;
            context.commit("SET_ORDER", obj);
            return;
          }
            if (response.status === 401 || response.status === 403) {
                return "";
            }
        })
        .catch(function () {
          //console.log("Error" + error);
          return Vue.toasted.error("Erro de processamento", {
            theme: "bubble",
            position: "top-center",
            duration: 4000,
          });
        })
        .finally(function () {
          eventBus.$emit("openSpin", false);
        });
    },

    getAllOrderNextPage(context, payload) {
      eventBus.$emit("openSpin", true);
      apiServices
        .getAllOrderNext(payload)
        .then(function (response) {
          if (response.status === 200) {
            let obj = response.data;
            context.commit("SET_ORDER", obj);
            return;
          }
            if (response.status === 401 || response.status === 403) {
                return "";
            }
        })
        .catch(function () {
          return Vue.toasted.error("Erro de processamento", {
            theme: "bubble",
            position: "top-center",
            duration: 4000,
          });
        })
        .finally(function () {
          eventBus.$emit("openSpin", false);
        });
    },
  },
  getters: {
    getStoreInvoice(state) {
      return state.store;
    },
    getStoreClient(state) {
      return state.client;
    },
    getStoreDocType(state) {
      return state.DocumentType;
    },

    getOrderOfStore(state) {
      return state.order;
    },
  },
};
