<template>
  <div style="padding: 0; margin-top: 15px">
    <v-row no-gutters>
      <v-col class="">
        <v-card
          class="pa-6"
          style="background-color: #ffffff"
          tile
          elevation="0"
          width="100%"
        >
          <v-sheet color="transparent" min-height="50" elevation="0">
            <v-card elevation="0" width="800" class="mx-auto m-txt-center">

              <v-row v-if="getImageToInvoice.length > 0">
                <v-col cols="12">
                  <img alt="" style="max-height: 100px; max-width: 100px" :src=getImageToInvoice>
                </v-col>
              </v-row>

              <v-row v-else>
                <v-col cols="12">
                  <v-card-text>Não tem nenhuma imagem carregada</v-card-text>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-card-text class="text-left">Medidas recomendadas: 150X40 ou 100X100 ou 200X200</v-card-text>
                </v-col>
              </v-row>


              <v-form ref="form" v-model="valid" lazy-validation id="form">
                <v-row>
                  <v-col cols="12">
                    <v-file-input
                      dense
                      outlined
                      accept="image/*"
                      name="avatar"
                      show-size
                      :rules="nameRules"
                      type="file"
                      id="file"
                      @change="onFileChange"
                    ></v-file-input>
                  </v-col>
                </v-row>
              </v-form>
              <v-img :src="imageUrl" style="display: none" />
              <div class="mt-6 text-left mx-2">
                <v-btn @click="updateAvatar" depressed color="success">
                  Carregar
                </v-btn>
              </div>
            </v-card>
            <div
              style="
                border-bottom: 1px dashed #f6f6f6;
                padding: 10px;
                background-color: #ffffff;
              "
            ></div>
            <div
              style="
                border-bottom: 1px dashed #f6f6f6;
                padding: 10px;
                background-color: #ffffff;
              "
            ></div>
            <div style="padding-top: 100px"></div>
          </v-sheet>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapHelper } from "../../store/helper";

export default {
  name: "CardEntityLogo",
  data: () => ({
    avatar: "",
    valid: true,
    imageUrl: "",
    nameRules: [
      (v) => v || "Deve escolher uma imagem",
      (v) => !!v || "Campo obrigatório",
    ],
  }),
  methods: {
    updateAvatar() {
      if (this.$refs.form.validate()) {
        const payload = {
          avatar: this.imageUrl,
        };
        this.$store.dispatch("addAvatar", payload);
      }
    },
    createImage(file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        this.imageUrl = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    onFileChange(file) {
      if (!file) {
        return;
      }
      this.createImage(file);
    },
  },
  computed: {
    ...mapHelper,
  },
};
</script>

<style scoped></style>
