import store from "../store";

export function middlewareSpecific(to, from, next, roles, callback) {
  const isAuthenticated = localStorage.getItem("cookie_vt");
  const role_ = localStorage.getItem("role_");
  let role = store.getters.getRole;
  const allowedRoles = roles;
  let userRoles;
  if (role) {
    userRoles = role;
  } else if (role_) {
    userRoles = atob(role_);
  }

  if (!isAuthenticated) {
    next("/login");
    return;
  }

  const hasPermission = allowedRoles.includes(userRoles);

  if (hasPermission) {
    store.dispatch(callback).then(() => {
      next();
    });
  } else {
    next("/unauthorized");
  }
}
