<template>
  <div style="height: 400px !important; margin-top: -200px">
    <video
      style="min-height: 100%; min-width: 100%; position: relative"
      playsinline
      autoplay
      muted
      loop
    >
      <source
        src="../../assets/video/vidfy-african-american-young-man-in-uniform-si_1280x720.mp4"
        type="video/mp4"
      />
    </video>
  </div>
</template>

<script>
export default {
  name: "HeaderVideo",
};
</script>

<style scoped>
/*background-color: #2d2b2b;*/

</style>
