<template>
  <v-app>
    <div id="intro" class="">
      <div class="mask" style="background-color: rgba(0, 0, 0, 0.95)">
        <v-content>
          <logo-login></logo-login>
          <v-card
            max-width="350"
            elevation="0"
            class="mx-auto m-center m-txt-center my-12 transparent"
          >
            <div
              style="
                margin-left: auto;
                margin-right: auto;
                text-align: center;
                padding-top: 50px;
              "
              class="mx-auto"
            >
              <change-login ></change-login>
            </div>
          </v-card>
        </v-content>
        <div>
          <v-card
            class="transparent mx-auto m-center m-txt-center"
            elevation="0"
          >
            <v-card-text class="grey--text m-size-font-12">
              &copy;Direitos Reservados à DELMAWAVE
            </v-card-text>
          </v-card>
        </div>
      </div>
    </div>
    <spin></spin>
    <open-alert></open-alert>
  </v-app>
</template>

<script>
import LogoLogin from "../../components/logo/LogoLogin";

import { mapHelper } from "../../store/helper";

import ChangeLogin from "../../components/auth/ChangeLogin";
import OpenAlert from "../../components/tools/Alert";
import Spin from "../../components/tools/Spin";
export default {
  name: "ChangePassword",
  components: {Spin, OpenAlert, ChangeLogin, LogoLogin },
  computed: {
    ...mapHelper,
  },
};
</script>

<style scoped>
#intro {
  background-image: url("../../assets/vendor/Namibe.jpg");
  background-position: right;
  height: 100vh;
  overflow: hidden;
}

.theme--light.v-label {
  color: #ffffff !important;
}

.theme--light.v-input input,
.theme--light.v-input textarea {
  color: #ffffff !important;
}

.theme--light.v-text-field > .v-input__control > .v-input__slot:before {
  border-color: #ffffff4f !important;
}

.v-text-field .v-label {
  font-size: 13px;
}

.theme--light.v-input {
  color: rgb(52 179 61) !important;
}

/**
Background input
 */
.theme--light.v-text-field--filled > .v-input__control > .v-input__slot {
  background: #81873114 !important;
}

.mask {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-attachment: fixed;
}
</style>
