<template>
  <div id="price">
    <v-row >
      <v-col cols="8" class="mx-auto m-center m-txt-center">
        <v-row sm="4" align="center" justify="center" no-gutters>
          <package-component
            plan-name="Essencial"
            description="Recomendado para escritório, comércio a retalho, lojas físicas e online."
            price="3.500"
            price-year="42.000"
            url="create.account"
            btn="Começar a facturar"
          ></package-component>
          <package-component
            plan-name="Master"
            description="Recomendado para escritório, comércio a retalho, lojas físicas e online com um alto número de funcionários."
            price="5.200"
            price-year="62.400"
            url="create.account"
            btn="Começar a facturar"
          ></package-component>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import PackageComponent from "./PackageComponent";
export default {
  name: "SectionPlan",
  components: { PackageComponent },
};
</script>

<style scoped></style>
