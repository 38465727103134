import store from "../src/store";

export default {
  getIpFromAmazon() {
    fetch("https://www.cloudflare.com/cdn-cgi/trace")
      .then((res) => res.text())
      .then((data) => {
        let ipRegex = /[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}/;
        let ip = data.match(ipRegex)[0];
        return store.dispatch("saveYouIp", ip);
      });
  },

  getNameBrowser() {
    let nAgt = navigator.userAgent;
    let browserName = navigator.appName;
    let nameOffset, verOffset;

    if ((verOffset = nAgt.indexOf("Opera")) !== -1) {
      browserName = "Opera";
    } else if ((verOffset = nAgt.indexOf("MSIE")) !== -1) {
      browserName = "Microsoft Internet Explorer";
    } else if ((verOffset = nAgt.indexOf("Chrome")) !== -1) {
      browserName = "Chrome";
    } else if ((verOffset = nAgt.indexOf("Safari")) !== -1) {
      browserName = "Safari";
    } else if ((verOffset = nAgt.indexOf("Firefox")) !== -1) {
      browserName = "Firefox";
    } else if (
      (nameOffset = nAgt.lastIndexOf(" ") + 1) <
      (verOffset = nAgt.lastIndexOf("/"))
    ) {
      browserName = nAgt.substring(nameOffset, verOffset);
      if (browserName.toLowerCase() === browserName.toUpperCase()) {
        browserName = navigator.appName;
      }
    }
    return store.dispatch("saveYouBrowser", browserName);
  },
};
