export default {
  state: {
    navTitleGlobal: {
      title: null,
    },
  },
  mutations: {
    SET_NAV_TOTAL_GLOBAL(state, value) {
      state.navTitleGlobal.title = value;
    },
  },
  actions: {
    setTitleGlobal(context, value) {
      return context.commit("SET_NAV_TOTAL_GLOBAL", value);
    },
  },
  getters: {
    getTitleNavGlobal(state) {
      //localStorage.setItem("navBarVitJs", state.navTitleGlobal.title);
      //return localStorage.getItem("navBarVitJs");
      if (!state.navTitleGlobal.title) {
        return localStorage.getItem("navBarVitJs");
      }
      return state.navTitleGlobal.title;
    },
  },
};
