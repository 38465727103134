<template>
  <div style="padding: 0; margin-top: 15px">
    <v-row no-gutters>
      <v-col class="">
        <v-card
          class="pa-0"
          style="background-color: #ffffff"
          tile
          elevation="0"
          width="100%"
        >
          <v-sheet color="transparent" min-height="50" elevation="0">
            <v-card elevation="0" width="800" class="mx-auto m-txt-center">
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-row>
                  <v-col cols="6">
                    <v-text-field
                      outlined
                      dense
                      v-model="name"
                      :rules="nameRules"
                      counter="60"
                      hint="Nome do Artigo"
                      label="Nome do Artigo"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="6">
                    <v-text-field
                      outlined
                      dense
                      v-model="barCode"
                      counter
                      maxlength="60"
                      hint="Código de Barras é obrigatório"
                      label="Código de Barras"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="6">
                    <v-text-field
                      outlined
                      dense
                      v-model="price"
                      :rules="priceRules"
                      hint="Exemplo: 500"
                      label="Preço"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="6">
                    <v-text-field
                      outlined
                      dense
                      v-model="priceByCost"
                      label="Preço de Custo"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col cols="6">
                    <!--                    <v-text-field-->
                    <!--                      v-model="productType"-->
                    <!--                      :rules="nameRules"-->
                    <!--                      counter="40"-->
                    <!--                      hint="Tipo de produto é obrigatório"-->
                    <!--                      label="Tipo de produto"-->
                    <!--                    >-->
                    <!--                    </v-text-field>-->
                    <v-select
                      outlined
                      dense
                      v-model="productTypeValue"
                      :rules="nameRules"
                      :items="productType"
                      item-text="text"
                      item-value="service"
                      hint="Opção actual: Produto"
                      label="Tipo de produto"
                    >
                    </v-select>
                  </v-col>

                  <v-col cols="6">
                    <v-select
                      outlined
                      dense
                      v-model="tax"
                      :items="getTax"
                      item-text="tax"
                      item-value="value"
                      hint="Seleccione o tipo de imposto"
                      label="Imposto"
                    >
                    </v-select>
                  </v-col>

                  <v-col cols="12">
                    <v-select
                      outlined
                      dense
                      v-model="taxIsention"
                      :items="getTaxFree"
                      hint="Seleccione o motivo de isenção"
                      label="Motivo da isenção"
                    >
                    </v-select>
                  </v-col>
                  <!--                  <v-col cols="6">-->
                  <!--                    <v-text-field-->
                  <!--                      v-model="tax"-->
                  <!--                      hint="Opção actual: Automático : 0 %"-->
                  <!--                      label="Imposto"-->
                  <!--                    >-->
                  <!--                    </v-text-field>-->
                  <!--                  </v-col>-->

                  <!--                  <v-col cols="12">-->
                  <!--                    <v-text-field-->
                  <!--                      v-model="taxIsention"-->
                  <!--                      hint="Opção actual: Automático"-->
                  <!--                      label="Motivo da isenção"-->
                  <!--                    >-->
                  <!--                    </v-text-field>-->
                  <!--                  </v-col>-->
                </v-row>
              </v-form>
              <div class="mt-6 text-left">
                <v-btn @click="createArticle" depressed color="success">
                  Criar
                </v-btn>
              </div>
            </v-card>
            <div
              style="
                border-bottom: 1px dashed #f6f6f6;
                padding: 10px;
                background-color: #ffffff;
              "
            ></div>
            <div
              style="
                border-bottom: 1px dashed #f6f6f6;
                padding: 10px;
                background-color: #ffffff;
              "
            ></div>
            <div style="padding-top: 100px"></div>
          </v-sheet>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapHelper } from "../../store/helper";
import Vue from "vue";

export default {
  name: "CardCreateArticle",
  data: () => ({
    name: "",
    valid: true,
    price: "",
    priceByCost: 0,
    barCode: "",
    productTypeValue: "",
    productType: [
      { text: "Produto", service: "P" },
      { text: "Serviço", service: "S" },
      { text: "Outros", service: "O" },
    ],
    tax: 0,
    taxIsention: "NOR",

    unit_measure: null,
    service_type: null,
    reference: null,
    series_number: null,

    nameRules: [
      (v) => v.length <= 60 || "Máximo 10 caracteres",
      (v) => !!v || "Campo obrigatório",
    ],

    priceRules: [
      (v) => !!v || "Campo obrigatório",
      (v) => /^\d+(\.\d+)?$/.test(v) || "Preço inválido",
    ],
    // rules: [
    //   (v) => v.length <= 10 || "Máximo 10 caracteres",
    //   (v) => !!v || "Campo obrigatório",
    // ],
    // wordsRules: [
    //   (v) => v.trim().split(" ").length <= 25 || "Máximo 25 caracteres",
    //   (v) => !!v || "Campo obrigatório",
    // ],
    // emailRules: [
    //   (v) => v.trim().split(" ").length <= 40 || "Máximo 40 caracteres",
    //   (v) => /.+@.+\..+/.test(v) || "Correio inválido",
    //   (v) => !!v || "Campo obrigatório",
    // ],
    // cellphoneRules: [
    //   (v) => v.trim().split(" ").length <= 9 || "Máximo 9 caracteres",
    //   (v) => !!v || "Campo obrigatório",
    // ],
    // birthRules: [
    //   (v) => v.trim().split(" ").length <= 10 || "Máximo 10 caracteres",
    //   (v) => !!v || "Campo obrigatório",
    // ],
  }),
  methods: {
    createArticle() {
      if (!this.tax && this.taxIsention === "NOR") {
        return Vue.toasted.error(
          "Deve escolher o motivo de isenção de Imposto",
          {
            theme: "bubble",
            position: "top-center",
            duration: 4000,
          }
        );
      }
      if (this.$refs.form.validate()) {
        let regex = /^\d+(\.\d+)?$/;
        if (!regex.test(this.priceByCost)) {
          return Vue.toasted.error("Preço de custo mal formatado", {
            theme: "bubble",
            position: "top-center",
            duration: 4000,
          });
        }

        let obj = {
          name: this.name,
          price: this.price,
          cost_price: this.priceByCost,
          unit_measure: this.unit_measure,
          service_type: this.service_type,
          reference: this.reference,
          series_number: this.series_number,
          qr_code: this.barCode,
          product_type: this.productTypeValue,
          tax: this.tax,
          tax_free: this.taxIsention,
        };
        this.$store.dispatch("createNewArticle", obj);
      }
    },
  },

  computed: {
    ...mapHelper,
  },
};
</script>

<style scoped></style>
