<template>
  <div>
    <v-row no-gutters>
      <v-col class="">
        <v-card
          class="pa-0"
          style="background-color: transparent"
          tile
          elevation="0"
          width="100%"
        >
          <v-form
            novalidate
            ref="formLogin"
            v-model="valid"
            lazy-validation
            autocomplete="off"
          >
            <v-row>
              <v-col cols="12">
                <v-text-field
                  dense
                  class="buda"
                  v-model="name"
                  :rules="nameRules"
                  label="NOME DA EMPRESA"
                  required
                  color="amber lighten-4"
                  filled
                  append-icon="mdi-account-check"
                >
                  <template v-slot:label>
                    <label class="white--text" style="font-size: 13px">
                      NOME DA EMPRESA
                    </label>
                  </template>
                </v-text-field>
              </v-col>

              <v-col cols="6">
                <v-text-field
                  dense
                  class="buda"
                  v-model="common_name"
                  label="NOME COMERCIAL DA EMPRESA"
                  required
                  color="amber lighten-4"
                  filled
                  append-icon="mdi-account-check"
                >
                  <template v-slot:label>
                    <label class="white--text" style="font-size: 13px">
                      NOME COMERCIAL DA EMPRESA
                    </label>
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  dense
                  class="buda"
                  v-model="nif"
                  :rules="nifRules"
                  label="NIF"
                  required
                  color="amber lighten-4"
                  filled
                  append-icon="mdi-account-check"
                >
                  <template v-slot:label>
                    <label class="white--text" style="font-size: 13px">
                      NIF
                    </label>
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  dense
                  class="buda"
                  v-model="cellphone"
                  :rules="cellphoneRules"
                  label="NIF"
                  required
                  color="amber lighten-4"
                  filled
                  append-icon="mdi-account-check"
                >
                  <template v-slot:label>
                    <label class="white--text" style="font-size: 13px">
                      TELEFONE | MÓVEL | FIXO
                    </label>
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="6">
                <v-select
                  dense
                  class="buda"
                  v-model="cond_fiscal"
                  :rules="ivaRules"
                  :items="getTax"
                  item-text="tax"
                  item-value="value"
                  hint="Opção actual: Sem motivos"
                  label="IMPOSTO"
                  required
                  color="amber lighten-4"
                  filled
                  append-icon="mdi-list"
                >
                  <template v-slot:label>
                    <label class="white--text" style="font-size: 13px">
                      TAXA DE IVA
                    </label>
                  </template>
                </v-select>
              </v-col>
              <v-col cols="6">
                <v-select
                  dense
                  class="buda"
                  v-model="motive_cond_fiscal"
                  :items="getTaxFree"
                  item-text="tax"
                  item-value="value"
                  hint="Opção actual: Sem motivos"
                  label="IMPOSTO"
                  required
                  color="amber lighten-4"
                  filled
                  append-icon="mdi-list"
                >
                  <template v-slot:label>
                    <label class="white--text" style="font-size: 13px">
                      MOTIVO DE ISENÇÃO
                    </label>
                  </template>
                </v-select>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  dense
                  class="buda"
                  v-model="first_name"
                  :rules="nameRules"
                  label="PRIMEIRO NOME DO REPRESENTANTE"
                  required
                  color="amber lighten-4"
                  filled
                  append-icon="mdi-account-check"
                >
                  <template v-slot:label>
                    <label class="white--text" style="font-size: 13px">
                      PRIMEIRO NOME DO REPRESENTANTE
                    </label>
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  dense
                  class="buda"
                  v-model="last_name"
                  :rules="nameRules"
                  label="ÚLTIMO NOME DO REPRESENTANTE"
                  required
                  color="amber lighten-4"
                  filled
                  append-icon="mdi-account-check"
                >
                  <template v-slot:label>
                    <label class="white--text" style="font-size: 13px">
                      ÚLTIMO NOME DO REPRESENTANTE
                    </label>
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  dense
                  class="buda"
                  v-model="user"
                  :rules="emailRules"
                  label="CORREIO ELECTRÓNICO"
                  required
                  color="amber lighten-4"
                  filled
                  append-icon="mdi-account-check"
                >
                  <template v-slot:label>
                    <label class="white--text" style="font-size: 13px">
                      CORREIO ELECTRÓNICO
                    </label>
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  dense
                  v-model="pwd"
                  :rules="pwdRules"
                  color="amber lighten-4"
                  label="PALAVRA-PASSE"
                  required
                  filled
                  :append-icon="value ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="() => (value = !value)"
                  :type="value ? 'password' : 'text'"
                >
                  <template v-slot:label>
                    <label class="white--text" style="font-size: 13px">
                      PALAVRA-PASSE
                    </label>
                  </template>
                </v-text-field>
              </v-col>

              <v-col cols="6">
                <v-text-field
                  dense
                  v-model="pwd_verify"
                  :rules="pwdRules"
                  color="amber lighten-4"
                  label="REPITA A PALAVRA-PASSE"
                  required
                  filled
                  :append-icon="value ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="() => (value = !value)"
                  :type="value ? 'password' : 'text'"
                >
                  <template v-slot:label>
                    <label class="white--text" style="font-size: 13px">
                      REPITA A PALAVRA-PASSE
                    </label>
                  </template>
                </v-text-field>
              </v-col>

              <v-col cols="12" class="text-center mx-auto">
                <v-checkbox
                  dense
                  class="text-center mx-auto"
                  v-model="terms"
                  :rules="termsRules"
                  color="white"
                  label="Li, e concordo com os termos e condições"
                  required
                  filled
                  style="background-color: #331c19; padding: 5px"
                >
                  <template v-slot:label>
                    <label class="white--text" style="font-size: 13px">
                      Li, e concordo com os <router-link to="/terms">Termos e Condições </router-link>
                    </label>
                  </template>
                </v-checkbox>
              </v-col>

              <input type="hidden" name="browser" value="" />
              <input type="hidden" name="ip" value="" />

              <v-btn
                color="white lighten-2"
                text
                block
                @click="submitCreateAccount"
              >
                Criar Conta
              </v-btn>
            </v-row>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import takeIp from "../../../services/takeIp";
import { mapHelper } from "../../store/helper";
import Vue from "vue";

export default {
  name: "FormCreateAccount",
  data: () => ({
    value: true,
    valid: true,
    terms: false,
    regime: [
      { tax: "Isenção (0%)", value: 0 },
      { tax: "Taxa Reduzida (2%)", value: 2 },
      { tax: "Taxa Intermédia (5%)", value: 5 },
      { tax: "Taxa de  (7%)", value: 7 },
      { tax: "Taxa Normal (14%)", value: 14 },
    ],
    access: true,
    pwd: "",
    pwd_verify: "",
    name: "",
    first_name: "",
    last_name: "",
    nif: "",
    cellphone: "",
    common_name: "",
    browser: "",
    cond_fiscal: "",
    motive_cond_fiscal: "",
    pwdRules: [
      (v) => !!v || "Palavra-passe é requerida",
      (v) => (v && v.length >= 6) || "Deve ter no mínimo 6 caracter",
      (v) =>
        /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*\W+)(?=^.{6,15}$).*$/.test(v) ||
        "Deve ter minúsculas, maísculas e caracter especial",
    ],
    nameRules: [
      (v) => !!v || "Nome é requerido",
      (v) => (v && v.length >= 3) || "Deve ter no mínimo 3 caracter",
    ],

    ivaRules: [(v) => /^(0|1?\d|14)$/.test(v) || "Campo requerido"],
    cellphoneRules: [(v) => /^[0-9]+$/.test(v) || "Campo requerido"],
    nifRules: [
      (v) => /^(?!.*(\d)\1{9})[A-Za-z0-9]{10,}$/.test(v) || "Campo requerido",
    ],

    termsRules: [
      (v) => !!v || "Campo requerido",
    ],

    generalRules: [
      (v) => !!v || "Campo requerido",
      (v) => (v && v.length >= 3) || "Deve ter no mínimo 3 caracter",
    ],
    user: "",
    emailRules: [
      (v) => !!v || "Correio electrónico é requerido",
      (v) => /.+@.+\..+/.test(v) || "Utilizador inválido",
    ],
  }),
  computed: {
    ...mapHelper,
  },
  methods: {
    recoverPwd() {
      this.$store.dispatch("setFormResetLogin", true);
    },
    submitCreateAccount() {
      if (this.$refs.formLogin.validate()) {
        if (this.cond_fiscal == 0 && !this.motive_cond_fiscal) {
          return Vue.toasted.error(
            "Deve escolher o motivo de isenção para taxa escolhida",
            {
              theme: "bubble",
              position: "top-center",
              duration: 4000,
            }
          );
        }

        if (this.pwd_verify !== this.pwd) {
          return Vue.toasted.error("A palavra passe não converge", {
            theme: "bubble",
            position: "top-center",
            duration: 4000,
          });
        }

        this.$store.dispatch("callCreateAccount", {
          name: this.name,
          common_name: this.common_name ? this.common_name : this.name,
          password: this.pwd,
          email: this.user,
          nif: this.nif,
          cellphone: this.cellphone,
          first_name: this.first_name,
          last_name: this.last_name,
          cond_fiscal: this.cond_fiscal,
          motive_cond_fiscal: this.motive_cond_fiscal,
          device:
            this.$store.getters.getYourBrowser + this.$store.getters.getYourIp,
          browser: this.$store.getters.getYourBrowser,
          ip: this.$store.getters.getYourIp,
        });
      }
    },
  },
  mounted() {
    takeIp.getIpFromAmazon();
    takeIp.getNameBrowser();
  },
};
</script>
<style scoped>
.theme--light.v-input input,
.theme--light.v-input textarea {
  color: #ffffff !important;
}



.theme--light.v-text-field >>> .v-input__control > .v-input__slot:before {
  border-color: #ffffff4f !important;
}

.v-text-field .v-label {
  font-size: 13px !important;
}

.theme--light.v-input >>> input {
  color: #ffffff !important;
}

.theme--light.v-input >>> textarea {
  color: #ffffff !important;
}

.theme--light.v-select >>> .v-select__selections {
  color: #ffffff !important;
}




/**
Background input
 */
.theme--light.v-text-field--filled >>> .v-input__control > .v-input__slot {
  background: #81873114 !important;
}
</style>
