<template>
  <v-app id="app" class="background">
    <div>
      <!--DRAWER-->
      <drawer></drawer>

      <!--MENU-->
      <bar-navigation></bar-navigation>
      <v-content class="background">
        <v-fade-transition mode="out-in">
          <router-view />
          <wellcome></wellcome>
        </v-fade-transition>
      </v-content>
    </div>
    <!--    <spin></spin>-->
    <spin></spin>
  </v-app>
</template>

<script>
import Drawer from "../../components/drawer/Drawer";
import BarNavigation from "../../components/bar_navigation/BarNavigation";
import Wellcome from "../../components/home/Wellcome";
import Spin from "../../components/tools/Spin";
import store from "../../store";
export default {
  name: "Home",
  components: { Spin, Wellcome, BarNavigation, Drawer },
  mounted() {
    store.dispatch("callEntityUsers").catch(() => {});
    store.dispatch("callClients").catch(() => {});
    store.dispatch("callEntity").catch(() => {});
    store.dispatch("callMe").catch(() => {});
    store.dispatch("getRole").catch(() => {});
  },
};
</script>

<style scoped>
.background {
  /*background-color: #ecf1f7;*/
  /*background-color: red;*/
  background-color: #f9f9f9bd;
  min-height: 300px;
  max-height: 2000px !important;
}
</style>
