<template>
  <div style="padding: 0">
    <v-row no-gutters>
      <v-col class="">
        <v-card
          class="pa-0"
          style="background-color: #ffffff"
          tile
          elevation="0"
          width="100%"
        >
          <v-sheet color="transparent" min-height="50" elevation="0">
            <v-simple-table class="pa-0 pl-0">
              <template v-slot:default>
                <thead class="text-left">
                  <tr>
                    <th class="text-left font-weight-bold">Nome</th>
                    <th class="text-left font-weight-bold">E-mail</th>
                    <th class="text-right font-weight-bold">Opções</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, index) in getAllUsers.victoria"
                    :key="item.email"
                    style="cursor: pointer"
                  >
                    <td class="text-left">
                      {{ item.first_name }} {{ item.last_name }}
                    </td>
                    <td class="text-left">
                      {{ item.email }}
                    </td>
                    <td class="text-right">
                      <v-btn
                        class="ma-2"
                        outlined
                        fab
                        color="teal"
                        x-small
                        @click="
                          editUser(
                            item.first_name,
                            item.last_name,
                            item.email,
                            item.ref
                          )
                        "
                      >
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>

                      <v-btn
                        class="ma-2"
                        outlined
                        fab
                        color="red"
                        x-small
                        @click="deleteUser(item.ref, index)"
                      >
                        <v-icon>mdi-delete-outline</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <div
              style="
                border-bottom: 1px dashed #f6f6f6;
                padding: 10px;
                background-color: #ffffff;
              "
            ></div>
            <div class="text-center" style="background-color: #ffffff">
              <v-pagination
                color="#607d8b"
                v-model="page"
                :length="getAllUsers.meta.last_page"
                @input="changePage"
              ></v-pagination>
            </div>
            <div
              style="
                border-bottom: 1px dashed #f6f6f6;
                padding: 10px;
                background-color: #ffffff;
              "
            ></div>
            <div style="padding-top: 100px"></div>
          </v-sheet>
        </v-card>
      </v-col>
    </v-row>
    <global-dialog-delete></global-dialog-delete>
  </div>
</template>
<script>
import { mapHelper } from "../../store/helper";
import eventBus from "../../main";
import GlobalDialogDelete from "../tools/GlobalDialogDelete";

export default {
  name: "CardEntityUsers",
  components: { GlobalDialogDelete },
  data: () => ({
    page: 1,
  }),
  methods: {
    editUser(first, last, email, id) {
      let payload = {
        first_name: first,
        last_name: last,
        email: email,
      };
      this.$store.dispatch("saveNamesUser", payload);
      return this.$router.push({ name: "user.edit", params: { id: id } });
    },

    changePage() {
      this.$store.dispatch("callEntityUsersNext", this.page);
    },

    deleteUser(id, index) {
      //return this.$router.push({ name: "articles.edit" , params: { id: id }});
      // alert(id);
      //this.$store.dispatch("", id)
      const value = {
        item: id,
        dialog: true,
        method: "callDeleteUser",
        index: index,
      };
      eventBus.$emit("openDialogDeleteGlobal", value);
    },
  },
  computed: {
    ...mapHelper,
  },
  // created() {
  //
  // }
};
</script>

<style scoped></style>
