<template>
  <!--  #363636db-->
  <v-app-bar color="black--text" dense dark app height="55" elevation="0" style="background-color: #ffffff">
    <!--    <v-app-bar-nav-icon class="black&#45;&#45;text"></v-app-bar-nav-icon>-->

    <v-toolbar-title class="m-size-font-14">{{
      getTitleNavGlobal ? getTitleNavGlobal : "Consola"
    }}</v-toolbar-title>

    <v-spacer></v-spacer>

    <span class="black--text m-size-font-14 mx-4">
      {{ getProfile.email }}
    </span>

    <span class="black--text m-size-font-14">
      <v-icon color="black" class="mr-1">mdi-account-circle</v-icon>
      {{ getProfile.first_name }} {{ getProfile.last_name }}
    </span>

    <v-menu top bottom class="black--text">
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on">
          <v-icon color="black">mdi-dots-vertical</v-icon>
        </v-btn>
      </template>

      <v-list>
        <v-list-item @click="profile">
          <v-list-item-title
            ><v-icon color="black" class="mr-2">mdi-account</v-icon
            >Perfil</v-list-item-title
          >
        </v-list-item>
        <v-list-item @click="logout">
          <v-list-item-title
            ><v-icon color="black" class="mr-2">mdi-logout</v-icon>Sair do
            Sistema</v-list-item-title
          >
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
import { mapHelper } from "../../store/helper";
import router from "../../router";

export default {
  name: "BarNavigation",
  methods:{
    logout(){
      return this.$store.dispatch("logout");
    },
    profile(){
      router.push({ name: "user.profile" }).catch(() => {
        //throw new Error("Problem - Contact DIGITAL INVOICE");
      });
    }
  },
  computed: {
    ...mapHelper,
  },
};
</script>

<style scoped></style>
