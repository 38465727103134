<template>
  <div class="mx-auto my-3 m-txt-center m-center background">
    <v-card elevation="0" class="transparent" min-height="200" max-width="100%">
      <v-row justify="center">
        <v-col cols="12">
          <v-row sm="4" align="center" justify="center">
            <v-card
              width="98%"
              style="border-radius: 0 !important"
              min-width="300"
              class="m-txt-center"
              elevation="0"
              min-height="70"
            >
              <card-view-title subtitle="Documento" base-title="Lista" name-route="list.documents"></card-view-title>
            </v-card>
          </v-row>

          <v-row sm="4" class="mt-6" align="center" justify="center">
            <v-card
              width="98%"
              style="border-radius: 0 !important"
              min-width="300"
              class="m-txt-center transparent"
              elevation="0"
              min-height="70"
            >
              <card-view-one-document></card-view-one-document>
            </v-card>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
    <open-alert></open-alert>
    <spin></spin>
  </div>
</template>

<script>
import OpenAlert from "../../components/tools/Alert";
import Spin from "../../components/tools/Spin.vue";
import CardViewOneDocument from "../../components/documents/CardViewOneDocument";
import CardViewTitle from "../../components/tools/CardViewTitle";
export default {
  name: "PageListDocumentDetails",
  components: {
    CardViewTitle,
    CardViewOneDocument,
    OpenAlert,
    Spin,
  },

  Spin,
};
</script>

<style scoped>
.background {
  /*background-color: #ecf1f7;*/
  background-color: #f9f9f9bd;
  min-height: 1000px;
  max-height: 2000px !important;
}
</style>
