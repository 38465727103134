<template>
  <div class="mx-auto my-3 m-txt-center m-center background">
    <v-card elevation="0" class="transparent" min-height="200" max-width="100%">
      <v-row justify="center">
        <v-col cols="12">
          <v-row sm="4" align="center" justify="center">
            <v-card
              width="98%"
              style="border-radius: 0 !important"
              min-width="300"
              class="m-txt-center"
              elevation="0"
              min-height="70"
            >
              <card-documents></card-documents>
            </v-card>
          </v-row>

          <v-row sm="4" class="mt-6" align="center" justify="center">
            <v-card
              width="98%"
              style="border-radius: 0 !important"
              min-width="300"
              class="m-txt-center transparent"
              elevation="0"
              min-height="70"
            >
              <div v-show="!getOrderOfStore.victoria" style="margin-top: 60px">
                <div><v-icon size="38">mdi-information-outline</v-icon></div>
                <v-card-text class="m-size-font-12">Sem documentos</v-card-text>
              </div>
              <card-view-documents></card-view-documents>
            </v-card>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
    <open-alert></open-alert>
    <spin></spin>
    <dialog-f-t></dialog-f-t>
    <dialog-cancel-document></dialog-cancel-document>
    <dialog-receipt></dialog-receipt>
    <dialog-download-receipt></dialog-download-receipt>
  </div>
</template>

<script>
import OpenAlert from "../../components/tools/Alert";
import Spin from "../../components/tools/Spin.vue";
import CardDocuments from "../../components/documents/CardDocuments";
import CardViewDocuments from "../../components/documents/CardViewDocuments";
import DialogFT from "../../components/tools/DialogFT";
import DialogCancelDocument from "../../components/tools/DialogCancelDocument";
import DialogReceipt from "../../components/tools/DialogReceipt";
import DialogDownloadReceipt from "../../components/tools/DialogDownloadReceipt";
import { mapHelper } from "../../store/helper";
export default {
  name: "Documents",
  components: {
    DialogDownloadReceipt,
    DialogReceipt,
    DialogFT,
    CardViewDocuments,
    CardDocuments,
    OpenAlert,
    Spin,
    DialogCancelDocument,
  },
  computed: {
    ...mapHelper,
  },
  Spin,
};
</script>

<style scoped>
.background {
  /*background-color: #ecf1f7;*/
  background-color: #f9f9f9bd;
  min-height: 1000px;
  max-height: 2000px !important;
}
</style>
