<template>
  <div>
    <v-form novalidate ref="formChange" v-model="valid" lazy-validation>
      <v-text-field
        v-model="email"
        :rules="emailRules"
        label="CORREIO ELECTRÓNICO"
        color="amber lighten-4"
        required
        filled
        append-icon="mdi-account-check"
      >
        <template v-slot:label>
          <label class="white--text" style="font-size: 13px">
            CORREIO ELECTRÓNICO
          </label>
        </template>
      </v-text-field>

      <v-text-field
        v-model="pwd"
        :rules="pwdRules"
        label="NOVA PALAVRA-PASSE"
        color="amber lighten-4"
        required
        filled
        :append-icon="value ? 'mdi-eye' : 'mdi-eye-off'"
        @click:append="() => (value = !value)"
        :type="value ? 'password' : 'text'"
      >
        <template v-slot:label>
          <label class="white--text" style="font-size: 13px">
            PALAVRA-PASSE
          </label>
        </template>

      </v-text-field>
      <v-btn color="white lighten-2" text block @click="changeLogin">
        Concluir
      </v-btn>
    </v-form>
  </div>
</template>

<script>
export default {
  name: "ChangeLogin",
  props: ["id"],
  data: () => ({
    value: true,
    valid: true,
    access: true,
    pwd: "",
    browser: "",
    pwdRules: [
      (v) => !!v || "Palavra-passe é requerida",
      (v) => (v && v.length >= 6) || "Deve ter no mínimo 6 caracter",
      (v) =>
        /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*\W+)(?=^.{6,15}$).*$/.test(v) ||
        "Deve ter minúsculas, maísculas e caracter especial",
    ],
    email: "",
    emailRules: [
      (v) => !!v || "Correio electrónico é requerido",
      (v) => /.+@.+\..+/.test(v) || "Utilizador inválido",
    ],
  }),
  methods: {
    recoverPwd() {
      this.$store.dispatch("setFormResetLogin", true);
    },
    changeLogin() {
      if (this.$refs.formChange.validate()) {
        this.$store.dispatch("changeLogin", {
          token: this.$route.params.id,
          email: this.email,
          password: this.pwd,
        });
      }
    },
  },
};
</script>

<style scoped>
.theme--light.v-input input,
.theme--light.v-input textarea {
  color: #ffffff !important;
}

.theme--light.v-text-field >>> .v-input__control > .v-input__slot:before {
  border-color: #ffffff4f !important;
}

.v-text-field .v-label {
  font-size: 13px !important;
}


.theme--light.v-input >>> input {
  color: #ffffff !important;
}

.theme--light.v-input >>> textarea {
  color: #ffffff !important;
}

/**
Background input
 */
.theme--light.v-text-field--filled >>> .v-input__control > .v-input__slot {
  background: #81873114 !important;
}
</style>
