<template>
  <div style="padding: 0; margin-top: 15px">
    <v-row no-gutters>
      <v-col class="">
        <v-card
          class="pa-6"
          style="background-color: #ffffff"
          tile
          elevation="0"
          width="100%"
        >
          <v-sheet color="transparent" min-height="50" elevation="0">
            <v-card elevation="0" width="800" class="mx-auto m-txt-center">
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-row>
                  <v-col cols="6">
                    <v-text-field
                      dense
                      outlined
                      disabled
                      hint="Primeiro nome"
                      :label="getProfile.first_name"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="6">
                    <v-text-field
                      dense
                      outlined
                      disabled
                      hint="Último nome é obrigatório"
                      :label="getProfile.last_name"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-text-field
                      dense
                      outlined
                      disabled
                      hint="Último nome é obrigatório"
                      :label="getProfile.email"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-text-field
                      dense
                      outlined
                      v-model="old_pwd"
                      :append-icon="value ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="() => (value = !value)"
                      :type="value ? 'password' : 'text'"
                      :rules="pwdRules"
                      hint="Palavra-passe é obrigatório"
                      label="Actual palavra-passe"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-text-field
                      dense
                      outlined
                      v-model="pwd"
                      :append-icon="value ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="() => (value = !value)"
                      :type="value ? 'password' : 'text'"
                      :rules="pwdRules"
                      hint="Palavra-passe é obrigatório"
                      label="Nova palavra-passe"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-form>
              <div class="mt-6 text-left">
                <v-btn @click="updatePwd" depressed color="success">
                  Actualizar
                </v-btn>
              </div>
            </v-card>
            <div
              style="
                border-bottom: 1px dashed #f6f6f6;
                padding: 10px;
                background-color: #ffffff;
              "
            ></div>
            <div
              style="
                border-bottom: 1px dashed #f6f6f6;
                padding: 10px;
                background-color: #ffffff;
              "
            ></div>
            <div style="padding-top: 100px"></div>
          </v-sheet>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapHelper } from "../../store/helper";

export default {
  name: "CardProfile",
  data: () => ({
    valid: true,
    value: true,
    old_pwd: "",
    pwd: "",
    pwdRules: [
      (v) => !!v || "Palavra-passe é requerida",
      (v) => (v && v.length >= 6) || "Deve ter no mínimo 6 caracteres",
      (v) =>
        /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*\W+)(?=^.{6,15}$).*$/.test(v) ||
        "Deve ter minúsculas, maísculas e caracter especial",
    ],
  }),
  methods: {
    updatePwd() {
      let obj = {
        old_password: this.old_pwd,
        password: this.pwd,
      };
      this.$store.dispatch("callUpdatePwd", obj);
    },
  },
  computed: {
    ...mapHelper,
  },
};
</script>

<style scoped></style>
