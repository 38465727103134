<template>
  <div style="padding: 0; margin-top: 15px">
    <v-row no-gutters>
      <v-col class="">
        <v-card
          class="pa-0"
          style="background-color: #ffffff"
          tile
          elevation="0"
          width="100%"
        >
          <v-sheet color="transparent" min-height="50" elevation="0">
            <v-card elevation="0" width="800" class="mx-auto m-txt-center">
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-row>
                  <v-col cols="6">
                    <v-text-field
                      dense
                      outlined
                      v-model="name"
                      :rules="nameRules"
                      hint="Nome do Artigo"
                      label="Nome do Artigo"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="6">
                    <v-text-field
                      outlined
                      dense
                      v-model="barCode"
                      hint="Código de Barras é obrigatório"
                      label="Código de Barras"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="6">
                    <v-text-field
                      outlined
                      dense
                      v-model="price"
                      :rules="priceRules"
                      hint="Exemplo: 500"
                      label="Preço"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="6">
                    <v-text-field
                      outlined
                      dense
                      v-model="cost_price"
                      hint="Preço de Custo é obrigatório"
                      label="Preço de Custo"
                    >
                    </v-text-field>
                  </v-col>

                  <!--                  <v-col cols="6">-->
                  <!--                    <v-text-field-->
                  <!--                        disabled-->
                  <!--                      v-model="productType"-->
                  <!--                      hint="Tipo de produto é obrigatório"-->
                  <!--                      label="Tipo de produto"-->
                  <!--                    >-->
                  <!--                    </v-text-field>-->
                  <!--                  </v-col>-->
                  <v-col cols="6">
                    <v-select
                      outlined
                      v-model="tax"
                      :items="getTax"
                      item-text="tax"
                      item-value="value"
                      hint="Opção actual: Automático"
                      label="Imposto"
                      dense
                    >
                    </v-select>
                  </v-col>

                  <v-col cols="12">
                    <v-select
                      outlined
                      dense
                      v-model="tax_free"
                      :items="getTaxFree"
                      hint="Opção actual: Automático"
                      label="Motivo da isenção"
                    >
                    </v-select>
                  </v-col>
                </v-row>
              </v-form>
              <div class="mt-6 text-left">
                <v-btn @click="updateArticle" depressed color="success">
                  Actualizar
                </v-btn>
              </div>
            </v-card>
            <div
              style="
                border-bottom: 1px dashed #f6f6f6;
                padding: 10px;
                background-color: #ffffff;
              "
            ></div>
            <div
              style="
                border-bottom: 1px dashed #f6f6f6;
                padding: 10px;
                background-color: #ffffff;
              "
            ></div>
            <div style="padding-top: 100px"></div>
          </v-sheet>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapHelper } from "../../store/helper";
import Vue from "vue";

export default {
  name: "CardUpdateArticle",
  data: () => ({
    name: "",
    valid: true,
    price: "",
    cost_price: "",
    barCode: "",
    productType: "Automático",
    tax: 0,
    tax_free: "Automático",

    unit_measure: null,
    service_type: null,
    reference: null,
    series_number: null,

    nameRules: [
      (v) => v.length <= 60 || "Máximo 10 caracteres",
      (v) => !!v || "Campo obrigatório",
    ],

    priceRules: [
      (v) => !!v || "Campo obrigatório",
      (v) => /^\d+(\.\d+)?$/.test(v) || "Preço inválido",
    ],
  }),
  methods: {
    updateArticle() {
      if (this.$refs.form.validate()) {
        let regex = /^\d+(\.\d+)?$/;

        if (!regex.test(this.cost_price)) {
          return Vue.toasted.error("Preço de custo mal formatado", {
            theme: "bubble",
            position: "top-center",
            duration: 4000,
          });
        }

        let obj = {
          id: this.$route.params.id,
          name: this.name,
          price: this.price,
          cost_price: this.cost_price,
          unit_measure: this.unit_measure,
          service_type: this.service_type,
          reference: this.reference,
          series_number: this.series_number,
          qr_code: this.barCode,
          product_type: this.productType,
          tax: this.tax,
          tax_free: this.tax_free,
        };
        this.$store.dispatch("callUpdateArticle", obj);
      }
    },
  },
  computed: {
    ...mapHelper,
  },

  mounted() {
    this.name = this.$store.getters.getSaveEditArticles.name;
    this.price = this.$store.getters.getSaveEditArticles.price;
    this.cost_price = this.$store.getters.getSaveEditArticles.cost_price;
    this.unit_measure = this.$store.getters.getSaveEditArticles.unit_measure;
    this.service_type = this.$store.getters.getSaveEditArticles.service_type;
    this.reference = this.$store.getters.getSaveEditArticles.reference;
    this.series_number = this.$store.getters.getSaveEditArticles.series_number;
    this.qr_code = this.$store.getters.getSaveEditArticles.qr_code;
    this.productType = this.$store.getters.getSaveEditArticles.product_type;
    this.tax = this.$store.getters.getSaveEditArticles.tax;
    this.tax_free = this.$store.getters.getSaveEditArticles.tax_free;
  },
};
</script>

<style scoped></style>
