<template>
  <div style="padding: 0; margin-top: 15px">
    <v-row>
      <v-col class="">
        <v-card
          class="pa-0"
          style="background-color: #ffffff"
          tile
          elevation="0"
          width="100%"
        >
          <v-sheet color="transparent" min-height="50" elevation="0">
            <v-card elevation="0" width="800" class="mx-auto m-txt-center">
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    outlined
                    v-model="motive"
                    :rules="nameRules"
                    @change="iMotive"
                    hint="Motivo para emissão da Nota de Crédito"
                    label="Motivo para Nota de Crédto"
                  ></v-text-field>
                </v-col>

                <v-col cols="6">
                  <v-text-field
                    outlined
                    v-model="invoiceReference"
                    :rules="invoiceRules"
                    @change="iMotive"
                    hint="N.º da Fatura que pretende corrigir"
                    label="N.º da Fatura gerada pelo Victória Invoice"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12">
                  <v-combobox
                    v-model="intentionNC"
                    @change="iMotive"
                    :rules="intentionType"
                    :items="items"
                    item-text="text"
                    item-value="description"
                    label="Tipo de Nota de Crédito"
                    outlined
                  ></v-combobox>
                </v-col>
              </v-row>
            </v-card>
          </v-sheet>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "CardListNoteCreditReference",
  data: () => ({
    items: [
      { text: "Correcção", description: "correction" },
      { text: "Cancelamento", description: "cancel" },
    ],
    intentionNC: "",
    motive: "",
    invoiceReference: "",
    nameRules: [
      (v) => !!v || "Campo obrigatório",
    ],
    invoiceRules: [
      (v) => !!v || "Campo obrigatório",
    ],
    intentionType: [
      (v) => !!v || "Campo obrigatório",
    ],
  }),
  methods: {
    iMotive() {
      let object = {
        motive: this.motive,
        invoiceReference: this.invoiceReference,
        typeNote: this.intentionNC.description,
      };
      this.$store.dispatch("setNoteCreditMotive", object);
    },
    // changeFormation(value) {
    //   alert(value)
    // },
  },
};
</script>

<style scoped></style>
