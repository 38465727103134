<template>
  <div style="padding: 0">
    <v-row no-gutters>
      <v-col class="">
        <v-card
          class="pa-0"
          style="background-color: #ffffff"
          tile
          elevation="0"
          width="100%"
        >
          <v-sheet color="transparent" min-height="50" elevation="0">
            <v-simple-table class="pa-0 pl-0">
              <template v-slot:default>
                <thead class="text-left">
                  <tr>
                    <th class="text-left font-weight-bold">Nome</th>
                    <th class="text-left font-weight-bold">Nif</th>
                    <th class="text-left font-weight-bold">Telefone</th>
                    <th class="text-left font-weight-bold">Endereço</th>
                    <th class="text-right font-weight-bold">Opções</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, k) in getClient.victoria"
                    :key="item.name"
                    style="cursor: pointer"
                  >
                    <td class="text-left" @click="editClient(item)">
                      {{ item.name }}
                    </td>
                    <td class="text-left" @click="editClient(item)">
                      {{ item.nif }}
                    </td>
                    <td class="text-left" @click="editClient(item)">
                      {{ item.cellphone }}
                    </td>
                    <td class="text-left" @click="editClient(item)">
                      {{ item.address }}
                    </td>
                    <td class="text-right">
                      <v-btn
                        class="ma-2"
                        outlined
                        fab
                        color="teal"
                        x-small
                        @click="editClient(item)"
                      >
                        <v-icon>mdi-eye</v-icon>
                      </v-btn>

                      <v-btn
                        class="ma-2"
                        outlined
                        fab
                        color="red"
                        x-small
                        @click="deleteClient(k, item.ref)"
                      >
                        <v-icon>mdi-delete-outline</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <div
              style="
                border-bottom: 1px dashed #f6f6f6;
                padding: 10px;
                background-color: #ffffff;
              "
            ></div>


            <div class="text-center" style="background-color: #ffffff">
              <v-pagination
                  color="#607d8b"
                  v-model="page"
                  :length="getClient.meta.last_page"
                  @input="changePage"
              ></v-pagination>
            </div>
            <div
              style="
                border-bottom: 1px dashed #f6f6f6;
                padding: 10px;
                background-color: #ffffff;
              "
            ></div>
            <div style="padding-top: 100px"></div>
          </v-sheet>
        </v-card>
      </v-col>
    </v-row>
    <global-dialog-delete></global-dialog-delete>
  </div>
</template>
<script>
import { mapHelper } from "../../store/helper";
import eventBus from "../../main";
import GlobalDialogDelete from "../tools/GlobalDialogDelete";

export default {
  name: "CardListClients",
  components: {GlobalDialogDelete},
  data: () => ({
    page: 1,
  }),
  methods: {
    editClient(item) {
      let payload = {
        name: item.name,
        common_name: item.common_name,
        nif: item.nif,
        province: item.province,
        country: item.country,
        address: item.address,
        cellphone: item.cellphone,
        cae: item.cae,
        email: item.email,
      };
      this.$store.dispatch("saveDataClient", payload);
      return this.$router.push({
        name: "edit.client",
        params: { id: item.ref },
      });
    },

    changePage() {
      this.$store.dispatch("callClientsNext", this.page);
    },

    deleteClient(index, id) {
      //return this.$router.push({ name: "articles.edit" , params: { id: id }});
      // alert(id);
      //this.$store.dispatch("", id)
      const value = {
        item: id,
        index: index,
        method: "callDeleteClient",
        dialog: true,
      };
      eventBus.$emit("openDialogDeleteGlobal", value);
    },
  },
  computed: {
    ...mapHelper,
  },
};
</script>

<style scoped></style>
