<template>
  <div class="mx-auto my-3 m-txt-center m-center background">
    <v-card
      elevation="0"
      class="transparent m-border-line-1"
      min-height="200"
      max-width="100%"
    >
      <v-row justify="center">
        <v-col cols="8">
          <v-row sm="4" align="center" justify="center">
            <v-card
              width="98%"
              style="border-radius: 0 !important"
              min-width="300"
              height="80"
              class="m-txt-center"
              elevation="0"
            >
              <card-invoice-type></card-invoice-type>
            </v-card>
          </v-row>
          <v-row sm="4" class="mt-6" align="center" justify="center">
            <v-card
              width="98%"
              style="border-radius: 0 !important"
              min-width="300"
              class="m-txt-center"
              elevation="0"
              min-height="70"
            >
              <card-create-client-invoice></card-create-client-invoice>
            </v-card>
          </v-row>

          <v-row sm="4" class="mt-6" align="center" justify="center">
            <v-card
              width="98%"
              style="border-radius: 0 !important"
              min-width="300"
              class="m-txt-center"
              elevation="0"
              min-height="70"
            >
              <card-item-invoice></card-item-invoice>
            </v-card>
          </v-row>

          <v-row sm="4" class="mt-6" align="center" justify="center">
            <v-card
              width="98%"
              style="border-radius: 0 !important"
              min-width="300"
              class="m-txt-center transparent"
              elevation="0"
              min-height="70"
            >
              <card-list-articles></card-list-articles>
            </v-card>
          </v-row>
        </v-col>
        <v-col cols="4" class="">
          <v-row sm="4" align="center" justify="center">
            <v-card width="98%" class="m-txt-center" elevation="0">
              <card-doc-definition></card-doc-definition>
            </v-card>
          </v-row>
        </v-col>
      </v-row>
    </v-card>

    <modal-article></modal-article>
    <dialog-advice></dialog-advice>
    <modal-update-article></modal-update-article>
    <dialog-f-t></dialog-f-t>
    <open-alert></open-alert>
    <spin></spin>
  </div>
</template>

<script>
import CardCreateClientInvoice from "../../components/invoice/CardCreateClientInvoice";
import CardInvoiceType from "../../components/invoice/CardInvoiceType";
import CardItemInvoice from "../../components/invoice/CardItemInvoice";
import CardDocDefinition from "../../components/invoice/CardDocDefinition";
import ModalArticle from "../../components/invoice/ModalArticle";
import CardListArticles from "../../components/invoice/CardListArticles";
import DialogAdvice from "../../components/tools/DialogAdvice";
import ModalUpdateArticle from "../../components/invoice/ModalUpdateArticle";
import DialogFT from "../../components/tools/DialogFT";
import OpenAlert from "../../components/tools/Alert";
import Spin from "../../components/tools/Spin.vue";
import { mapHelper } from "../../store/helper";

export default {
  name: "PageCreateInvoice",
  computed: {
    ...mapHelper,
  },
  components: {
    DialogFT,
    ModalUpdateArticle,
    DialogAdvice,
    CardListArticles,
    ModalArticle,
    CardDocDefinition,
    CardItemInvoice,
    CardInvoiceType,
    CardCreateClientInvoice,
    OpenAlert,
    Spin,
  },

  Spin,
};
</script>

<style scoped>
.background {
  /*background-color: #ecf1f7;*/
  background-color: #f9f9f9bd;
  min-height: 1000px;
  max-height: 2000px !important;
}
</style>
