<template>
  <div style="padding: 0; margin-top: 15px">
    <v-row no-gutters>
      <v-col class="">
        <v-card
          class="pa-0"
          style="background-color: #ffffff"
          tile
          elevation="0"
          width="100%"
        >
          <v-sheet color="transparent" min-height="50" elevation="0">
            <v-card elevation="0" width="800" class="mx-auto m-txt-center">
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      dense
                      outlined
                      v-model="entity"
                      :rules="entityRules"
                      hint="Número de Entidade"
                      label="Número de Entidade"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-text-field
                      dense
                      outlined
                      v-model="mark"
                      :rules="nameRules"
                      hint="Insira o Marc-Zone"
                      label="Insira o Marc-Zone"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="6">
                    <v-text-field
                      dense
                      outlined
                      v-model="bag"
                      hint="Insira o Sys-Factory-Bag"
                      label="Insira o Sys-Factory-Bag"
                      :rules="nameRules"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="6">
                    <v-text-field
                      dense
                      outlined
                      v-model="authorization"
                      :rules="nameRules"
                      hint="Insira o Authorization"
                      label="Insira o Authorization"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-form>
              <div class="mt-6 text-left">
                <v-btn @click="saveGateway" depressed color="success">
                  Guardar
                </v-btn>
              </div>
            </v-card>
            <div
              style="
                border-bottom: 1px dashed #f6f6f6;
                padding: 10px;
                background-color: #ffffff;
              "
            ></div>
            <div
              style="
                border-bottom: 1px dashed #f6f6f6;
                padding: 10px;
                background-color: #ffffff;
              "
            ></div>
            <div style="padding-top: 100px"></div>
          </v-sheet>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapHelper } from "../../store/helper";

export default {
  name: "CardGatewayKyamiPay",
  data: () => ({
    entity: "",
    mark: "",
    bag: "",
    authorization: "",
    valid: true,
    nameRules: [
      (v) => v.trim().split(" ").length <= 10 || "",
      (v) => !!v || "Campo obrigatório",
    ],

    entityRules: [
      (v) => v.trim().split(" ").length <= 4 || "",
      (v) => !!v || "Campo obrigatório",
    ],
  }),
  methods: {
    saveGateway() {
      if (this.$refs.form.validate()) {
        let obj = {
          entity: this.entity,
          mark: this.mark,
          bag: this.bag,
          auth: this.authorization,
          id: this.$store.getters.getGatewayKyami.ref,
        };
        this.$store.dispatch("saveGateway", obj);
      }
    },
  },

  computed: {
    ...mapHelper,
  },

  mounted() {
    this.entity = this.$store.getters.getGatewayKyami.entity;
    this.mark = this.$store.getters.getGatewayKyami.mark;
    this.bag = this.$store.getters.getGatewayKyami.bag;
    this.authorization = this.$store.getters.getGatewayKyami.auth;
  },
};
</script>

<style scoped></style>
