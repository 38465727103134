import apiServices from "../../../../services/apiServices";
import Vue from "vue";
import eventBus from "../../../main";
import router from "../../../router";

export default {
  state: {
    clients: {
      clients: [],
      add: [],
      clientToUpdate: Object,

      // {
      //   name: "catinfo",
      //   ref: "yy-iiuio-pouy",
      //   nif: 78945656,
      //   address: "Av. 21 De Janeiro, Ed. FCW Tecnologia",
      // },
      // {
      //   name: "DELMAWAVE",
      //   ref: "yy-iiuio-cupu",
      //   nif: 54589745,
      //   address: "Av. 21 De Janeiro, Ed. FCW Tecnologia",
      // },
    },
  },
  mutations: {
    SET_ALL_CLIENTS(state, value) {
      state.clients.clients = value;
    },

    SET_DATA_CLIENT(state, value) {
      state.clients.clientToUpdate = value;
    },
    SET_REMOVE_CLIENT(state, value) {
      state.clients.clients.victoria.splice(value, 1);
    },
  },
  actions: {
    saveDataClient(context, payload) {
      return context.commit("SET_DATA_CLIENT", payload);
    },
    callClients(context, payload) {
      apiServices
        .getAllClients(payload)
        .then(function (response) {
          if (response.status === 200) {
            let obj = response.data;
            //context.commit("SET_STORE_CLIENT", obj.victoria);
            return context.commit("SET_ALL_CLIENTS", obj);
          }
          if (response.status === 422) {
            let message = response.data.message;
            return Vue.toasted.error(message, {
              theme: "bubble",
              position: "top-center",
              duration: 4000,
            });
          }

            if (response.status === 401 || response.status === 403) {
                return "";
            }
          return Vue.toasted.error("Pedido não processado", {
            theme: "bubble",
            position: "top-center",
            duration: 4000,
          });
        })
        .catch(function (error) {
          return Vue.toasted.error(error, {
            theme: "bubble",
            position: "top-center",
            duration: 4000,
          });
        })
        .finally(function () {
          eventBus.$emit("openSpin", false);
        });
    },

    callClientsNext(context, payload) {
      eventBus.$emit("openSpin", true);
      apiServices
        .getAllClientsNext(payload)
        .then(function (response) {
          if (response.status === 200) {
            let obj = response.data;
            //context.commit("SET_STORE_CLIENT", obj.victoria);
            return context.commit("SET_ALL_CLIENTS", obj);
          }
          if (response.status === 422) {
            let message = response.data.message;
            return Vue.toasted.error(message, {
              theme: "bubble",
              position: "top-center",
              duration: 4000,
            });
          }
            if (response.status === 401 || response.status === 403) {
                return "";
            }
          return Vue.toasted.error("Pedido não processado", {
            theme: "bubble",
            position: "top-center",
            duration: 4000,
          });
        })
        .catch(function () {
          return Vue.toasted.error("Pedido não processado", {
            theme: "bubble",
            position: "top-center",
            duration: 4000,
          });
        })
        .finally(function () {
          eventBus.$emit("openSpin", false);
        });
    },

    createNewClient(context, payload) {
      eventBus.$emit("openSpin", true);
      apiServices
        .callAddClient(payload)
        .then(function (response) {
          if (response.status === 201) {
            Vue.toasted.success("Cliente adicionado com sucesso!", {
              theme: "bubble",
              position: "top-center",
              duration: 4000,
            });
            router.push({ name: "list.client" });
            return;
          }
          if (response.status === 422) {
            let message = response.data.message;
            return Vue.toasted.error(message, {
              theme: "bubble",
              position: "top-center",
              duration: 4000,
            });
          }
            if (response.status === 401 || response.status === 403) {
                return "";
            }
          return Vue.toasted.error("Pedido não processado", {
            theme: "bubble",
            position: "top-center",
            duration: 4000,
          });
        })
        .catch(function () {
          return Vue.toasted.error("Pedido não processado", {
            theme: "bubble",
            position: "top-center",
            duration: 4000,
          });
        })
        .finally(function () {
          eventBus.$emit("openSpin", false);
        });
    },

    callDeleteClient(context, payload) {
      eventBus.$emit("openSpin", true);
      apiServices
        .deleteClient(payload.id)
        .then(function (response) {
          if (response.status === 200) {
            context.commit("SET_REMOVE_CLIENT", payload.index);
            return Vue.toasted.success("Cliente removido com sucesso!", {
              theme: "bubble",
              position: "top-center",
              duration: 4000,
            });
          }
          if (response.status === 422) {
            let message = response.data.message;
            return Vue.toasted.error(message, {
              theme: "bubble",
              position: "top-center",
              duration: 4000,
            });
          }

            if (response.status === 401 || response.status === 403) {
                return "";
            }
          return Vue.toasted.error("Pedido não processado", {
            theme: "bubble",
            position: "top-center",
            duration: 4000,
          });
        })
        .catch(function () {
          Vue.toasted.error("Pedido não processado", {
            theme: "bubble",
            position: "top-center",
            duration: 4000,
          });
        })
        .finally(function () {
          eventBus.$emit("openSpin", false);
        });
    },

    callUpdateClient(context, payload) {
      eventBus.$emit("openSpin", true);
      apiServices
        .updateClient(payload)
        .then(function (response) {
          if (response.status === 200) {
            return Vue.toasted.success("Dados actualizados com sucesso!", {
              theme: "bubble",
              position: "top-center",
              duration: 4000,
            });
          }
          if (response.status === 422) {
            //let message = response.data.message;
            return Vue.toasted.error(
              "Não pode alterar o NIF ou Nome de um cliente associado a um documento",
              {
                theme: "bubble",
                position: "top-center",
                duration: 4000,
              }
            );
          }

            if (response.status === 401 || response.status === 403) {
                return "";
            }
          return Vue.toasted.error("Pedido não processado", {
            theme: "bubble",
            position: "top-center",
            duration: 4000,
          });
        })
        .catch(function () {
          return Vue.toasted.error("Pedido não processado", {
            theme: "bubble",
            position: "top-center",
            duration: 4000,
          });
        })
        .finally(function () {
          eventBus.$emit("openSpin", false);
        });
    },
  },
  getters: {
    getClient(state) {
      return state.clients.clients;
    },
    getClientToUpdate(state) {
      return state.clients.clientToUpdate;
    },
  },
};
