<template>
  <div class="text-center" style="padding: 5px">
    <v-bottom-sheet v-model="sheet" inset width="700">
      <v-sheet class="text-center" height="500px">
        <div>
          <v-card elevation="0">
            <v-card-title>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Filtrar artigos"
                single-line
                hide-details
              ></v-text-field>
              <v-spacer></v-spacer>
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="mx-n2"
                    dark
                    color="teal"
                    v-bind="attrs"
                    v-on="on"
                    fab
                    small
                    @click="$router.push({ name: 'articles.create' })"
                  >
                    <v-icon dark> mdi-plus </v-icon>
                  </v-btn>
                </template>
                <span>Criar artigo</span>
              </v-tooltip>
            </v-card-title>
            <v-data-table
              style="cursor: pointer"
              height="350"
              :headers="headers"
              :items="getArticles.victoria"
              :search="search"
              :page.sync="page"
              single-select
              item-key="name"
              @click:row="insertArticle"
              hide-default-footer
              items-per-page="15"
            >
              <!--              :options.sync="options"-->
              <!--              <template v-slot:item.price="{ item }">-->
              <!--                <div class="text-right">-->
              <!--                  {{ item.price }}-->
              <!--                </div>-->
              <!--              </template>-->


            </v-data-table>
          </v-card>

          <div class="text-center pt-2">
            <v-pagination
                v-model="page"
                :length="getArticles.meta.last_page"
                @input="changePage"
            ></v-pagination>
          </div>



        </div>
      </v-sheet>
    </v-bottom-sheet>
  </div>
</template>

<script>
import eventBus from "../../main";
import { mapHelper } from "../../store/helper";
export default {
  name: "ModalArticle",
  data: () => ({
    sheet: false,
    search: "",
    page: 1,
    totalDesserts: 0,
    desserts: [],
    options: {},
    headers: [
      {
        text: "Artigo",
        align: "start",
        filterable: true,
        value: "name",
        sortable: false,
      },
      { text: "Preço", value: "price", align: "right", sortable: false },
    ],
  }),
  methods: {
    changePage() {
      return this.$store.dispatch("callArticlesNext", this.page);
    },
    insertArticle(item) {
      if (item) {
        this.$store.dispatch("setStoreInvoice", item);
        eventBus.$emit("openInsertArticle", false);
      }
    },
  },
  computed: {
    ...mapHelper,
  },
  created() {
    const self = this;
    eventBus.$on("openInsertArticle", function (event) {
      self.sheet = event;
    });
  },
};
</script>

<style scoped></style>
