import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "@/assets/css/modularCSS.css";
import "@/assets/css/all.css";
import apiServices from "../services/apiServices";
import Toasted from "vue-toasted";
// import vuelidate from "vuelidate"
const eventBus = new Vue();
export default eventBus;

Vue.config.productionTip = false;
Vue.use(Toasted);
// Vue.use(vuelidate);

new Vue({
  router,
  store,
  vuetify,
  created() {
    const cookie = localStorage.getItem("cookie_vt");
    if (cookie) {
      apiServices.customizeHeader(cookie);
      apiServices.interceptionRoute();
      const url = window.location.pathname;

      if (url !== "/" && url !== "/login" && url !== "/create/account") {
        store.dispatch("callEntityUsers").catch(() => {});
        store.dispatch("callClients").catch(() => {});
        store.dispatch("callEntity").catch(() => {});
        store.dispatch("callMe").catch(() => {});
        store.dispatch("getRole").catch(() => {});
      }
    }
  },
  render: (h) => h(App),
}).$mount("#app");
