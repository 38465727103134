<template>
  <div>
    <v-form novalidate ref="formReset" v-model="valid" lazy-validation>
      <v-text-field
        v-model="user"
        :rules="emailRules"
        label="CORREIO ELECTRÓNICO"
        color="amber lighten-4"
        required
        filled
        append-icon="mdi-account-check"
      >
        <template v-slot:label>
          <label class="white--text" style="font-size: 13px">
            CORREIO ELECTRÓNICO
          </label>
        </template>

      </v-text-field>
      <v-btn color="white lighten-2" text block @click="submitResetLogin">
        Solicitar novo acesso
      </v-btn>
      <v-btn class="my-3" block color="white" text @click="backLogin"
        ><v-icon class="mx-2">mdi-arrow-left</v-icon>Voltar</v-btn
      >
    </v-form>
  </div>
</template>

<script>
export default {
  name: "ResetLogin",
  data: () => ({
    value: true,
    valid: true,
    access: true,
    pwd: "",
    emailRules: [
      (v) => !!v || "Correio electrónico é requerido",
      (v) => /.+@.+\..+/.test(v) || "Utilizador inválido",
    ],
  }),
  methods: {
    backLogin() {
      this.$store.dispatch("setFormLogin", true);
    },

    submitResetLogin() {
      if (this.$refs.formReset.validate()) {
        this.$store.dispatch("resetLogin", {
          email: this.user,
        });
      }
    },
  },
};
</script>

<style scoped>
.theme--light.v-input input,
.theme--light.v-input textarea {
  color: #ffffff !important;
}

.theme--light.v-text-field >>> .v-input__control > .v-input__slot:before {
  border-color: #ffffff4f !important;
}

.v-text-field .v-label {
  font-size: 13px !important;
}


.theme--light.v-input >>> input {
  color: #ffffff !important;
}

.theme--light.v-input >>> textarea {
  color: #ffffff !important;
}

/**
Background input
 */
.theme--light.v-text-field--filled >>> .v-input__control > .v-input__slot {
  background: #81873114 !important;
}
</style>
