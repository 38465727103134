import Vue from "vue";
import Vuex from "vuex";
import auth from "./modules/auth/index";
import profileEntity from "./modules/entity/index";
import navTitle from "./modules/navigation/index";
import client from "./modules/client/index";
import storeInvoice from "./modules/store/storeInvoice";
import articles from "./modules/articles/articles";
import config from "./modules/docs/config";
import invoice from "./modules/invoice/index";
import subscribe from "./modules/subscribe";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    profileEntity,
    navTitle,
    client,
    storeInvoice,
    articles,
    config,
    invoice,
    subscribe,
  },
});
