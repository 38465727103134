<template>
  <div style="padding: 0">
    <v-row no-gutters>
      <v-col class="">
        <v-card
          class="pa-0"
          style="background-color: #ffffff"
          tile
          elevation="0"
          height="60"
          width="100%"
        >
          <v-sheet color="transparent" min-height="50" elevation="0">
            <v-simple-table class="pa-0 pl-0">
              <template v-slot:default>
                <thead class="text-left">
                  <tr>
                    <th class="text-left font-weight-bold">Tipo</th>
                    <th class="text-left font-weight-bold">Nome</th>
                    <th class="text-right font-weight-bold">Preço</th>
                    <th class="text-right font-weight-bold">IVA</th>
                    <th class="text-right font-weight-bold">Opções</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, k) in getArticles.victoria"
                    :key="item.name"
                    style="cursor: pointer"
                  >
                    <td class="text-left">
                      <div
                          class="text-left"
                          v-if="item.product_type === 'P'"
                      >
                        <v-btn x-small class="ma-2" outlined fab color="indigo">
                          P
                        </v-btn>
                      </div>
                      <div
                          class="text-left"
                          v-if="item.product_type === 'S'"
                      >
                        <v-btn x-small class="ma-2" outlined fab color="warning">
                          S
                        </v-btn>
                      </div>
                      <div
                          class="text-left"
                          v-if="item.product_type === 'O'"
                      >
                        <v-btn x-small class="ma-2" outlined fab color="red">
                          O
                        </v-btn>
                      </div>
                    </td>
                    <td class="text-left">
                      {{ item.name }}
                    </td>
                    <td class="text-right">
                      {{ new Intl.NumberFormat("de-DE").format(item.price) }} Kz
                    </td>
                    <td class="text-right">
                      {{ item.tax }} %
                    </td>
                    <td class="text-right">
                      <v-btn
                        class="ma-2"
                        outlined
                        fab
                        color="teal"
                        x-small
                        @click="editArticle(item)"
                      >
                        <v-icon>mdi-eye</v-icon>
                      </v-btn>

                      <v-btn
                        class="ma-2"
                        outlined
                        fab
                        color="red"
                        x-small
                        @click="deleteArticle(k, item.ref)"
                      >
                        <v-icon>mdi-delete-outline</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <div
              style="
                border-bottom: 1px dashed #f6f6f6;
                padding: 10px;
                background-color: #ffffff;
              "
            ></div>

            <div class="text-center" style="background-color: #ffffff">
              <v-pagination
                color="#607d8b"
                v-model="page"
                :length="getArticles.meta.last_page"
                @input="changePage"
              ></v-pagination>
            </div>
            <div
              style="
                border-bottom: 1px dashed #f6f6f6;
                padding: 10px;
                background-color: #ffffff;
              "
            ></div>
            <div style="padding-top: 100px"></div>
          </v-sheet>
        </v-card>
      </v-col>
    </v-row>
    <global-dialog-delete></global-dialog-delete>
  </div>
</template>
<script>
import { mapHelper } from "../../store/helper";
import eventBus from "../../main";
import GlobalDialogDelete from "../tools/GlobalDialogDelete";

export default {
  name: "CardViewArticles",
  components: { GlobalDialogDelete },
  data: () => ({
    page: 1,
  }),
  methods: {
    editArticle(item) {
      let payload = {
        name: item.name,
        price: item.price,
        cost_price: item.cost_price,
        unit_measure: item.unit_measure,
        service_type: item.service_type,
        reference: item.reference,
        series_number: item.series_number,
        qr_code: item.barCode,
        product_type: item.product_type,
        tax: item.tax,
        tax_free: item.taxFree,
      };
      this.$store.dispatch("saveEditArticle", payload);
      return this.$router.push({
        name: "articles.edit",
        params: { id: item.ref },
      });
    },

    changePage() {
      this.$store.dispatch("callArticlesNext", this.page);
    },

    deleteArticle(index, id) {
      const value = {
        item: id,
        index: index,
        method: "callDeleteArticle",
        dialog: true,
      };
      eventBus.$emit("openDialogDeleteGlobal", value);
    },
  },
  computed: {
    ...mapHelper,
  },
};
</script>

<style scoped></style>
