<template>
  <div style="padding: 5px">
    <v-row no-gutters>
      <v-col class="text-left" cols="7">
        <v-card
          class="pa-2"
          style="background-color: #f6f6f6"
          tile
          elevation="0"
          height="60"
        >
          <v-sheet color="transparent" height="50" elevation="0">
            <v-card-title class="m-size-font-14 mt-n5">{{
              baseTitle
            }}</v-card-title>
            <v-card-text class="mt-n6">{{ subtitle }}</v-card-text>
          </v-sheet>
        </v-card>
      </v-col>

      <v-col class="text-right">
        <v-card
          style="background-color: #f6f6f6"
          tile
          elevation="0"
          height="60"
        >
          <v-sheet color="transparent" height="50" elevation="0" class="" v-if="!condView">
            <v-btn class="ma-3" text color="teal" @click="linkComponent">
              <v-icon class="mx-1">mdi-arrow-left</v-icon
              ><span style="text-transform: capitalize">Voltar</span>
            </v-btn>
          </v-sheet>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "CardViewTitle",
  props: {
    nameRoute: {
      type: String,
      required: true,
    },
    baseTitle: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: true,
    },
    condView: {
      type: String
    },
  },
  data: () => ({}),
  methods: {
    linkComponent() {
      return this.$router.push({ name: this.nameRoute });
    },
  },
};
</script>

<style scoped></style>
