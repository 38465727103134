<template>
  <div style="padding: 0; margin-top: 15px">
    <v-row no-gutters>
      <v-col class="">
        <v-card
          class="pa-6"
          style="background-color: #ffffff"
          tile
          elevation="0"
          width="100%"
        >
          <v-sheet color="transparent" min-height="50" elevation="0">
            <v-card elevation="0" width="800" class="mx-auto m-txt-center">
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-row>
                  <v-col cols="12">
                    <v-textarea
                      dense
                      outlined
                      v-model="bank"
                      :rules="entityRules"
                      hint="Estes dados aparecerão nas Facturas"
                      label="Escreva os Dados Bancários da Sua Empresa"
                      placeholder="Ex: Conta1: 123456789 IBAN: AO06454784477; Conta2: 123456789 IBAN: AO06454784477"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-form>
              <div class="mt-6 text-left">
                <v-btn @click="saveBankAccount" depressed color="success">
                  Guardar
                </v-btn>
              </div>
            </v-card>
            <div
              style="
                border-bottom: 1px dashed #f6f6f6;
                padding: 10px;
                background-color: #ffffff;
              "
            ></div>
            <div
              style="
                border-bottom: 1px dashed #f6f6f6;
                padding: 10px;
                background-color: #ffffff;
              "
            ></div>
            <div style="padding-top: 100px"></div>
          </v-sheet>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapHelper } from "../../store/helper";

export default {
  name: "CardAddAccountBank",
  data: () => ({
    bank: "",
    valid: true,
    entityRules: [
      (v) => v.trim().split(" ").length <= 10 || "",
      (v) => !!v || "Campo obrigatório",
    ],
  }),
  methods: {
    saveBankAccount() {
      if (this.$refs.form.validate()) {
        let obj = {
          account: this.bank,
        };
        this.$store.dispatch("updateBankAccount", obj);
      }
    },
  },

  computed: {
    ...mapHelper,
  },

  mounted() {
    this.bank = this.$store.getters.getBankAccount;
  },
};
</script>

<style scoped></style>
