<template>
  <div style="padding: 5px">
    <v-autocomplete
      filled
      v-model="select"
      placeholder="Consumidor Final"
      :loading="loading"
      :items="items"
      :search-input.sync="search"
      cache-items
      hide-no-data
      append-icon="mdi-database-search"
      :label="!select ? 'Consumidor Final' : 'Cliente seleccionado'"
      name="client"
      item-value="id"
      item-text="name"
      return-object
      @change="storeClient"
    ></v-autocomplete>

    <v-expand-transition>
      <v-list
        v-if="select"
        class="text-left"
        color="orange lighten-4"
        style="background-color: #7f894529; margin-top: -20px"
      >
        <span v-for="(field, i) in fields" :key="i">
          <v-list-item v-if="field.key === 'name'">
            <v-list-item-content>
              <v-list-item-title
                class="text-uppercase font-weight-bold m-size-font-12"
                >NOME</v-list-item-title
              >
              <v-list-item-title
                v-text="field.value"
                class="text-uppercase m-size-font-12"
              ></v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-if="field.key === 'nif'" class="mt-n5">
            <v-list-item-content>
              <v-list-item-title
                class="text-uppercase font-weight-bold m-size-font-12"
                >NIF</v-list-item-title
              >
              <v-list-item-subtitle v-text="field.value"></v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-if="field.key === 'address'" class="mt-n5">
            <v-list-item-content>
              <v-list-item-title
                class="text-uppercase font-weight-bold m-size-font-12"
                >ENDEREÇO</v-list-item-title
              >
              <v-list-item-subtitle v-text="field.value"></v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </span>
      </v-list>
    </v-expand-transition>
  </div>
</template>

<script>
import { mapHelper } from "../../store/helper";

export default {
  name: "CardCreateClientInvoice",
  data: () => ({
    domain: "",
    valid: true,
    count: 1,
    service: "",
    loading: false,
    items: [],
    search: null,
    select: null,
    selectx: "",
  }),

  computed: {
    ...mapHelper,
    fields() {
      if (!this.select) return [];

      return Object.keys(this.select).map((key) => {
        return {
          key,
          value: this.select[key] || "n/a",
        };
      });
    },
  },
  watch: {
    search(val) {
      val && val !== this.select && this.querySelections(val);
    },
  },

  methods: {
    storeClient() {
      this.$store.dispatch("setStoreClient", this.select);
    },
    querySelections(v) {
      this.loading = true;
      setTimeout(() => {
        this.items = this.$store.getters.getClient.victoria.filter((e) => {
          return (
            (e.name || "").toLowerCase().indexOf((v || "").toLowerCase()) > -1
          );
        });
        this.loading = false;
      }, 500);
    },
  },
};
</script>

<style scoped>
.v-sheet.v-card {
  border-radius: 0 !important;
}
.theme--light.v-input input,
.theme--light.v-input textarea {
  color: #ffffff !important;
}

.theme--light.v-text-field >>> .v-input__control > .v-input__slot:before {
  border-color: cornflowerblue !important;
}
</style>
