import apiServices from "../../../../services/apiServices";
import eventBus from "../../../main";
import router from "../../../router";
import Vue from "vue";

export default {
  state: {
    forms: {
      login: true,
      resetLogin: false,
      changeLogin: false,
    },
    globalHeader: {
      cookie: null,
    },
    profile: Object,
    role: false,
  },
  mutations: {
    SET_FORM_LOGIN(state, value) {
      state.forms.login = value;
    },
    SET_FORM_RESET_LOGIN(state, value) {
      state.forms.resetLogin = value;
    },
    SET_FORM_CHANGE_LOGIN(state, value) {
      state.forms.changeLogin = value;
    },

    SET_COOKIE(state, value) {
      state.globalHeader.cookie = value;
    },
    SET_GLOBAL_HEADER(value) {
      apiServices.customizeHeader(value);
    },
    SET_SAVE_IP(state, value) {
      state.saveIp = value;
    },
    SET_SAVE_BROWSER(state, value) {
      state.saveBrowser = value;
    },

    SET_PROFILE(state, value) {
      state.profile = value;
    },
    SET_ALL_ROLES(state, value) {
      state.role = value;
    },
  },
  actions: {
    setFormLogin(context, value) {
      context.commit("SET_FORM_LOGIN", value);
      context.commit("SET_FORM_RESET_LOGIN", false);
      context.commit("SET_FORM_CHANGE_LOGIN", false);
    },
    setFormResetLogin(context, value) {
      context.commit("SET_FORM_LOGIN", false);
      context.commit("SET_FORM_RESET_LOGIN", value);
      context.commit("SET_FORM_CHANGE_LOGIN", false);
    },
    saveYouIp(context, value) {
      return context.commit("SET_SAVE_IP", value);
    },
    saveYouBrowser(context, value) {
      return context.commit("SET_SAVE_BROWSER", value);
    },

    auth(context, payload) {
      eventBus.$emit("openSpin", true);
      apiServices
        .getGenericLogin(payload)
        .then(async function (response) {
          if (response.status === 200) {
            let obj = response.data;

            if (obj.access === "change") {
              router.push({ name: "force" }).catch(() => {
                //throw new Error("Problem - Contact DIGITAL INVOICE");
              });
              return;
            }

            context.commit("SET_GLOBAL_HEADER", obj.cookie);
            localStorage.setItem("cookie_vt", obj.cookie);
            context.commit("SET_COOKIE", obj.cookie);
            apiServices.customizeHeader(obj.cookie);
            let sv = btoa(obj.role);
            localStorage.setItem("role_", sv);
            context.commit("SET_ALL_ROLES", obj.role);
            context.commit("SET_PROFILE", obj.me);

            if (obj.sub.status) {
              if (obj.role === "accounting") {
                router.push({ name: "list.documents" }).catch(() => {
                  //throw new Error("Problem - Contact DIGITAL INVOICE");
                });
                return;
              }

              if (
                obj.role === "admin" ||
                obj.role === "operator" ||
                obj.role === "gestor"
              ) {
                router.push({ name: "create.invoice" }).catch(() => {
                  //throw new Error("Problem - Contact DIGITAL INVOICE");
                });
                return;
              }

              // router.push({ name: "create.invoice" }).catch(() => {
              //   //throw new Error("Problem - Contact DIGITAL INVOICE");
              // });
              return;
              // window.location.href =
              //   "https://" + obj.url + ".victoria.ao/create/document";
              // return;
            }

            if (!obj.sub.status && obj.role === "admin") {
              //const url = "https://" + obj.url + ".victoria.ao/console";
              Vue.toasted.info(
                "Seja Benvindo! Faça subscrição para começar a facturar",
                {
                  theme: "bubble",
                  position: "top-center",
                  duration: 4000,
                }
              );

              router.push({ name: "victoria" }).catch(() => {
                //throw new Error("Problem - Contact DIGITAL INVOICE");
              });
              return;
              // window.location.href =
              //   "https://" + obj.url + ".victoria.ao/console";
              // return;
            }

            Vue.toasted.error(
              "Lamentamos. A sua subscrição expirou. Contacte o Gestor",
              {
                theme: "bubble",
                position: "top-center",
                duration: 4000,
              }
            );
            return router.push({ name: "login" }).catch(() => {
              //throw new Error("Problem - Contact DIGITAL INVOICE");
            });

            // return router.push({ name: "landing.page" }).catch(() => {
            //     //throw new Error("Problem - Contact DIGITAL INVOICE");
            // });
          }

          if (response.status === 401 || response.status === 403) {
            return "";
          }

          if (response.status === 422) {
            let message = response.data.message;
            return Vue.toasted.error(message, {
              theme: "bubble",
              position: "top-center",
              duration: 4000,
            });
          }
          return Vue.toasted.error("Pedido não processado", {
            theme: "bubble",
            position: "top-center",
            duration: 4000,
          });
        })
        .catch(function () {
          return Vue.toasted.error("Error", {
            theme: "bubble",
            position: "top-center",
            duration: 4000,
          });
        })
        .finally(function () {
          eventBus.$emit("openSpin", false);
        });
    },

    callMe(context) {
      apiServices
        .getMe()
        .then(function (response) {
          if (response.status === 200) {
            let obj = response.data;
            return context.commit("SET_PROFILE", obj.victoria);
          }

          if (response.status === 401 || response.status === 403) {
            return "";
          }
        })
        .catch(function (error) {
          Vue.toasted.error(error, {
            theme: "bubble",
            position: "top-center",
            duration: 3000,
          });
        })
        .finally(function () {
          eventBus.$emit("openOverlay", false);
        });
    },

    logout() {
      eventBus.$emit("openSpin", true);
      apiServices
        .logout()
        .then(function (response) {
          if (response.status === 200) {
            let obj = response.data;
            // return !!obj.status;
            if (obj.success) {
              localStorage.removeItem("cookie_vt");
              localStorage.removeItem("role_");
              localStorage.removeItem("navBarVitJs");
              router.push({ name: "landing.page" }).catch(() => {
                //throw new Error("Problem - Contact DIGITAL INVOICE");
              });

              return;
              //localStorage.removeItem("cookie_vt");
            }
          }
          if (response.status === 401 || response.status === 403) {
            return "";
          }
          if (response.status === 401) {
            let message = "Nao tem autorização";
            return Vue.toasted.error(message, {
              theme: "bubble",
              position: "top-center",
              duration: 4000,
            });
          }
        })
        .catch(function (error) {
          Vue.toasted.error(error, {
            theme: "bubble",
            position: "top-center",
            duration: 3000,
          });
        })
        .finally(function () {
          eventBus.$emit("openOverlay", false);
        });
    },

    getRole(context) {
      eventBus.$emit("openOverlay", true);
      return apiServices
        .callGetRole()
        .then(function (response) {
          if (response.status === 200) {
            let obj = response.data;
            let sv = btoa(obj.role);
            localStorage.setItem("role_", sv);
            return context.commit("SET_ALL_ROLES", obj);
          }
          if (response.status === 401 || response.status === 403) {
            return "";
          }
        })
        .catch(function (error) {
          Vue.toasted.error(error, {
            theme: "bubble",
            position: "top-center",
            duration: 3000,
          });
        })
        .finally(function () {
          eventBus.$emit("openOverlay", false);
        });
    },

    /**
     * Reset
     */

    resetLogin(context, payload) {
      eventBus.$emit("openSpin", true);
      apiServices
        .getResetLogin(payload)
        .then(function (response) {
          if (response.status === 200) {
            let obj = response.data;
            if (obj.status === true) {
              return Vue.toasted.success(
                "Solicitação aceite. Consulte o seu correio electrónico para concluir",
                {
                  theme: "bubble",
                  position: "top-center",
                  duration: 4000,
                }
              );
            }
          }
          if (response.status === 422) {
            return Vue.toasted.error("Solicitação não aceite", {
              theme: "bubble",
              position: "top-center",
              duration: 4000,
            });
          }
          return Vue.toasted.error("Solicitação não aceite", {
            theme: "bubble",
            position: "top-center",
            duration: 4000,
          });
        })
        .catch(function () {
          return Vue.toasted.error("Error", {
            theme: "bubble",
            position: "top-center",
            duration: 4000,
          });
        })
        .finally(function () {
          eventBus.$emit("openSpin", false);
        });
    },

    /**
     * Change
     */

    changeLogin(context, payload) {
      eventBus.$emit("openSpin", true);
      apiServices
        .getChangeLogin(payload)
        .then(function (response) {
          if (response.status === 200) {
            let obj = response.data;
            if (obj.status === true) {
              return Vue.toasted.success(
                "A sua palavra-passe foi actualizada com sucesso!",
                {
                  theme: "bubble",
                  position: "top-center",
                  duration: 4000,
                }
              );
            }
          }
          if (response.status === 422) {
            return Vue.toasted.error("Pedido não processado", {
              theme: "bubble",
              position: "top-center",
              duration: 4000,
            });
          }
          return Vue.toasted.error("Pedido não processado", {
            theme: "bubble",
            position: "top-center",
            duration: 4000,
          });
        })
        .catch(function () {
          return Vue.toasted.error("Error", {
            theme: "bubble",
            position: "top-center",
            duration: 4000,
          });
        })
        .finally(function () {
          eventBus.$emit("openSpin", false);
        });
    },

    /**
     * Create Account
     *
     */

    callCreateAccount(context, payload) {
      eventBus.$emit("openSpin", true);
      apiServices
        .createAccount(payload)
        .then(function (response) {
          if (response.status === 201) {
            Vue.toasted.success("Seu registo foi criado com sucesso!", {
              theme: "bubble",
              position: "top-center",
              duration: 4000,
            });
            router.push({ name: "login" }).catch(() => {
              //throw new Error("Problem - Contact DIGITAL INVOICE");
            });
            return;
          }
          if (response.status === 422) {
            let message = response.data.message;
            return Vue.toasted.error(message, {
              theme: "bubble",
              position: "top-center",
              duration: 4000,
            });
          }
          if (response.status === 401 || response.status === 403) {
            return "";
          }
          return Vue.toasted.error("Pedido não processado", {
            theme: "bubble",
            position: "top-center",
            duration: 4000,
          });
        })
        .catch(function () {
          return Vue.toasted.error("Error", {
            theme: "bubble",
            position: "top-center",
            duration: 4000,
          });
        })
        .finally(function () {
          eventBus.$emit("openSpin", false);
        });
    },
  },
  getters: {
    getFormLogin(state) {
      return state.forms.login;
    },
    getFormResetLogin(state) {
      return state.forms.resetLogin;
    },
    getFormChangeLogin(state) {
      return state.forms.login;
    },
    getYourIp(state) {
      return state.saveIp;
    },
    getYourBrowser(state) {
      return state.saveBrowser;
    },
    getProfile(state) {
      return state.profile;
    },

    getRole(state) {
      return state.role;
    },
  },
};
