<template>
  <v-app>
    <div id="intro">
      <div class="mask" style="background-color: rgba(0, 0, 0, 0.95)">
        <v-main>
          <logo-login style="margin-top: 100px"></logo-login>
          <v-card
            max-width="650"
            elevation="0"
            class="mx-auto m-center m-txt-center mt-2 transparent"
          >
            <div
              style="
                margin-left: auto;
                margin-right: auto;
                text-align: left;
                padding-top: 50px;
                min-height: 600px;
                margin-top: -80px;
              "
              class="mx-auto"
            >
              <terms-component></terms-component>
            </div>
          </v-card>
        </v-main>
        <div>
          <v-card
            class="transparent mx-auto m-center m-txt-center"
            elevation="0"
          >
            <v-card-text class="grey--text m-size-font-12">
              &copy;Direitos Reservados à DIGITAL INVOICE
            </v-card-text>
          </v-card>
        </div>
      </div>
    </div>
  </v-app>
</template>

<script>


import TermsComponent from "../../components/website/TermsComponent";
import LogoLogin from "../../components/logo/LogoLogin";
export default {
  name: "Terms",
  components: {LogoLogin, TermsComponent },
};
</script>

<style scoped>
#intro {
  /*background-image: url("../../assets/vendor/Namibe.jpg");*/
  /*background-position: right;*/
  /*height: 240vh;*/
  /*overflow: hidden;*/
}

/*.mask {*/
/*  position: absolute;*/
/*  top: 0;*/
/*  right: 0;*/
/*  bottom: 0;*/
/*  left: 0;*/
/*  width: 100%;*/
/*  height: 100%;*/
/*  overflow: hidden;*/
/*  background-attachment: fixed;*/
/*}*/

.m-delcio-animation {
  -webkit-animation-name: Banimation;
  -webkit-animation-duration: 4s;
  -webkit-animation-direction: alternate;
  -webkit-animation-iteration-count: infinite;
  animation-name: Banimation;
  animation-duration: 4s;
  animation-direction: alternate;
  animation-iteration-count: infinite;
}
</style>
