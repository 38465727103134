<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" max-width="800" persistent>
      <v-card class="text-center mx-auto" height="500">
        <v-card-title class="text-uppercase">
          <span class="m-top-5 text-center mx-auto m-txt-center m-center">{{
            this.plan
          }}</span>
        </v-card-title>
        <v-card-text class="text-h5 text-center mx-auto"> </v-card-text>
        <v-card-text class="text-center">
          <v-row>
            <v-col cols="12" class="mx-auto m-center m-txt-center">
              <v-row sm="4" align="center" justify="center" no-gutters>
                <v-card class="mx-auto" width="320" elevation="0">
                  <v-card-text class="black--text text-left">
                    Pagamento Por Referência
                  </v-card-text>
                  <v-card-text class="mt-0">
                    <template>
                      <v-simple-table>
                        <template v-slot:default>
                          <tbody>
                            <tr>
                              <td>
                                <div class="text-left">
                                  <strong class="black--text m-size-font-12 text-left">Entidade</strong>
                                </div>
                              </td>
                              <!--                              <td>{{ item.name }}</td>-->
                              <td class="text-right">{{ entity }}</td>
                            </tr>
                            <tr>
                              <td>
                                <div class="text-left">
                                  <strong class="black--text m-size-font-12 text-left">Referência</strong>
                                </div>
                              </td>
                              <!--                              <td>{{ item.name }}</td>-->
                              <td class="text-right">{{ reference }}</td>
                            </tr>
                            <tr>
                              <td>
                                <div class="text-left">
                                  <strong class="black--text m-size-font-12 text-left">Preço</strong>
                                </div>
                              </td>
                              <!--                              <td>{{ item.name }}</td>-->
                              <td class="text-right">{{ price }}</td>
                            </tr>
                            <tr>
                              <td>
                                <div class="text-left">
                                  <strong class="black--text m-size-font-12 text-left">Expira em</strong>
                                </div>
                              </td>
                              <!--                              <td>{{ item.name }}</td>-->
                              <td class="text-right">{{ data }}</td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </template>
                  </v-card-text>
                </v-card>

                <v-card
                  class="mx-auto"
                  width="320"
                  height="280"
                  elevation="0"
                  color="#f6f6f6"
                >
                  <v-card-text class="black--text text-left">
                    Observação
                  </v-card-text>
                  <v-card-text class="mt-n2 text-left">
                    <template>
                      <v-simple-table>
                        <template v-slot:default>
                          <tbody>
                            <tr>
                              <td>
                                <div class="m-padd-5">
                                  <strong class="black--text m-size-font-12 text-left mt-6"
                                    >Após o pagamento, a sua subscrição será activada automaticamente. Obrigado pela confiança!</strong
                                  >
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </template>
                  </v-card-text>
                </v-card>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text class="text-center mx-auto mt-6">
          <v-btn color="red darken-1" text @click="dialog = false">
            Fechar
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import eventBus from "../../main";

export default {
  name: "DialogConfirmPayReference",
  data: () => ({
    dialog: false,
    entity: "",
    price: "",
    reference: "",
    data: "",
  }),
  methods: {},
  created() {
    const self = this;
    eventBus.$on("openDialogConfirmPaymentReference", function (event) {
      self.dialog = event.dialog;
      self.entity = event.entity;
      self.price = event.price;
      self.reference = event.reference;
      self.data = event.data;
    });
  },
};
</script>

<style scoped></style>
