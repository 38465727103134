<template>
  <div class="mx-auto my-3 m-txt-center m-center background">
    <v-card elevation="0" class="transparent" min-height="200" max-width="100%">
      <v-row justify="center">
        <v-col cols="12">
          <v-row sm="4" align="center" justify="center">
            <v-card
              width="98%"
              style="border-radius: 0 !important"
              min-width="300"
              class="m-txt-center"
              elevation="0"
              min-height="70"
            >
              <card-clients></card-clients>
            </v-card>
          </v-row>

          <v-row sm="4" class="mt-6" align="center" justify="center">
            <v-card
              width="98%"
              style="border-radius: 0 !important"
              min-width="300"
              class="m-txt-center transparent"
              elevation="0"
              min-height="70"
            >
              <div v-show="!getClient.victoria" style="margin-top: 60px">
                <div><v-icon size="38">mdi-information-outline</v-icon></div>
                <v-card-text class="m-size-font-12">Sem lista de clientes</v-card-text>
              </div>
              <card-list-clients></card-list-clients>
            </v-card>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
    <open-alert></open-alert>
    <spin></spin>
  </div>
</template>

<script>
import OpenAlert from "../../components/tools/Alert";
import Spin from "../../components/tools/Spin.vue";
import CardClients from "../../components/clients/CardClients";
import CardListClients from "../../components/clients/CardListClients";
import {mapHelper} from "../../store/helper";
export default {
  name: "PageListClient",
  components: {
    CardListClients,
    CardClients,
    OpenAlert,
    Spin,
  },
  computed: {
    ...mapHelper,
  },
  Spin,
};
</script>

<style scoped>
.background {
  /*background-color: #ecf1f7;*/
  background-color: #f9f9f9bd;
  min-height: 1000px;
  max-height: 2000px !important;
}
</style>
