import store from "../store";

export function middlewareMultiStage(
  to,
  from,
  next,
  roles,
  callback1,
  callback2
) {
  const isAuthenticated = localStorage.getItem("cookie_vt");
  const role_ = localStorage.getItem("role_");
  let role = store.getters.getRole;
  const allowedRoles = roles;
  let userRoles;
  if (role) {
    userRoles = role;
  } else if (role_) {
    userRoles = atob(role_);
  }

  if (!isAuthenticated) {
    next("/login");
    return;
  }

  const hasPermission = allowedRoles.includes(userRoles);

  if (hasPermission) {
    store.dispatch(callback1).then(() => {
      store.dispatch(callback2).then(() => {
        next();
      });
    });
  } else {
    next("/unauthorized");
  }
}
