<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="400">
      <v-card>
        <v-card-text class="text-uppercase text-center m-txt-center">
          <div class="text-center m-txt-center">
            <h4 class="text-center m-txt-center pa-4">
              {{ obj.info ? obj.info : "Emitir Recibo" }}
            </h4>
          </div>
        </v-card-text>
        <v-card-text class="m-txt-center">
          <v-text-field
            v-model="payment"
            :rules="txtRules"
            placeholder="Ex.: 13,000.00"
            @input="formatCurrency"
            label="Montante recebido"
            hint="Dinheiro Pago"
            persistent-hint
          ></v-text-field>
          <v-select
            v-model="methodTypeValue"
            :rules="nameRules"
            :items="methodType"
            item-text="text"
            item-value="service"
            hint="Método de Pagamento"
            label="Escolha o método de pagamento"
          >
          </v-select>

          <v-textarea
            v-model="obs"
            label="Observações"
            hint="Observações não são obrigatórias"
          ></v-textarea>
        </v-card-text>
        <v-card-text class="text-uppercase m-txt-center">
          <v-btn color="teal" text @click="downloadDocument">
            Emitir recibo
          </v-btn>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="teal darken-1" text @click="dialog = false">
            Sair
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import eventBus from "../../main";

export default {
  name: "DialogReceipt",
  data: () => ({
    dialog: false,
    obs: "",
    payment: "",
    obj: Object,
    methodTypeValue: "",
    methodType: [
      { text: "Trasferência Bancária", service: "TB" },
      { text: "Cartão crédito", service: "CC" },
      { text: "Numerário", service: "CD" },
      { text: "Cartão Débito", service: "CD" },
      { text: "Pagamento Por Referência", service: "MB" },
      { text: "Dinheiro Electrónico", service: "DE" },
      { text: "Outros", service: "OU" },
    ],
    txtRules: [
      (v) => v.length <= 60 || "Máximo 60 caracteres",
      (v) => !!v || "Campo obrigatório",
      (v) => /^[0-9,.]*$/.test(v) || "Deve ser valor monetário",
    ],
  }),
  methods: {
    formatCurrency() {
      // Remove all non-numeric and non-decimal characters
      let formattedValue = this.payment.replace(/[^0-9.]/g, "");

      // Separate decimals by a dot and remove leading zeros
      const parts = formattedValue.split(".");
      if (parts.length > 1) {
        parts[0] = parts[0].replace(/^0+/, "");
        formattedValue = parts.join(".");
      }

      // Add a comma for thousands separation
      const regex = /(\d)(?=(\d{3})+(?!\d))/g;
      formattedValue = formattedValue.replace(regex, "$1,");

      // Update the v-model to display the formatted value
      this.payment = formattedValue;
    },

    // createItem() {
    //   const ReformattedValue = this.price.replace(/[^0-9.]/g, "");
    //   if (this.$refs.form.validate()) {
    //     const payload = {
    //       price: ReformattedValue,
    //       iva: this.iva,
    //       name: this.name,
    //       id_service: 1,
    //       qtd: "1",
    //     };
    //     this.$store.dispatch("createItem", payload);
    //   }
    // },
    downloadDocument() {

      const ReformattedValue = this.payment.replace(/[^0-9.]/g, "");
      let payload = {

        receipt: {
          payment: parseFloat(ReformattedValue),
          method: this.methodTypeValue,
          obs: this.obs,
        },
        document: this.obj,
      };

      this.$store.dispatch("createReceipt", payload);

    },
  },

  computed: {
    txtRules() {
      return [this.$v.payment.v, this.$v.payment.v, this.$v.payment.v];
    },
  },
  created() {
    const self = this;
    eventBus.$on("openDialogReceipt", function (event) {
      self.dialog = event.dialog;
      self.obj = event.invoice;
    });
  },
};
</script>

<style scoped></style>
