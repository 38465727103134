import apiServices from "../../../../services/apiServices";
import eventBus from "../../../main";
import Vue from "vue";
//import router from "../../../router";

export default {
  state: {
    orderInvoice: "",
  },
  mutations: {
    SET_ORDER_INVOICE(state, value) {
      state.orderInvoice = value;
    },
  },
  actions: {
    consultReceipt(context, payload) {
      eventBus.$emit("openSpin", true);
      apiServices
        .callConsultReceipt(payload)
        .then(function (response) {
          let obj = response.data;
          const ev = {
            dialog: true,
            invoice: payload,
          };

            if (response.status === 401 || response.status === 403) {
                return "";
            }
          if (response.status === 200) {
            if (obj.status === true) {
              let load = {
                dialog: true,
                order: obj.order,
                invoice: payload.ref,
              };
              eventBus.$emit("openDialogDownloadReceipt", load);
              // console.log(payload);
              return;
            }

            eventBus.$emit("openDialogReceipt", ev);

            // const ev = {
            //   dialog: true,
            //   invoice: item,
            // };
            // eventBus.$emit("openDialogReceipt", ev);
            // console.log(item);

            // return Vue.toasted.success("Documento criado com sucesso", {
            //   theme: "bubble",
            //   position: "top-center",
            //   duration: 4000,
            // });
          } else {
            return Vue.toasted.error(
              "Ocorreu algum erro a tentar criar o documento",
              {
                theme: "bubble",
                position: "top-center",
                duration: 4000,
              }
            );
          }
        })
        .catch(function () {
          //console.log("Error" + error);
          return Vue.toasted.error("Erro de processamento", {
            theme: "bubble",
            position: "top-center",
            duration: 4000,
          });
        })
        .finally(function () {
          eventBus.$emit("openSpin", false);
        });
    },

    createReceipt(context, payload) {
      eventBus.$emit("openSpin", true);
      apiServices
        .callCreateReceipt(payload)
        .then(function (response) {
          let obj = response.data;
          if (response.status === 200) {
            Vue.toasted.success("Documento criado com sucesso", {
              theme: "bubble",
              position: "top-center",
              duration: 4000,
            });

            let load = {
              dialog: true,
              order: obj.order,
              invoice: payload.document.ref,
              format: 1,
            };
            const ev = {
              dialog: false,
              invoice: payload,
            };
            console.log(load);
            eventBus.$emit("openDialogReceipt", ev);
            eventBus.$emit("openDialogDownloadReceipt", load);
          } else {
            return Vue.toasted.error(
              "Ocorreu algum erro a tentar criar o documento",
              {
                theme: "bubble",
                position: "top-center",
                duration: 4000,
              }
            );
          }
        })
        .catch(function () {
          //console.log("Error" + error);
          return Vue.toasted.error("Erro de processamento", {
            theme: "bubble",
            position: "top-center",
            duration: 4000,
          });
        })
        .finally(function () {
          eventBus.$emit("openSpin", false);
        });
    },

    downloadReceipt(context, payload) {
      eventBus.$emit("openSpin", true);
      apiServices
        .callDownloadReceipt(payload)
        .then(function (response) {

            if (response.status === 401 || response.status === 403) {
                return "";
            }
          if (response.status === 200) {
            Vue.toasted.success("Documento a ser descarregado", {
              theme: "bubble",
              position: "top-center",
              duration: 4000,
            });

            const blob = new Blob([response.data]);
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            const d = new Date();
            let year =
              d.getFullYear() +
              "_" +
              d.getMonth() +
              "_" +
              d.getDay() +
              "_" +
              d.getSeconds();
            const nameReport = "Victoria_invoice_receipt" + year + ".pdf";
            link.setAttribute("download", nameReport);
            document.body.appendChild(link);
            link.click();

            return;
          } else {
            console.log(response.data);
            return Vue.toasted.error(
              "Ocorreu algum erro a tentar descarregar o documento",
              {
                theme: "bubble",
                position: "top-center",
                duration: 4000,
              }
            );
          }
        })
        .catch(function () {
          //console.log("Error" + error);
          return Vue.toasted.error("Erro de processamento", {
            theme: "bubble",
            position: "top-center",
            duration: 4000,
          });
        })
        .finally(function () {
          eventBus.$emit("openSpin", false);
        });
    },

    downloadDocs(context, payload) {
      eventBus.$emit("openSpin", true);
      apiServices
        .callDownloadDocs(payload)
        .then(function (response) {

            if (response.status === 401 || response.status === 403) {
                return "";
            }
          if (response.status === 200) {
            Vue.toasted.success("Documento a ser descarregado", {
              theme: "bubble",
              position: "top-center",
              duration: 4000,
            });

            const blob = new Blob([response.data]);
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            const d = new Date();
            let year =
              d.getFullYear() +
              "_" +
              d.getMonth() +
              "_" +
              d.getDay() +
              "_" +
              d.getSeconds();
            const nameReport = "Victoria_invoice_" + year + ".pdf";
            link.setAttribute("download", nameReport);
            document.body.appendChild(link);
            link.click();

            return;
          } else {
            return Vue.toasted.error(
              "Ocorreu algum erro a tentar descarregar o documento",
              {
                theme: "bubble",
                position: "top-center",
                duration: 4000,
              }
            );
          }
        })
        .catch(function () {
          //console.log("Error" + error);
          return Vue.toasted.error("Erro de processamento", {
            theme: "bubble",
            position: "top-center",
            duration: 4000,
          });
        })
        .finally(function () {
          eventBus.$emit("openSpin", false);
        });
    },

    createInvoice(context, payload) {
      eventBus.$emit("openSpin", true);
      apiServices
        .callCreateInvoice(payload)
        .then(function (response) {

          if (response.status === 200) {
            let obj = response.data;
            context.commit("SET_ORDER_INVOICE", obj);
            let objectData = {
              dialog: true,
              invoice: obj,
              format: 1,
            };
            console.log(objectData);
            eventBus.$emit("openDialogFT", objectData);
            return;
          }
          if (response.status === 422) {
            let message = response.data.message;
            return Vue.toasted.error(message, {
              theme: "bubble",
              position: "top-center",
              duration: 4000,
            });
          }
          if (response.status === 403) {
            let message = "Não tem uma subscrição activa";
            return Vue.toasted.error(message, {
              theme: "bubble",
              position: "top-center",
              duration: 4000,
            });
          }

          if (response.status === 400) {
            let message = response.data.message;
            return Vue.toasted.error(message, {
              theme: "bubble",
              position: "top-center",
              duration: 4000,
            });
          }
          return Vue.toasted.error("Erro de processamento", {
            theme: "bubble",
            position: "top-center",
            duration: 4000,
          });
        })
        .catch(function () {
          //console.log("Error" + error);
          let payload = {
            color: "error",
            text: "Seu pedido não foi processado",
            errorLogin: true,
            right: "right",
          };
          eventBus.$emit("openInfo", payload); // emit the event to the bus
        })
        .finally(function () {
          eventBus.$emit("openSpin", false);
        });
    },

    createInvoiceNc(context, payload) {
      eventBus.$emit("openSpin", true);
      apiServices
        .callCreateInvoice(payload)
        .then(function (response) {

          if (response.status === 200) {
            let obj = response.data;
            context.commit("SET_ORDER_INVOICE", obj);
            let objectData = {
              dialog: true,
              invoice: obj,
              format: 1,
            };
            console.log(objectData);
            eventBus.$emit("openDialogFT", objectData);
            return;
          }
          if (response.status === 422) {
            let message = response.data.message;
            return Vue.toasted.error(message, {
              theme: "bubble",
              position: "top-center",
              duration: 4000,
            });
          }

          if (response.status === 400) {
            let message = response.data.message;
            return Vue.toasted.error(message, {
              theme: "bubble",
              position: "top-center",
              duration: 4000,
            });
          }
          return Vue.toasted.error("Erro de processamento", {
            theme: "bubble",
            position: "top-center",
            duration: 4000,
          });
        })
        .catch(function () {
          //console.log("Error" + error);
          let payload = {
            color: "error",
            text: "Seu pedido não foi processado",
            errorLogin: true,
            right: "right",
          };
          eventBus.$emit("openInfo", payload); // emit the event to the bus
        })
        .finally(function () {
          eventBus.$emit("openSpin", false);
        });
    },

    createInvoiceFR(context, payload) {
      eventBus.$emit("openSpin", true);
      apiServices
        .callCreateInvoice(payload)
        .then(function (response) {
          if (response.status === 200) {
            let obj = response.data;
            context.commit("SET_ORDER_INVOICE", obj);
            let objectData = {
              dialog: true,
              invoice: obj,
            };
            eventBus.$emit("openDialogFT", objectData);
            return;
          }
          if (response.status === 422) {
            let message = response.data.message;
            return Vue.toasted.error(message, {
              theme: "bubble",
              position: "top-center",
              duration: 4000,
            });
          }

          if (response.status === 400) {
            let message = response.data.message;
            return Vue.toasted.error(message, {
              theme: "bubble",
              position: "top-center",
              duration: 4000,
            });
          }


          return Vue.toasted.error("Erro de processamento", {
            theme: "bubble",
            position: "top-center",
            duration: 4000,
          });
        })
        .catch(function () {
          return Vue.toasted.error("Erro de processamento", {
            theme: "bubble",
            position: "top-center",
            duration: 4000,
          });
        })
        .finally(function () {
          eventBus.$emit("openSpin", false);
        });
    },

    createInvoiceFP(context, payload) {
      eventBus.$emit("openSpin", true);
      apiServices
        .callCreateInvoice(payload)
        .then(function (response) {
          if (response.status === 200) {
            let obj = response.data;
            context.commit("SET_ORDER_INVOICE", obj);
            let objectData = {
              dialog: true,
              invoice: obj,
            };
            eventBus.$emit("openDialogFT", objectData);
            return;
          }
          if (response.status === 422) {
            let message = response.data.message;
            return Vue.toasted.error(message, {
              theme: "bubble",
              position: "top-center",
              duration: 4000,
            });
          }

          if (response.status === 400) {
            let message = response.data.message;
            return Vue.toasted.error(message, {
              theme: "bubble",
              position: "top-center",
              duration: 4000,
            });
          }
          return Vue.toasted.error("Erro de processamento", {
            theme: "bubble",
            position: "top-center",
            duration: 4000,
          });
        })
        .catch(function () {
          return Vue.toasted.error("Erro de processamento", {
            theme: "bubble",
            position: "top-center",
            duration: 4000,
          });
        })
        .finally(function () {
          eventBus.$emit("openSpin", false);
        });
    },

    createInvoiceGD(context, payload) {
      eventBus.$emit("openSpin", true);
      apiServices
        .callCreateInvoice(payload)
        .then(function (response) {
          if (response.status === 200) {
            let obj = response.data;
            context.commit("SET_ORDER_INVOICE", obj);
            let objectData = {
              dialog: true,
              invoice: obj,
            };
            eventBus.$emit("openDialogFT", objectData);
            return;
          }
          if (response.status === 422) {
            let message = response.data.message;
            return Vue.toasted.error(message, {
              theme: "bubble",
              position: "top-center",
              duration: 4000,
            });
          }

          if (response.status === 400) {
            let message = response.data.message;
            return Vue.toasted.error(message, {
              theme: "bubble",
              position: "top-center",
              duration: 4000,
            });
          }
          return Vue.toasted.error("Erro de processamento", {
            theme: "bubble",
            position: "top-center",
            duration: 4000,
          });
        })
        .catch(function () {
          //console.log("Error" + error);
          return Vue.toasted.error("Erro de processamento", {
            theme: "bubble",
            position: "top-center",
            duration: 4000,
          });
        })
        .finally(function () {
          eventBus.$emit("openSpin", false);
        });
    },
  },
  getters: {
    getIdInvoiceToPrint(state) {
      return state.orderInvoice;
    },
  },
};
