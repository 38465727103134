export default {
  state: {
    doc: {
      dataEmit: false,
      dataDue: false,
      dataAvailable: false,
      payments: false,
      discount: 0,
      percentageDiscount: 0,
      transport: false,
    },

    creditNote: {
      motive: "",
      invoiceReference: "",
      typeNote: "",
    },

    docDisc: {
      discount: 0,
      percentageDiscount: 0,
    },

    saveTotalGlobal: 0,
    saveTotalFinal: 0,
    saveTotalFinalFreeTax: 0,
    saveTotalFinalTax: 0,
    saveTotalDiscount: 0,

    invoiceType: [
      "Factura",
      "Factura Recibo",
      "Factura Pró-Forma",
      // "Guia de Transporte",
      "Nota de Crédito",
    ],
    //0, 2, 5, 7, 14
    tax: [
      { tax: "Isenção (0%)", value: 0 },
      { tax: "Taxa Reduzida (2%)", value: 2 },
      { tax: "Taxa Intermédia (5%)", value: 5 },
      { tax: "Taxa de  (7%)", value: 7 },
      { tax: "Taxa Normal (14%)", value: 14 },
    ],
    taxFree: [
      "M00:Regime Simplificado",
      "M02:Transmissão de bens e serviço não sujeita",
      "M04:IVA – Regime de Exclusão",
      "M10:Isento nos termos da alínea a) do nº1 do artigo 12.º do CIVA",
      "M11:Isento nos termos da alínea b) do nº1 do artigo 12.º do CIVA",
      "M12:Isento nos termos da alínea c) do nº1 do artigo 12.º do CIVA",
      "M13:Isento nos termos da alínea d) do nº1 do artigo 12.º do CIVA",
      "M14:Isento nos termos da alínea e) do nº1 do artigo 12.º do CIVA",
      "M15:Isento nos termos da alínea f) do nº1 do artigo 12.º do CIVA",
      "M16:Isento nos termos da alínea g) do nº1 do artigo 12.º do CIVA",
      "M17:Isento nos termos da alínea h) do nº1 do artigo 12.º do CIVA",
      "M18:Isento nos termos da alínea i) do nº1 artigo 12.º do CIVA",
      "M19:Isento nos termos da alínea j) do nº1 do artigo 12.º do CIVA",
      "M20:Isento nos termos da alínea k) do nº1 do artigo 12.º do CIVA",
      "M21:Isento nos termos da alínea l) do nº1 do artigo 12.º do CIVA",
      "M22:Isento nos termos da alínea m) do artigo 12.º do CIVA",
      "M23:Isento nos termos da alínea n) do artigo 12.º do CIVA",
      "M24:Isento nos termos da alínea 0) do artigo 12.º do CIVA",
      "M80:Isento nos termos da alínea a) do nº1 do artigo 14.º",
      "M81:Isento nos termos da alínea b) do nº1 do artigo 14.º",
      "M82:Isento nos termos da alínea c) do nº1 do artigo 14.º",
      "M83:Isento nos termos da alínea d) do nº1 do artigo 14.º",
      "M84:Isento nos termos da alínea e) do nº1 do artigo 14.º",
      "M85:Isento nos termos da alínea a) do nº2 do artigo 14.º",
      "M86:Isento nos termos da alínea b) do nº2 do artigo 14.º",
      "M30:Isento nos termos da alínea a) do artigo 15.º do CIVA",
      "M31:Isento nos termos da alínea b) do artigo 15.º do CIVA",
      "M32:Isento nos termos da alínea c) do artigo 15.º do CIVA",
      "M33:Isento nos termos da alínea d) do artigo 15.º do CIVA",
      "M34:Isento nos termos da alínea e) do artigo 15.º do CIVA",
      "M35:Isento nos termos da alínea f) do artigo 15.º do CIVA",
      "M36:Isento nos termos da alínea g) do artigo 15.º do CIVA",
      "M37:Isento nos termos da alínea h) do artigo 15.º do CIVA",
      "M38:Isento nos termos da alínea i) do artigo 15.º do CIVA",
      "M90:Isento nos termos da alínea a) do nº1 do artigo 16.º",
      "M91:Isento nos termos da alínea b) do nº1 do artigo 16.º",
      "M92:Isento nos termos da alínea c) do nº1 do artigo 16.º",
      "M93:Isento nos termos da alínea d) do nº1 do artigo 16.º",
      "M94:Isento nos termos da alínea e) do nº1 do artigo 16.º",
    ],
    viewDoc: {
      available: "Factura",
      ft: true,
      fr: false,
      fp: false,
      gd: false,
      nc: false,
    },
  },
  mutations: {
    SET_VIEW_DOC_FT(state, value) {
      state.viewDoc.ft = value;
    },
    SET_VIEW_DOC_FR(state, value) {
      state.viewDoc.fr = value;
    },
    SET_VIEW_DOC_FP(state, value) {
      state.viewDoc.fp = value;
    },
    SET_VIEW_DOC_GD(state, value) {
      state.viewDoc.gd = value;
    },

    SET_VIEW_DOC_NC(state, value) {
      state.viewDoc.nc = value;
    },

    SET_DOC_DISC(state, value) {
      state.docDisc.discount = value.discount;
      state.docDisc.percentageDiscount = value.percentageDiscount;
    },

    SET_NOTE_CREDIT_MOTIVE(state, value) {
      state.creditNote.motive = value.motive;
      state.creditNote.invoiceReference = value.invoiceReference;
      state.creditNote.typeNote = value.typeNote;
    },

    SET_TOTAL_GLOBAL(state, value) {
      state.saveTotalGlobal = value;
    },

    SET_TOTAL_FINAL(state, value) {
      state.saveTotalFinal = value;
    },

    SET_TOTAL_FINAL_FREE_TAX(state, value) {
      state.saveTotalFinalFreeTax = value;
    },

    SET_TOTAL_FINAL_TAX(state, value) {
      state.saveTotalFinalTax = value;
    },

    SET_TOTAL_DISCOUNT(state, value) {
      state.saveTotalDiscount = value;
    },
  },
  actions: {
    setDocDisc(context, value) {
      context.commit("SET_DOC_DISC", value);
    },

    setNoteCreditMotive(context, value) {
      context.commit("SET_NOTE_CREDIT_MOTIVE", value);
    },
    saveTotalGlobal(context, value) {
      context.commit("SET_TOTAL_GLOBAL", value);
    },

    saveTotalFinal(context, value) {
      context.commit("SET_TOTAL_FINAL", value);
    },

    saveTotalFinalFreeTax(context, value) {
      context.commit("SET_TOTAL_FINAL_FREE_TAX", value);
    },

    saveTotalFinalTax(context, value) {
      context.commit("SET_TOTAL_FINAL_TAX", value);
    },

    saveTotalDiscount(context, value) {
      context.commit("SET_TOTAL_DISCOUNT", value);
    },

    setViewDocFT(context) {
      context.commit("SET_VIEW_DOC_FT", true);
      context.commit("SET_VIEW_DOC_FR", false);
      context.commit("SET_VIEW_DOC_FP", false);
      context.commit("SET_VIEW_DOC_GD", false);
      context.commit("SET_VIEW_DOC_NC", false);
    },

    setViewDocFR(context) {
      context.commit("SET_VIEW_DOC_FR", true);
      context.commit("SET_VIEW_DOC_FT", false);
      context.commit("SET_VIEW_DOC_FP", false);
      context.commit("SET_VIEW_DOC_GD", false);
      context.commit("SET_VIEW_DOC_NC", false);
    },

    setViewDocFP(context) {
      context.commit("SET_VIEW_DOC_FP", true);
      context.commit("SET_VIEW_DOC_FT", false);
      context.commit("SET_VIEW_DOC_FR", false);
      context.commit("SET_VIEW_DOC_GD", false);
      context.commit("SET_VIEW_DOC_NC", false);
    },

    setViewDocGD(context) {
      context.commit("SET_VIEW_DOC_GD", true);
      context.commit("SET_VIEW_DOC_FT", false);
      context.commit("SET_VIEW_DOC_FP", false);
      context.commit("SET_VIEW_DOC_FR", false);
      context.commit("SET_VIEW_DOC_NC", false);
    },

    setViewDocNC(context) {
      context.commit("SET_VIEW_DOC_NC", true);
      context.commit("SET_VIEW_DOC_GD", false);
      context.commit("SET_VIEW_DOC_FT", false);
      context.commit("SET_VIEW_DOC_FP", false);
      context.commit("SET_VIEW_DOC_FR", false);
    },
  },
  getters: {
    getTotalGlobal(state) {
      return state.saveTotalGlobal;
    },

    getTotalFinal(state) {
      return state.saveTotalFinal;
    },
    getDocDisc(state) {
      return state.docDisc;
    },
    getDocObjectFt(state) {
      return state.doc;
    },

    getViewDocFt(state) {
      return state.viewDoc.ft;
    },
    getViewDocFr(state) {
      return state.viewDoc.fr;
    },
    getViewDocFp(state) {
      return state.viewDoc.fp;
    },
    getViewDocGd(state) {
      return state.viewDoc.gd;
    },

    getViewDocNc(state) {
      return state.viewDoc.nc;
    },
    getInvoiceType(state) {
      return state.invoiceType;
    },

    getTax(state) {
      return state.tax;
    },
    getTaxFree(state) {
      return state.taxFree;
    },

    getTotalFinalFreeTax(state) {
      return state.saveTotalFinalFreeTax;
    },

    getTotalFinalTax(state) {
      return state.saveTotalFinalTax;
    },

    getTotalDiscount(state) {
      return state.saveTotalDiscount;
    },

    creditNote(state) {
      return state.creditNote;
    },
  },
};
