<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="400">
      <v-card>
        <v-card-text class="text-uppercase text-center m-txt-center">
          <h4 style="margin-left:0; margin-right: 0 !important; padding-top: 20px">{{ obj.info ? obj.info : "Documento Criado com Sucesso" }}</h4>
        </v-card-text>
        <v-card-text class="m-txt-center">
          <v-icon size="48" class="mx-10 mt-6" color="teal"
            >mdi-download</v-icon
          >
          <!--          <v-icon size="68" class="mx-14">mdi-at</v-icon>-->
        </v-card-text>
        <v-card-text class="text-uppercase m-txt-center">
          <v-btn text @click="downloadDocument"> Descarregar </v-btn>
          <!--          <v-btn text> Enviar por e-mail </v-btn>-->
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="teal darken-1" text @click="dialog = false">
            Sair
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import eventBus from "../../main";

export default {
  name: "DialogFT",
  data: () => ({
    dialog: false,
    obj: Object,
  }),
  methods: {
    downloadDocument() {
      // return window.open(
      //   "http://localhost:8000/api/v1/entity/invoice/" + this.obj.order + "/" + this.obj.id, "_blank"
      // );
      console.log(this.obj);
      this.$store.dispatch("downloadDocs", this.obj);
    },
  },
  created() {
    const self = this;
    eventBus.$on("openDialogFT", function (event) {
      self.dialog = event.dialog;
      self.obj = event.invoice;
    });
  },
};
</script>

<style scoped></style>
