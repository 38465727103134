<template>
  <div style="padding: 0">
    <v-row no-gutters>
      <v-col class="">
        <v-card
          class="pa-0"
          style="background-color: #ffffff"
          tile
          elevation="0"
          height="60"
          width="100%"
        >
          <v-sheet
            color="transparent"
            height="50"
            elevation="0"
            class="pa-2"
            v-if="getStoreInvoice.length === 0"
          >
            <v-card-text class="mt-0"
              ><v-icon size="20" class="mx-2"
                >mdi-format-list-bulleted-square</v-icon
              >Nenhum artigo foi adicionado ainda.</v-card-text
            >
          </v-sheet>

          <v-sheet
            color="transparent"
            min-height="50"
            elevation="0"
            v-if="getStoreInvoice.length > 0"
          >
            <v-simple-table class="pa-0 pl-0">
              <template v-slot:default>
                <thead class="text-left">
                  <tr>
                    <th class="text-left font-weight-bold"></th>
                    <th class="text-left font-weight-bold">Artigo</th>
                    <th class="text-right font-weight-bold">Desc.</th>
                    <th class="text-right font-weight-bold">IVA</th>
                    <th class="text-right font-weight-bold">P.Unit.</th>
                    <th class="text-right font-weight-bold">Qtd.</th>
                    <th class="text-right font-weight-bold">Total</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, k) in getStoreInvoice"
                    :key="item.name"
                    style="cursor: pointer"
                  >
                    <td class="text-right">
                      <v-icon color="red" @click="deleteItem(k, item)"
                        >mdi-delete-outline</v-icon
                      >
                    </td>
                    <td class="text-left" @click="editItem(k, item)">
                      {{ item.name }}
                    </td>
                    <td class="text-right" @click="editItem(k, item)">
                      {{ discountView(item.discount) }}
                      {{ discountView(item.discount) ? "Akz" : "" }}
                      {{
                        discountView(item.discount) &&
                        discountView(item.percentageDiscount)
                          ? "+"
                          : ""
                      }}
                      {{ discountView(item.percentageDiscount) }}
                      {{ discountView(item.percentageDiscount) ? "%" : "" }}
                    </td>
                    <td class="text-right" @click="editItem(k, item)">
                      {{ item.tax }} %
                    </td>
                    <td class="text-right" @click="editItem(k, item)">
                      {{ price(item.tax, item.price) }} Akz
                    </td>
                    <td class="text-right" @click="editItem(k, item)">
                      {{ item.qty }}
                    </td>
                    <td class="text-right" @click="editItem(k, item)">
                      {{
                        subTotal(
                          item.tax,
                          item.qty,
                          item.price,
                          item.discount,
                          item.percentageDiscount
                        )
                      }}
                      Akz
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <div
              style="
                border-bottom: 1px dashed #f6f6f6;
                padding: 10px;
                background-color: #ffffff;
              "
            ></div>
            <v-simple-table>
              <template v-slot:default dense>
                <tbody class="mt-6">
                  <tr v-if="parseInt(discountResume(getStoreInvoice)) > 0">
                    <td class="text-right font-weight-bold">Desconto</td>
                    <td class="text-right font-weight-bold">
                      {{ discountResume(getStoreInvoice) }} Akz
                    </td>
                  </tr>
                  <tr>
                    <td
                      class="text-right font-weight-bold"
                      style="width: 500px; max-width: 500px"
                    >
                      Total
                    </td>
                    <td
                      class="text-right font-weight-bold"
                      style="min-width: 200px"
                    >
                      {{ totalGlobal(getStoreInvoice) }} Akz
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>

            <div
              style="
                border-bottom: 1px dashed #f6f6f6;
                padding: 10px;
                background-color: #ffffff;
              "
            ></div>
            <v-card-text
              class="text-right"
              style="
                margin-left: auto;
                margin-right: 0;
                background-color: white;
              "
            >
              Impostos
            </v-card-text>
            <v-simple-table>
              <template v-slot:default dense>
                <thead class="text-left">
                  <tr>
                    <th class="text-right font-weight-bold">Taxa</th>
                    <th class="text-right font-weight-bold">Base</th>
                    <th class="text-right font-weight-bold">Iva</th>
                    <th class="text-right font-weight-bold">Total</th>
                  </tr>
                </thead>
                <tbody class="mt-6">
                  <tr
                    v-if="
                      resumeIncidentCalculate(getStoreInvoice, 0).count === 0 &&
                      resumeIncidentCalculate(getStoreInvoice, 0).pb > 0
                    "
                  >
                    <td class="text-right">0 %</td>
                    <td class="text-right">
                      {{ resumeIncidentCalculate(getStoreInvoice, 0).base }} Akz
                    </td>
                    <td class="text-right">
                      {{ resumeIncidentCalculate(getStoreInvoice, 0).IVA }} Akz
                    </td>
                    <td class="text-right">
                      {{ resumeIncidentCalculate(getStoreInvoice, 0).total }}
                      Akz
                    </td>
                  </tr>
                  <tr
                    v-if="
                      resumeIncidentCalculate(getStoreInvoice, 2).count === 2
                    "
                  >
                    <td class="text-right">2 %</td>
                    <td class="text-right">
                      {{ resumeIncidentCalculate(getStoreInvoice, 2).base }} Akz
                    </td>
                    <td class="text-right">
                      {{ resumeIncidentCalculate(getStoreInvoice, 2).IVA }} Akz
                    </td>
                    <td class="text-right">
                      {{ resumeIncidentCalculate(getStoreInvoice, 2).total }}
                      Akz
                    </td>
                  </tr>
                  <tr
                    v-if="
                      resumeIncidentCalculate(getStoreInvoice, 5).count === 5
                    "
                  >
                    <td class="text-right">5 %</td>
                    <td class="text-right">
                      {{ resumeIncidentCalculate(getStoreInvoice, 5).base }} Akz
                    </td>
                    <td class="text-right">
                      {{ resumeIncidentCalculate(getStoreInvoice, 5).IVA }} Akz
                    </td>
                    <td class="text-right">
                      {{ resumeIncidentCalculate(getStoreInvoice, 5).total }}
                      Akz
                    </td>
                  </tr>

                  <tr
                    v-if="
                      resumeIncidentCalculate(getStoreInvoice, 7).count === 7
                    "
                  >
                    <td class="text-right">7 %</td>
                    <td class="text-right">
                      {{ resumeIncidentCalculate(getStoreInvoice, 7).base }} Akz
                    </td>
                    <td class="text-right">
                      {{ resumeIncidentCalculate(getStoreInvoice, 7).IVA }} Akz
                    </td>
                    <td class="text-right">
                      {{ resumeIncidentCalculate(getStoreInvoice, 7).total }}
                      Akz
                    </td>
                  </tr>
                  <tr
                    v-if="
                      resumeIncidentCalculate(getStoreInvoice, 14).count === 14
                    "
                  >
                    <td class="text-right">14 %</td>
                    <td class="text-right">
                      {{ resumeIncidentCalculate(getStoreInvoice, 14).base }}
                      Akz
                    </td>
                    <td class="text-right">
                      {{ resumeIncidentCalculate(getStoreInvoice, 14).IVA }} Akz
                    </td>
                    <td class="text-right">
                      {{ resumeIncidentCalculate(getStoreInvoice, 14).total }}
                      Akz
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>

            <div style="padding-top: 30px"></div>

            <!--          CREDIT NOTE-->
            <v-row
              v-if="getViewDocNc"
              sm="4"
              class="mt-6"
              align="center"
              justify="center"
            >
              <v-card
                width="98%"
                style="border-radius: 0 !important"
                min-width="300"
                class="m-txt-center transparent"
                elevation="0"
                min-height="70"
              >
                <card-list-note-credit-reference></card-list-note-credit-reference>
              </v-card>
            </v-row>
          </v-sheet>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapHelper } from "../../store/helper";
import eventBus from "../../main";
import Vue from "vue";
import CardListNoteCreditReference from "./CardListNoteCreditReference";

export default {
  name: "CardListArticles",
  components: { CardListNoteCreditReference },
  data: () => ({
    desserts: [
      {
        name: "Desconto",
        total: 0,
      },
      {
        name: "Total",
        total: 0,
      },
    ],
  }),

  methods: {
    discountView(item) {
      if (item == 0 || item === "") return "";
      return item;
    },
    deleteItem(item) {
      const value = {
        item: item,
        dialog: true,
      };
      eventBus.$emit("openDialogAdvice", value);
    },

    discountResume(obj) {
      let vDoc = this.$store.getters.getDocDisc;
      let sum = 0;
      obj.map(function (value) {
        let addTax = 0;

        if (value.tax > 0) {
          addTax = (parseFloat(value.tax) * parseFloat(value.price)) / 100;
        }

        let disc = value.discount;
        let discPercent = value.percentageDiscount;
        const discPercentage =
          (parseFloat(discPercent) *
            (parseFloat(value.price) + parseFloat(addTax))) /
          100;
        let result = parseFloat(disc) + parseFloat(discPercentage);
        // console.log(result);
        sum += result;
      });
      let discPercentageDoc =
        (parseFloat(vDoc.percentageDiscount) *
          parseFloat(this.$store.getters.getTotalGlobal)) /
        100;
      let resultDoc = parseFloat(vDoc.discount) + parseFloat(discPercentageDoc);
      // alert(resultDoc);
      let f = parseFloat(sum) + parseFloat(resultDoc);
      this.$store.dispatch("saveTotalDiscount", parseFloat(f));
      return this.currencyFormat(f);
    },
    editItem(k, obj) {
      const value = {
        key: k,
        dialog: true,
        object: obj,
      };
      eventBus.$emit("openModalUpdateArticle", value);
      this.$store.dispatch("setModalUpdateArticle", obj.name);
    },
    subTotal(tax, qty, price, disc, percentage) {
      //para ajustar precos face aos descontos percentuais
      let addTax = 0;
      if (tax > 0) {
        addTax = (parseFloat(tax) * parseFloat(price)) / 100;
      }

      const calcIva = (parseFloat(tax) * parseFloat(price)) / 100;
      const discPercentage =
        (parseFloat(percentage) * (parseFloat(price) + parseFloat(addTax))) /
        100;
      let total =
        parseFloat(price) * parseInt(qty) +
        parseFloat(calcIva) -
        parseFloat(disc) -
        parseFloat(discPercentage);

      if (isNaN(total)) return 0;
      if (total < 0) {
        return Vue.toasted.error(
          "SubTotal não pode ser negativo. Verifique se aplicou desconto superior ao total",
          {
            theme: "bubble",
            position: "top-center",
            duration: 4000,
          }
        );
      }

      return new Intl.NumberFormat("de-DE", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(total);
    },

    totalGlobal(obj) {
      let sum = 0;
      let sumFreeTax = 0;

      obj.map(function (value) {
        let addTax = 0;
        if (value.tax > 0) {
          addTax = (parseFloat(value.tax) * parseFloat(value.price)) / 100;
        }

        let price = value.price;
        let tax = value.tax;
        let percentage = value.percentageDiscount;
        let calcIva = (parseFloat(tax) * parseFloat(price)) / 100;
        const discPercentage =
          (parseFloat(percentage) * (parseFloat(price) + parseFloat(addTax))) /
          100;
        let disc = value.discount;
        let qty = value.qty;

        // Ver este point com calma

        let result =
          parseFloat(price) * parseInt(qty) +
          parseFloat(calcIva) -
          (parseFloat(disc) + parseFloat(discPercentage));

        //Rever estava mais passei para menos
        let resultFreeTax =
          parseFloat(price) * parseInt(qty) -
          (parseFloat(disc) + parseFloat(discPercentage));

        sum += result;
        sumFreeTax += resultFreeTax;
      });
      if (isNaN(sum)) return 0;
      this.$store.dispatch("saveTotalGlobal", parseFloat(sum));

      let vDoc = this.$store.getters.getDocDisc;
      const discPercentageDoc =
        (parseFloat(vDoc.percentageDiscount) * parseFloat(sum)) / 100;

      const discPercentageDocFreeTax =
        (parseFloat(vDoc.percentageDiscount) * parseFloat(sumFreeTax)) / 100;

      let resultDoc = parseFloat(vDoc.discount) + parseFloat(discPercentageDoc);
      let resultDocFreeTax =
        parseFloat(vDoc.discount) + parseFloat(discPercentageDocFreeTax);

      if (isNaN(resultDoc)) {
        resultDoc = 0;
      }
      let fg = parseFloat(sum) - parseFloat(resultDoc);
      let fgFreeTax = parseFloat(sumFreeTax) - parseFloat(resultDocFreeTax);
      if (fg < 0) {
        return Vue.toasted.error(
          "Total não pode ser negativo. Verifique se aplicou desconto superior ao preço total",
          {
            theme: "bubble",
            position: "top-center",
            duration: 4000,
          }
        );
      }

      let totalTax = parseFloat(fg) - parseFloat(Math.abs(fgFreeTax));

      this.$store.dispatch("saveTotalFinalTax", parseFloat(Math.abs(totalTax)));
      this.$store.dispatch("saveTotalFinal", parseFloat(fg));
      this.$store.dispatch(
        "saveTotalFinalFreeTax",
        parseFloat(Math.abs(fgFreeTax))
      );
      return this.currencyFormat(fg);
    },

    calculateIva(tax, price) {
      return (parseFloat(tax) * parseFloat(price)) / 100;
    },

    blocTax(obj, iv) {
      let base = 0;
      let total = 0;
      let Iva = 0;
      let count = 0;
      let vDoc = this.$store.getters.getDocDisc;
      obj.map(function (value) {
        if (value.tax === iv) {
          let addTax = 0;

          if (value.tax > 0) {
            addTax = (parseFloat(value.tax) * parseFloat(value.price)) / 100;
          }

          let price = value.price;
          let tax = value.tax;
          let percentage = value.percentageDiscount;
          let calcIva = (parseFloat(tax) * parseFloat(price)) / 100;

          let discPercentage =
            (parseFloat(percentage) *
              (parseFloat(price) + parseFloat(addTax))) /
            100;

          let discPercentageBase =
            (parseFloat(percentage) * parseFloat(price)) / 100;

          let disc = value.discount;
          let qty = value.qty;

          let result =
            parseFloat(price) * parseInt(qty) -
            (parseFloat(disc) + parseFloat(discPercentageBase));
          base += result;

          let tot =
            parseFloat(price) * parseInt(qty) +
            parseFloat(calcIva) -
            parseFloat(disc) -
            parseFloat(discPercentage);
          total += tot;
          count = value.tax;

          let calcIvaBase = (parseFloat(tax) * parseFloat(result)) / 100;

          Iva += calcIvaBase;
        }
      });

      // let vDoc = this.$store.getters.getDocDisc;
      // const discPercentageDoc = (parseFloat(vDoc.percentageDiscount) * parseFloat(this.$store.getters.getTotalGlobal)) / 100;
      // let resultDoc = parseFloat(vDoc.discount) + parseFloat(discPercentageDoc);
      // let f = parseFloat(base) - parseFloat(resultDoc);

      //Rever esta linha
      // let vDoc = this.$store.getters.getDocDisc;
      let discPercentageGlobalBase =
        (parseFloat(vDoc.percentageDiscount) * parseFloat(base)) / 100 +
        parseFloat(vDoc.discount);

      // const discPercentageGlobalTotal =
      //   (parseFloat(vDoc.percentageDiscount) * parseFloat(total)) / 100;

      // let discPercentageGlobalForIva =
      //   (parseFloat(vDoc.percentageDiscount) * parseFloat(Iva)) / 100;

      let finalIva =
        ((parseFloat(base) - parseFloat(discPercentageGlobalBase)) *
          parseFloat(count)) /
        100;

      // let ivaResume =
      //   parseFloat(base) -
      //   parseFloat(discPercentageGlobalBase) +
      //   parseFloat(Iva) -
      //   parseFloat(discPercentageGlobalForIva);

      let ivaResume =
        parseFloat(base) -
        parseFloat(discPercentageGlobalBase) +
        parseFloat(finalIva);

      return {
        base: this.currencyFormat(
          parseFloat(base) - parseFloat(discPercentageGlobalBase)
        ),
        // base: this.currencyFormat(f),
        // IVA: this.currencyFormat(Iva),
        // IVA: this.currencyFormat(finalIva),
        IVA: this.currencyFormat(parseFloat(finalIva)),

        // IVA: this.currencyFormat(
        //     parseFloat(finalIva) - parseFloat(discPercentageGlobalForIva)
        // )
        //total: this.currencyFormat(ivaResume),
        total: this.currencyFormat(ivaResume),
        // total: this.currencyFormat(parseFloat(total)-parseFloat(resultDoc)),
        count: count,
        pb: base,
        yx: total,
        yt: Iva,
        // pb: f,
      };
    },
    resumeIncidentCalculate(obj, controller) {
      return this.blocTax(obj, controller);
    },

    currencyFormat(currency) {
      return new Intl.NumberFormat("de-DE", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(currency);
    },

    price(tax, price) {
      const calcIva = (parseFloat(tax) * parseFloat(price)) / 100;
      let p = parseFloat(price) + parseFloat(calcIva);
      if (isNaN(p)) return 0;
      return new Intl.NumberFormat("de-DE", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(p);
    },
  },
  computed: {
    ...mapHelper,
  },
  // mounted() {
  //   alert(1254.2 > 0)
  //   alert(this.resumeIncidentCalculate(this.$store.getters.getStoreInvoice, 0).pb)
  // }
};
</script>

<style scoped>
/*.hover:hover {*/
/*  background-color: #7f894529 !important;*/
/*}*/
</style>
