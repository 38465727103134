<template>
  <div class="filter">
    <v-card width="320" class="mx-auto transparent hero-min" elevation="0">
      <h1 class="mx-auto text-center">
        <span class="mx-auto text-center text-header white--text"
          >Software de Facturação online</span
        >
      </h1>
    </v-card>
    <v-card-text
      style="max-width: 400px"
      class="mx-auto mt-12 text-center white--text text-subtitle-1 text-sub-header margin-line font-weight-regular"
    >
      O Victória foi desenhado para facilitar o seu processo de facturação.
      Facture mais, canse-se menos ao preço mais baixo do mercado.
    </v-card-text>

    <v-card-text
      style="max-width: 400px"
      class="mx-auto mt-6 text-center white--text text-subtitle-1 text-sub-header margin-line font-weight-regular"
    >
      <span style="background-color: forestgreen; padding: 5px"
        ><v-icon color="white">mdi-check</v-icon> Certificado Nº
        427/AGT/2023</span
      >
    </v-card-text>
    <div
      class="mx-auto mt-12 text-center"
      style="opacity: 1 !important; z-index: 100 !important"
    >
      <v-btn large outlined color="white" dark @click="createAccount"
        >Começar a facturar
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: "HeaderContent",
  methods: {
    createAccount() {
      return this.$router.push({ name: "create.account" });
    },
  },
};
</script>

<style scoped>
/*fb8c00*/
.text-header {
  font-family: "Fira Sans Condensed", sans-serif;
  line-height: 42px;
  font-size: 38px;
  font-weight: 500;
}
.filter {
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 0%,
    rgb(0 0 0) 35%,
    #ae7402 100%
  ) !important;
  opacity: 0.7;
  margin-top: -200px;
  height: 634px;
}
.hero-min {
  padding-top: 100px !important;
}

@media (max-width: 1347px) {
  .filter {
    margin-top: -313px;
    /*background: linear-gradient(*/
    /*  90deg,*/
    /*  rgba(2, 0, 36, 1) 0%,*/
    /*  rgb(0 0 0) 35%,*/
    /*  #ae7402 100%*/
    /*) !important;*/
    /*opacity: 0.7;*/
    /*height: 634px;*/
  }
  .hero-min {
    padding-top: 139px !important;
  }
}
</style>
