<template>
  <div class="mx-auto my-3 m-txt-center m-center background">
    <v-card elevation="0" class="transparent" min-height="200" max-width="100%">
      <v-row justify="center">
        <v-col cols="12">
          <v-row sm="4" align="center" justify="center">
            <v-card
              width="98%"
              style="border-radius: 0 !important"
              min-width="300"
              class="m-txt-center"
              elevation="0"
              min-height="70"
            >
              <card-title-subscription></card-title-subscription>
            </v-card>
          </v-row>

          <v-row sm="4" class="mt-6" align="center" justify="center">
            <v-card
              width="98%"
              style="border-radius: 0 !important"
              min-width="300"
              class="m-txt-center transparent"
              elevation="0"
              min-height="70"
            >
              <section-subscription></section-subscription>
            </v-card>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
    <!--    <open-alert></open-alert>-->
    <!--    <spin></spin>-->
  </div>
</template>

<script>
import CardTitleSubscription from "../../components/subscription/cards/CardTitleSubscription";
import SectionSubscription from "../../components/subscription/SectionSubscription";
export default {
  name: "Subscription",
  components: {SectionSubscription, CardTitleSubscription },
};
</script>

<style scoped></style>
