<template>
  <div style="padding: 0">
    <v-row no-gutters>
      <v-col class="">

        <v-card
          class="pa-0"
          style="background-color: #ffffff"
          tile
          elevation="0"
          height="60"
          width="100%"
        >
          <v-sheet color="transparent" min-height="50" elevation="0">
            <v-simple-table class="pa-0 pl-0">
              <template v-slot:default>
                <thead class="text-left">
                  <tr>
                    <th class="text-center font-weight-bold">Tipo</th>
                    <th class="text-left font-weight-bold">Documento</th>
                    <th class="text-left font-weight-bold">Cliente</th>
                    <th class="text-left font-weight-bold">Total</th>
                    <th class="text-left font-weight-bold">Data Criação</th>
                    <th class="text-left font-weight-bold">Status</th>
                    <th class="text-right font-weight-bold">Opções</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="item in getOrderOfStore.victoria"
                    :key="item.invoice_number"
                    style="cursor: pointer"
                  >
                    <td class="text-left" @click="viewDocument(item)">
                      <div
                        class="text-center"
                        v-if="item.doc_type === 'factura'"
                      >
                        <v-btn small class="ma-2" outlined fab color="indigo">
                          FT
                        </v-btn>
                      </div>

                      <div
                        class="text-center"
                        v-if="item.doc_type === 'factura recibo'"
                      >
                        <v-btn small class="ma-2" outlined fab color="teal">
                          FR
                        </v-btn>
                      </div>

                      <div
                        class="text-center"
                        v-if="item.doc_type === 'recibo'"
                      >
                        <v-btn small class="ma-2" outlined fab color="secondary">
                          RC
                        </v-btn>
                      </div>

                      <div
                        class="text-center"
                        v-if="item.doc_type === 'nota de crédito'"
                      >
                        <v-btn small class="ma-2" outlined fab color="warning">
                          NC
                        </v-btn>
                      </div>

                      <div
                          class="text-center"
                          v-if="item.doc_type === 'factura pró-forma'"
                      >
                        <v-btn small class="ma-2" outlined fab color="success">
                          PP
                        </v-btn>
                      </div>
                    </td>
                    <td class="text-left" @click="viewDocument(item)">
                      {{ item.invoice_number }}
                    </td>
                    <td class="text-left" @click="viewDocument(item)">
                      {{ item.client_name ? item.client_name: 'CONSUMIDOR FINAL'}}
                    </td>
                    <td class="text-left" @click="viewDocument(item)">
                      {{ new Intl.NumberFormat("de-DE").format(item.total) }} Kz
                    </td>

                    <td class="text-left" @click="viewDocument(item)">
                      {{ splitDate(item.date) }}
                    </td>

                    <td class="text-left">
                      {{ splitDate(item.status) }}
                    </td>

                    <td class="text-right">
                      <v-tooltip left v-if="item.doc_type === 'factura'">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-bind="attrs"
                            v-on="on"
                            class="ma-2"
                            outlined
                            fab
                            color="indigo"
                            x-small
                            @click="printReceipt(item)"
                          >
                            <v-icon>mdi-note</v-icon>
                          </v-btn>
                        </template>
                        <span>Emitir Recibo</span>
                      </v-tooltip>

                      <v-tooltip left>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-bind="attrs"
                            v-on="on"
                            class="ma-2"
                            outlined
                            fab
                            color="teal"
                            x-small
                            @click="viewDocument(item)"
                          >
                            <v-icon>mdi-eye</v-icon>
                          </v-btn>
                        </template>
                        <span>Ver Documento</span>
                      </v-tooltip>

                      <v-tooltip left>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-bind="attrs"
                            v-on="on"
                            class="ma-2"
                            outlined
                            fab
                            color="blue-grey"
                            x-small
                            @click="downloadDocument(item.ref, item.controller)"
                          >
                            <v-icon>mdi-download</v-icon>
                          </v-btn>
                        </template>
                        <span>Descarregar Documento</span>
                      </v-tooltip>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <div
              style="
                border-bottom: 1px dashed #f6f6f6;
                padding: 10px;
                background-color: #ffffff;
              "
            ></div>

            <div class="text-center" style="background-color: #ffffff">
              <v-pagination
                color="#607d8b"
                v-model="page"
                :length="getOrderOfStore.meta.last_page"
                @input="changePage"
              ></v-pagination>
            </div>
            <div
              style="
                border-bottom: 1px dashed #f6f6f6;
                padding: 10px;
                background-color: #ffffff;
              "
            ></div>
            <div style="padding-top: 100px"></div>
          </v-sheet>
        </v-card>
      </v-col>
    </v-row>
    <dialog-delete-article></dialog-delete-article>
  </div>
</template>
<script>
import { mapHelper } from "../../store/helper";
// import eventBus from "../../main";
import DialogDeleteArticle from "../tools/DialogDeleteArticle";
import eventBus from "../../main";

export default {
  name: "CardViewDocuments",
  components: { DialogDeleteArticle },
  data: () => ({
    page: 1,
  }),

  methods: {
    splitDate(dateString) {
      let d = dateString.split("T");
      return d[0];
    },
    changePage() {
      this.$store.dispatch("getAllOrderNextPage", this.page);
    },
    viewDocument(item) {
      return this.$router.push({
        name: "documents.view",
        params: { id: item.ref },
      });
    },

    printReceipt(item) {
      // const ev = {
      //   dialog: true,
      //   invoice: item,
      // };

      this.$store.dispatch("consultReceipt", item);
      // eventBus.$emit("openDialogReceipt", ev);
      // console.log(item);
    },

    downloadDocument(r, c) {
      let payload = {
        order: r,
        id: c,
        info: "Documento preparado",
        format: 2,
      };

      let objectData = {
        dialog: true,
        invoice: payload,
      };
      return eventBus.$emit("openDialogFT", objectData);
      //return this.$router.push({ name: "articles.edit" , params: { id: id }});
      // alert(id);
      //this.$store.dispatch("", id)
      // const value = {
      //   item: id,
      //   dialog: true,
      // };
    },

    cancelDocument(item) {
      let payload = {
        item: item.ref,
        status: item.status,
        dialog: true,
      };
      return eventBus.$emit("openDialogCancelDocument", payload);
    },
  },
  computed: {
    ...mapHelper,
  },
};
</script>

<style scoped></style>
