<template>
  <div>
    <v-expansion-panels focusable>
      <v-expansion-panel>
        <v-expansion-panel-header class="text-left">
          <v-card-text style="padding: 0">
            <v-icon>mdi-calendar-month</v-icon> <span>Data de Emissão</span>
          </v-card-text>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-menu
            ref="menu"
            v-model="dataEmit"
            :close-on-content-click="false"
            :return-value.sync="date1"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                class=""
                v-model="date1"
                prepend-icon="mdi-calendar-month"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="date1"
              no-title
              scrollable
              @input="$refs.menu.save(date1)"
            >
            </v-date-picker>
          </v-menu>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <!--    3 PART-->
      <v-expansion-panel>
        <v-expansion-panel-header class="text-left">
          <v-card-text style="padding: 0">
            <v-icon>mdi-calendar-month</v-icon>
            <span>Data de Disponibilização</span>
          </v-card-text>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-menu
            ref="menu"
            v-model="dataAvailable"
            :close-on-content-click="false"
            :return-value.sync="date3"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                class=""
                v-model="date3"
                prepend-icon="mdi-calendar-month"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="date3"
              no-title
              scrollable
              @input="$refs.menu.save(date3)"
            >
            </v-date-picker>
          </v-menu>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <!--    5 PART-->
      <v-expansion-panel>
        <v-expansion-panel-header class="text-left">
          <v-card-text style="padding: 0">
            <v-icon>mdi-sale</v-icon> <span>Desconto</span>
          </v-card-text>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-text-field
            v-model="percentageDiscount"
            label="Desconto percentual"
            hint="%"
            type="number"
            min="0"
            persistent-hint
            @change="changeD"
          ></v-text-field>

          <v-text-field
            v-model="discount"
            disabled
            label="Desconto em dinheiro"
            hint="Akz"
            persistent-hint
            required
            type="number"
            min="0"
            @change="changeD"
          ></v-text-field>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <!--    7 PART FORMATION-->
      <v-expansion-panel>
        <v-expansion-panel-header class="text-left" :style="bgFormation">
          <v-card-text style="padding: 0">
            <v-icon>mdi-school</v-icon> <span>Aprenda em Modo Formação</span>
          </v-card-text>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-radio-group
            v-model="formation"
            column
            class="mt-10"
            @change="changeFormation(formation)"
          >
            <v-radio
              class="mt-n5"
              label="Sim"
              color="orange darken-3"
              value="true"
            >
              <template v-slot:label>
                <label class="black--text" style="font-size: 14px"> Sim </label>
              </template>
            </v-radio>
            <v-radio label="Não" color="red darken-3" value="false">
              <template v-slot:label>
                <label class="black--text" style="font-size: 14px"> Não </label>
              </template>
            </v-radio>
          </v-radio-group>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-btn class="ma-0 mt-6" color="success" block @click="callCreateInvoice">
      <v-icon size="15">mdi-checkbox-marked-circle-outline</v-icon>
      <span class="mx-2">Criar Nota de Crédito</span>
    </v-btn>
  </div>
</template>

<script>
import Vue from "vue";

export default {
  name: "CardPanelNC",

  data: () => ({
    date1: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    date2: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    date3: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    menu: false,
    menu1: false,
    menu2: false,
    menu3: false,
    dataEmit: false,
    dataDue: false,
    dataAvailable: false,
    payments: [],
    discount: 0,
    percentageDiscount: 0,
    transport: "false",
    formation: "false",
    bgFormation: "",
  }),

  methods: {
    callCreateInvoice() {
      let obj = {
        client: this.$store.getters.getStoreClient
          ? this.$store.getters.getStoreClient
          : null,
        total: this.$store.getters.getTotalFinal,
        total_ise: this.$store.getters.getTotalFinalFreeTax, //getTotalFinalFreeTax and getTotalFinalTax
        total_tax: this.$store.getters.getTotalFinalTax, //getTotalFinalFreeTax and getTotalFinalTax
        //global_discount: this.$store.getters.getTotalFinalTax, //getTotalFinalFreeTax and getTotalFinalTax
        total_discount: this.$store.getters.getTotalDiscount
          ? this.$store.getters.getTotalDiscount
          : 0,
        docType: this.$store.getters.getStoreDocType
          ? this.$store.getters.getStoreDocType
          : null,
        articles:
          this.$store.getters.getStoreInvoice.length > 0
            ? this.$store.getters.getStoreInvoice
            : null,
        def: {
          dataEmit: this.date1,
          dataDue: this.date2,
          dataAvailable: this.date3,
          payments: this.payments,
          discount: this.discount,
          percentageDiscount: this.percentageDiscount,
          transport: this.transport,
          formation: this.formation,
          format: 1,
        },
        obs: {
          motive: this.$store.getters.creditNote.motive,
          invoice_reference: this.$store.getters.creditNote.invoiceReference,
          type_note: this.$store.getters.creditNote.typeNote,
        },
      };

      // let vDoc = {
      //   discount: this.discount,
      //   percentageDiscount: this.percentageDiscount,
      // };
      if (
        obj.docType == null ||
        isNaN(obj.def.percentageDiscount) ||
        isNaN(obj.def.discount)
      ) {
        return false;
      }

      if (obj.articles == null) {
        Vue.toasted.error("Não seleccionou nenhum artigo", {
          theme: "bubble",
          position: "top-center",
          duration: 4000,
        });
        return false;
      }

      // if (obj.def.payments.length == 0) {
      //   Vue.toasted.error("Escolha um método de pagamento", {
      //     theme: "bubble",
      //     position: "top-center",
      //     duration: 4000,
      //   });
      //   return false;
      // }

      if (
        obj.obs.motive == null ||
        obj.obs.motive.length === 0 ||
        obj.obs.invoice_reference.length === 0
      ) {
        Vue.toasted.error(
          "Nº da factura e Motivo para emissão de Nota são obrigatórios",
          {
            theme: "bubble",
            position: "top-center",
            duration: 4000,
          }
        );
        return false;
      }

      if (obj.obs.type_note == null || obj.obs.type_note.length === 0) {
        Vue.toasted.error("Tipo de Nota de Crédito é obrigatório", {
          theme: "bubble",
          position: "top-center",
          duration: 4000,
        });
        return false;
      }

      //console.log(obj);
      this.$store.dispatch("createInvoiceNc", obj);
      //eventBus.$emit("openDialogFT", true);
    },
    changeD() {
      let vDoc = {
        discount: this.discount,
        percentageDiscount: this.percentageDiscount,
      };
      this.$store.dispatch("setDocDisc", vDoc);
    },

    changeFormation(value) {
      //alert(value)
      if (value == "true") {
        this.bgFormation = "background-color: #ffe0b2"; //#ffe0b2
        Vue.toasted.info(
          "Seleccionou o Modo Formação Instantânia. Todos os documentos emitidos neste Modo, não têm valores fiscais",
          {
            theme: "bubble",
            position: "top-center",
            duration: 6000,
          }
        );
      } else {
        this.bgFormation = ""; //#ffe0b2
        Vue.toasted.success(
          "Desactivou o Modo Formação Instantânia. Todos os documentos emitidos terão valores fiscais",
          {
            theme: "bubble",
            position: "top-center",
            duration: 6000,
          }
        );
      }
    },
  },
};
</script>

<style scoped>
.controls .v-radio > .v-label {
  font-size: 12px;
}
.cursor {
  cursor: pointer;
}
</style>
