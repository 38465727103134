<template>
  <div style="padding: 0; margin-top: 15px">
    <v-row no-gutters>
      <v-col class="">
        <v-card
          class="pa-6"
          style="background-color: #ffffff"
          tile
          elevation="0"
          width="100%"
        >
          <v-sheet color="transparent" min-height="50" elevation="0">
            <v-card elevation="0" width="800" class="mx-auto m-txt-center">
              <v-card-text class="text-left" style="margin-bottom: 15px">
                <b>Importante</b>: Período inicial e final trazem informações dentro deste intervalo.
                Ex: Se deseja trazer informações do dia 29/05/2023, a data inicial deverá ser 28/05/2023 e data final 30/05/2023.
              </v-card-text>

              <v-form ref="form" v-model="valid" lazy-validation>
                <v-row>
                  <v-menu
                    ref="menu1"
                    v-model="dateStart"
                    :close-on-content-click="false"
                    :return-value.sync="date1"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        outlined
                        dense
                        v-model="date1"
                        prepend-icon="mdi-calendar-month"
                        label="Período Inicial"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="date1"
                      no-title
                      scrollable
                      @input="$refs.menu1.save(date1)"
                    >
                    </v-date-picker>
                  </v-menu>
                </v-row>

                <v-row>
                  <v-menu
                    ref="menu"
                    v-model="dateEnd"
                    :close-on-content-click="false"
                    :return-value.sync="date2"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        outlined
                        dense
                        class=""
                        v-model="date2"
                        prepend-icon="mdi-calendar-month"
                        label="Período Final"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="date2"
                      no-title
                      scrollable
                      @input="$refs.menu.save(date2)"
                    >
                    </v-date-picker>
                  </v-menu>
                </v-row>
              </v-form>
              <div class="mt-6 text-left">
                <v-btn @click="generateSaft" depressed color="success">
                  Gerar Ficheiro Saft
                </v-btn>
              </div>
            </v-card>
            <div
              style="
                border-bottom: 1px dashed #f6f6f6;
                padding: 10px;
                background-color: #ffffff;
              "
            ></div>
            <div
              style="
                border-bottom: 1px dashed #f6f6f6;
                padding: 10px;
                background-color: #ffffff;
              "
            ></div>
            <div style="padding-top: 100px"></div>
          </v-sheet>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapHelper } from "../../store/helper";

export default {
  name: "CardAgtSaft",
  data: () => ({
    menu1: false,
    // menu: false,
    dateStart: false,
    dateEnd: false,
    date1: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    date2: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    valid: true,
  }),
  methods: {
    generateSaft() {
      if (this.$refs.form.validate()) {
        let obj = {
          start: this.date1,
          end: this.date2,
        };
        //console.log(obj)
        const first = new Date(this.date1);
        const last = new Date(this.date2);
        if (first > last) {
          alert("Data final nao pode ser inferior a data inicial");
          return false;
        }
        this.$store.dispatch("getSaftT", obj);
      }
    },
  },

  computed: {
    ...mapHelper,
  },

  mounted() {
    this.entity = this.$store.getters.getGatewayKyami.entity;
    this.mark = this.$store.getters.getGatewayKyami.mark;
    this.bag = this.$store.getters.getGatewayKyami.bag;
    this.authorization = this.$store.getters.getGatewayKyami.auth;
  },
};
</script>

<style scoped></style>
