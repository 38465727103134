<template>
  <div class="mx-auto my-3 m-txt-center m-center background">
    <v-card elevation="0" class="transparent" min-height="200" max-width="100%">
      <v-row justify="center">
        <v-col cols="12">
          <v-row sm="4" align="center" justify="center">
            <v-card
              width="98%"
              style="border-radius: 0 !important"
              min-width="300"
              class="m-txt-center"
              elevation="0"
              min-height="70"
            >
              <card-view-title
                base-title="Empresa"
                cond-view="true"
                name-route=""
                subtitle="Adicionar Logotipo"
              ></card-view-title>
            </v-card>
          </v-row>

          <v-row sm="4" align="center" justify="center">
            <v-card
              width="98%"
              style="border-radius: 0 !important"
              min-width="300"
              class="m-txt-center"
              elevation="0"
              min-height="70"
            >
              <card-entity-logo></card-entity-logo>
            </v-card>
          </v-row>

          <v-row sm="4" class="mt-6" align="center" justify="center">
            <v-card
              width="98%"
              style="border-radius: 0 !important"
              min-width="300"
              class="m-txt-center transparent"
              elevation="0"
              min-height="70"
            >
            </v-card>

          </v-row>
        </v-col>
      </v-row>
    </v-card>
    <open-alert></open-alert>
    <spin></spin>
  </div>
</template>

<script>
import OpenAlert from "../../components/tools/Alert";
import Spin from "../../components/tools/Spin";
import CardViewTitle from "../../components/tools/CardViewTitle";
import CardEntityLogo from "../../components/entity/CardEntityLogo";
export default {
  name: "EntityLogo",
  components: {CardEntityLogo, CardViewTitle, Spin, OpenAlert },
};
</script>

<style scoped></style>
