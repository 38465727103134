<template>
  <div style="padding: 5px">
    <v-card elevation="0" style="background-color: #f6f6f6">
      <v-card-title class="m-size-font-16">
        Definições do Documento
      </v-card-title>
      <v-card-text>
        <card-panel-f-t v-if="getViewDocFt"></card-panel-f-t>
        <card-panel-f-r v-else-if="getViewDocFr"></card-panel-f-r>
        <card-panel-f-p v-else-if="getViewDocFp"></card-panel-f-p>
        <card-panel-g-d v-else-if="getViewDocGd"></card-panel-g-d>
        <card-panel-n-c v-else-if="getViewDocNc"></card-panel-n-c>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import CardPanelFT from "./docs/CardPanelFT";
import CardPanelFR from "./docs/CardPanelFR";
import { mapHelper } from "../../store/helper";
import CardPanelFP from "./docs/CardPanelFP";
import CardPanelGD from "./docs/CardPanelGD";
import CardPanelNC from "./docs/CardPanelNC";
export default {
  name: "CardDocDefinition",
  components: {
    CardPanelNC,
    CardPanelGD,
    CardPanelFP,
    CardPanelFR,
    CardPanelFT,
  },
  computed: {
    ...mapHelper,
  },
};
</script>

<style scoped></style>
