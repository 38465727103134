<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" max-width="800">
      <v-card class="text-center mx-auto" height="500">
        <v-card-title class="text-uppercase">
          <span class="m-top-5 text-center mx-auto m-txt-center m-center">{{
            this.plan
          }}</span>
        </v-card-title>
        <v-card-text class="text-h5 text-center mx-auto"> </v-card-text>
        <v-card-text class="text-center">
          <v-row>
            <v-col cols="12" class="mx-auto m-center m-txt-center">
              <v-row sm="4" align="center" justify="center" no-gutters>
                <v-card class="mx-auto" width="320" elevation="0">
                  <v-card-text class="black--text text-left">
                    Tempo de Subscrição
                  </v-card-text>
                  <v-card-text class="mt-n10">
                    <template>
                      <v-radio-group v-model="radios">
                        <v-simple-table>
                          <template v-slot:default>
                            <tbody>
                              <tr>
                                <td>
                                  <v-radio :value="1">
                                    <template v-slot:label>
                                      <div>
                                        <strong
                                          class="black--text m-size-font-12"
                                          >{{ month1 }}</strong
                                        >
                                      </div>
                                    </template>
                                  </v-radio>
                                </td>
                                <!--                              <td>{{ item.name }}</td>-->
                                <td class="text-right">{{ new Intl.NumberFormat("de-DE").format(price) }}</td>
                              </tr>
                              <tr>
                                <td>
                                  <v-radio :value="3">
                                    <template v-slot:label>
                                      <div>
                                        <strong
                                          class="black--text m-size-font-12"
                                          >{{ month3 }}</strong
                                        >
                                      </div>
                                    </template>
                                  </v-radio>
                                </td>
                                <!--                              <td>{{ item.name }}</td>-->
                                <td class="text-right">{{ new Intl.NumberFormat("de-DE").format(price3) }}</td>
                              </tr>
                              <tr>
                                <td>
                                  <v-radio :value="6">
                                    <template v-slot:label>
                                      <div>
                                        <strong
                                          class="black--text m-size-font-12"
                                          >{{ month6 }}</strong
                                        >
                                      </div>
                                    </template>
                                  </v-radio>
                                </td>
                                <!--                              <td>{{ item.name }}</td>-->
                                <td class="text-right">{{ new Intl.NumberFormat("de-DE").format(price6) }}</td>
                              </tr>
                              <tr>
                                <td>
                                  <v-radio :value="12">
                                    <template v-slot:label>
                                      <div>
                                        <strong
                                          class="black--text m-size-font-12"
                                          >{{ year }}</strong
                                        >
                                      </div>
                                    </template>
                                  </v-radio>
                                </td>
                                <!--                              <td>{{ item.name }}</td>-->
                                <td class="text-right">{{ new Intl.NumberFormat("de-DE").format(priceYear) }}</td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-radio-group>
                    </template>
                  </v-card-text>
                </v-card>

                <v-card
                  class="mx-auto"
                  width="320"
                  height="280"
                  elevation="0"
                  color="#f6f6f6"
                >
                  <v-card-text class="black--text text-left">
                    Método de Pagamento
                  </v-card-text>
                  <v-card-text class="mt-n10">
                    <template>
                      <v-radio-group v-model="radiosMCX">
                        <v-simple-table>
                          <template v-slot:default>
                            <tbody>
                              <tr>
                                <td>
                                  <v-radio value="MCX">
                                    <template v-slot:label>
                                      <div>
                                        <strong
                                          class="black--text m-size-font-12"
                                          >Pagamento por Referência</strong
                                        >
                                      </div>
                                    </template>
                                  </v-radio>
                                </td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-radio-group>
                    </template>
                  </v-card-text>
                </v-card>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text class="text-center mx-auto mt-6">
          <v-btn
            color="primary darken-1 text-center"
            class="mx-2"
            @click="subscribePlan(radios)"
          >
            Concluir
          </v-btn>
          <v-btn color="red darken-1" text @click="dialog = false">
            Fechar
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import eventBus from "../../main";

export default {
  name: "DialogSubscribePlan",
  props: {
    defineFunction: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    dialog: false,
    radiosMCX: "MCX",
    radios: 1,
    item: "",
    plan: "",
    price: 0,
    month1: "1 Mês",
    month3: "3 Meses",
    month6: "6 Meses",
    year: "1 Ano",
    price3: 0,
    price6: 0,
    priceYear: 0,
    prd: "",
  }),
  methods: {
    subscribePlan(cycle) {
      if (this.radios && this.plan) {
        const payload = {
          payment_method: "ref",
          subscribe: cycle,
          plan: this.plan,
          reference: this.plan,
          prd: this.prd,
        };
        this.$store.dispatch(this.defineFunction, payload);
      }
    },
  },
  created() {
    const self = this;
    eventBus.$on("openDialogSubscribePlan", function (event) {
      self.dialog = event.dialog;
      // self.item = event.item;
      self.plan = event.plan;
      self.price = event.price;
      self.price3 = parseFloat(event.price) * parseFloat(3);
      self.price6 = parseFloat(event.price) * parseFloat(6);
      self.priceYear = parseFloat(event.price) * parseFloat(12);
      self.prd = event.prd ? event.prd : false;
    });
  },
};
</script>

<style scoped></style>
