<template>
  <div style="padding: 0">
    <v-row no-gutters>
      <v-col class="">
        <v-card
          class="pa-0"
          style="background-color: #ffffff"
          tile
          elevation="0"
          height="60"
          width="100%"
        >
          <v-sheet color="transparent" min-height="50" elevation="0">
            <v-simple-table class="pa-0 pl-0">
              <template v-slot:default>
                <thead class="text-left">
                  <tr>
                    <th class="text-left font-weight-bold">Artigo</th>
                    <th class="text-right font-weight-bold">Desc.</th>
                    <th class="text-right font-weight-bold">P.Unit.</th>
                    <th class="text-right font-weight-bold">Qtd.</th>

                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="item in getSaveEditArticlesBought"
                    :key="item.ref"
                    style="cursor: pointer"
                  >
                    <td class="text-left">
                      {{ item.name }}
                    </td>
                    <td class="text-right">
                      {{ item.discount }}
                    </td>
                    <td class="text-right">
                      {{new Intl.NumberFormat("de-DE").format(item.price)}} Kz
                    </td>
                    <td class="text-right">
                      {{ item.qty }}
                    </td>

                    <!--                    <td class="text-right" @click="editItem(k, item)">-->
                    <!--                      <v-btn-->
                    <!--                        class="ma-2"-->
                    <!--                        outlined-->
                    <!--                        fab-->
                    <!--                        color="teal"-->
                    <!--                        x-small-->
                    <!--                        @click="editArticle(item.ref)"-->
                    <!--                      >-->
                    <!--                        <v-icon>mdi-pencil</v-icon>-->
                    <!--                      </v-btn>-->

                    <!--                      <v-btn-->
                    <!--                        class="ma-2"-->
                    <!--                        outlined-->
                    <!--                        fab-->
                    <!--                        color="red"-->
                    <!--                        x-small-->
                    <!--                        @click="deleteArticle(item.ref)"-->
                    <!--                      >-->
                    <!--                        <v-icon>mdi-delete-outline</v-icon>-->
                    <!--                      </v-btn>-->
                    <!--                    </td>-->
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <div
              style="
                border-bottom: 1px dashed #f6f6f6;
                padding: 10px;
                background-color: #ffffff;
              "
            ></div>
            <div
              style="
                border-bottom: 1px dashed #f6f6f6;
                padding: 10px;
                background-color: #ffffff;
              "
            ></div>
            <div style="padding-top: 100px"></div>
          </v-sheet>
        </v-card>
      </v-col>
    </v-row>
    <dialog-delete-article></dialog-delete-article>
  </div>
</template>
<script>
import { mapHelper } from "../../store/helper";
import eventBus from "../../main";
import DialogDeleteArticle from "../tools/DialogDeleteArticle";


export default {
  name: "CardViewOneDocument",
  components: { DialogDeleteArticle },
  methods: {
    viewDocument(item) {
      return this.$router.push({
        name: "documents.view",
        params: { id: item.ref },
      });
    },
    deleteArticle(id) {
      const value = {
        item: id,
        dialog: true,
      };
      eventBus.$emit("openDialogAdvice", value);
    },
  },
  computed: {
    ...mapHelper,
  },
  mounted() {
    this.$store.dispatch("callArticlesBought", this.$route.params.id);
  },
};
</script>

<style scoped></style>
