<template>
  <v-navigation-drawer permanent app floating width="300">
    <v-row class="fill-height" no-gutters style="background-color: #ffffff">
      <v-navigation-drawer dark mini-variant mini-variant-width="62" permanent>
        <v-list-item class="px-2">
          <v-list-item-avatar>
            <v-img :src="require('../../assets/svg_logo_icon.png')"></v-img>
          </v-list-item-avatar>
        </v-list-item>

        <v-divider></v-divider>

        <v-list dense>
          <v-list-item-group
            v-model="selectedItem"
            color="primary"
            active-class="white--text"
          >
            <v-list-item
              v-for="(item, key) in items"
              :key="item.title"
              @click="submitSubMenu(key)"
              v-show="item.view"
            >
              <v-list-item-action class="mx-1">
                <v-icon class="mx-0">{{ item.icon }}</v-icon>
                <v-list-item-title
                  class=""
                  style="
                    font-size: 10px;
                    font-weight: normal;
                    text-transform: uppercase;
                  "
                  >{{ item.title }}</v-list-item-title
                >
              </v-list-item-action>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>

      <v-list class="grow">
        <v-card class="transparent" elevation="0">
          <v-card-text
            class="blue-grey--text font-weight-bold mx-3 text-uppercase"
            >{{
              (
                getCommercialNameEntity.length > 20
                  ? getCommercialNameEntity.substr(0, 17) + "..."
                  : getCommercialNameEntity
              )
                ? getCommercialNameEntity.length > 20
                  ? getCommercialNameEntity.substr(0, 17) + "..."
                  : getCommercialNameEntity
                : getNameEntity.length > 20
                ? getNameEntity.substr(0, 17) + "..."
                : getNameEntity
            }}</v-card-text
          >
          <v-card-text class="mt-n8 blue-grey--text mx-3"
            >NIF: {{ getNifEntity }}</v-card-text
          >
        </v-card>
        <v-list-group
          v-show="positionFirstMenu && item.view"
          color="blue-grey"
          v-for="item in subItems"
          v-model="item.active"
          :key="item.title"
          :append-icon="item.exp ? $expand : ''"
        >
          <template v-slot:activator>
            <v-icon class="mx-2" size="18">{{ item.icon }}</v-icon
            ><v-list-item-title
              v-text="item.title"
              class="m-size-font-12 font-weight-bold"
              @click="submitSubItem(item.description, item.url)"
            ></v-list-item-title>
          </template>
          <v-list-item
            v-for="child in item.items"
            :key="child.title"
            @click="submitSubItem(child.description, child.url)"
            v-show="child.view"
          >
            <v-list-item-content class="mx-8">
              <v-list-item-title
                v-text="child.title"
                class="m-size-font-12 font-weight-bold"
              ></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <v-list-group
          v-show="positionSecondMenu"
          color="blue-grey"
          v-for="item in subItemsSecond"
          v-model="item.active"
          :key="item.title"
          :append-icon="item.exp ? $expand : ''"
        >
          <template v-slot:activator>
            <v-icon class="mx-2" size="18">{{ item.icon }}</v-icon
            ><v-list-item-title
              v-text="item.title"
              class="m-size-font-12 font-weight-bold"
              @click="submitSubItem(item.description, item.url)"
            ></v-list-item-title>
          </template>
          <v-list-item
            v-for="child in item.items"
            :key="child.title"
            @click="submitSubItem(child.description, child.url)"
          >
            <v-list-item-content class="mx-8">
              <v-list-item-title
                v-text="child.title"
                class="m-size-font-12 font-weight-bold"
              ></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </v-list>
    </v-row>
  </v-navigation-drawer>
  <!--  </v-card>-->
</template>

<script>
import { mapHelper } from "../../store/helper";
// import store from "./../../store";

export default {
  name: "Drawer",
  data: () => ({
    selectedItem: 0,
    positionFirstMenu: 1,
    positionSecondMenu: 0,
    items: [
      { title: "Geral", icon: "mdi-view-dashboard-outline", view: true },
      {
        title: "Ajustes",
        icon: "mdi-cog-outline",
        view: atob(localStorage.getItem("role_")) === "admin",
      },
    ],
    subItems: [
      {
        title: "Home",
        icon: "mdi-home",
        active: true,
        exp: false,
        url: "create.invoice",
        description: "Documentos",
        view:
          atob(localStorage.getItem("role_")) === "admin" ||
          atob(localStorage.getItem("role_")) === "operator" ||
          atob(localStorage.getItem("role_")) === "gestor",
      },
      // {
      //   title: "Dashboard",
      //   icon: "mdi-chart-bar-stacked",
      //   exp: false,
      //   description: "Dashboard",
      // },
      {
        title: "Produtos",
        icon: "mdi-view-list-outline",
        exp: true,
        view: true,
        items: [
          {
            title: "Artigos",
            url: "articles",
            description: "Artigos",
            view: true,
          },
          {
            title: "Criar Artigo",
            url: "articles.create",
            description: "Criar Artigo",
            view: atob(localStorage.getItem("role_")) === "admin" || atob(localStorage.getItem("role_")) === "gestor",
          },
        ],
      },
      {
        title: "Documentos",
        icon: "mdi-folder-text",
        exp: true,
        view: true,
        items: [
          {
            title: "Criar Documento",
            url: "create.invoice",
            description: "Novo Documento",
            view:
              atob(localStorage.getItem("role_")) === "admin" ||
              atob(localStorage.getItem("role_")) === "operator" ||
              atob(localStorage.getItem("role_")) === "gestor",
          },
          {
            title: "Todos",
            url: "list.documents",
            description: "Todos os Documentos",
            view:
              atob(localStorage.getItem("role_")) === "admin" ||
              atob(localStorage.getItem("role_")) === "operator" ||
              atob(localStorage.getItem("role_")) === "accounting" ||
              atob(localStorage.getItem("role_")) === "gestor",
          },
        ],
      },
      {
        title: "Clientes",
        icon: "mdi-account-multiple",
        exp: true,
        view: true,
        items: [
          {
            title: "Criar Cliente",
            url: "create.client",
            description: "Criar Cliente",
            view:
              atob(localStorage.getItem("role_")) === "admin" ||
              atob(localStorage.getItem("role_")) === "operator" ||
              atob(localStorage.getItem("role_")) === "gestor",
          },
          {
            title: "Todos",
            url: "list.client",
            description: "Clientes",
            view: true,
          },
        ],
      },
    ],

    subItemsSecond: [
      {
        title: "Empresa",
        icon: "mdi-office-building",
        active: false,
        description: "Dados da Empresa",
        exp: true,
        items: [
          {
            title: "Dados da Empresa",
            url: "entity.account",
            description: "Dados da Empresa",
          },
          {
            title: "Logo em Documentos",
            url: "entity.logo",
            description: "Logo em Documentos",
          },
          {
            title: "Utilizadores",
            url: "entity.users",
            description: "Utilizadores",
          },

          {
            title: "Dados Bancários",
            url: "entity.bank",
            description: "Dados Bancários",
          },
        ],
      },
      {
        title: "Subscrição",
        icon: "mdi-account-card",
        url: "subscription.overview",
        description: "Subscrição",
        active: false,
        exp: false,
      },

      {
        title: "Gateway Pagamentos",
        icon: "mdi-dip-switch",
        description: "Kyami Pay",
        active: false,
        exp: false,
        items: [
          {
            title: "Kyami Pay",
            url: "gateway.payment",
            description: "Gateway Kyami Pay",
          },
        ],
      },

      {
        title: "AGT",
        icon: "mdi-card-account-details",
        description: "Agt",
        active: false,
        exp: false,
        items: [
          {
            title: "Saft-t",
            url: "fiscal.agt",
            description: "Saft-t",
          },
        ],
      },
    ],
    mini: true,
  }),
  computed: {
    ...mapHelper,
  },
  methods: {
    submitSubItem(title, link) {
      if (link) {
        localStorage.setItem("navBarVitJs", title);
        this.$store.dispatch("setTitleGlobal", title);
        return this.$router.push({ name: link }).catch(() => {});
      }
    },
    submitSubMenu(key) {
      if (key) {
        this.positionFirstMenu = 0;
        return (this.positionSecondMenu = 1);
      }
      this.positionFirstMenu = 1;
      return (this.positionSecondMenu = 0);
    },
  },
};
</script>

<style scoped></style>
