import axios from "axios";
// import store from "../src/store";
import router from "../src/router";
const apiClient = axios.create({
  // baseURL: "http://localhost:8000",
  baseURL: "https://delmawave.ao/app/public",
  withCredentials: false,
  validateStatus: () => true,
  headers: {
    Accept: "Application/json",
  },
});

const clientFormData = axios.create({
  // baseURL: "http://localhost:8000",
  baseURL: "https://delmawave.ao/app/public",
  withCredentials: false,

  headers: {
    Accept: "Application/json",
    "Content-Type": "multipart/form-data",
  },
});

const apiClientPdf = axios.create({
  // baseURL: "http://localhost:8000",
  baseURL: "https://delmawave.ao/app/public",
  withCredentials: false,
  validateStatus: () => true,
  responseType: "arraybuffer",
  headers: {
    Accept: "Application/json",
    "Content-Type": "multipart/form-data",
  },
});

const apiClientXml = axios.create({
  // baseURL: "http://localhost:8000",
  baseURL: "https://delmawave.ao/app/public",
  withCredentials: false,
  validateStatus: () => true,
  responseType: "arraybuffer",
  headers: {
    Accept: "Application/json",
  },
});

export default {
  customizeHeader(token) {
    clientFormData.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    apiClientPdf.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    apiClientXml.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    return (apiClient.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${token}`);
  },
  interceptionRoute() {
    return apiClient.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response.status === 401) {
          router.push({ name: "login" }).catch(() => {
            //throw new Error("Problem - Contact DIGITAL INVOICE");
          });
          return;
        }
        return Promise.reject(error);
      }
    );
  },

  /**
   *
   * Global Statement
   *
   */

  async getLogin(cred) {
    return await apiClient.post("/api/v1/victoria/auth", cred);
  },

  async getGenericLogin(cred) {
    return await apiClient.post("/api/v1/victoria/login", cred);
  },

  async getResetLogin(cred) {
    return await apiClient.post("/api/v1/victoria/reset-password", cred);
  },
  async getChangeLogin(cred) {
    return await apiClient.post("/api/v1/victoria/change-password", cred);
  },

  async logout() {
    return await apiClient.post("/api/v1/victoria/logout");
  },

  async callGetRole() {
    return await apiClient.get("/api/v1/victoria/role");
  },

  async callCheckSubscription() {
    return await apiClient.get("/api/v1/entity/subscribe/check");
  },
  async getMe() {
    return await apiClient.get("/api/v1/entity/get/user");
  },

  /**
   * Invoice
   */

  async callCreateInvoice(value) {
    return await apiClient.post("/api/v1/entity/order/create", value);
  },

  async callDownloadDocs(value) {
    let v = value.format === undefined ? 1 : value.format;

    return await apiClientPdf.get(
      "/api/v1/entity/invoice/" + value.order + "/" + value.id + "/" + v
    );
  },

  async callCreateReceipt(value) {
    return await apiClient.post("/api/v1/entity/receipt/create", value);
  },

  async callConsultReceipt(value) {
    return await apiClient.get("/api/v1/entity/receipt/get/" + value.order);
  },
  ///entity/receipt/{order}/{invoice}/{format}
  async callDownloadReceipt(value) {
    let v = value.format === undefined ? 1 : value.format;
    return await apiClientPdf.get(
      "/api/v1/entity/receipt/" + value.order + "/" + value.invoice + "/" + v
    );
  },

  /**
   * Article
   */

  async callCreateNewArticle(value) {
    return await apiClient.post("/api/v1/entity/article/resource", value);
  },

  async getAllArticles() {
    return await apiClient.get("/api/v1/entity/article/resource");
  },

  async getAllArticlesNext(id) {
    return await apiClient.get("/api/v1/entity/article/resource?page=" + id);
  },
  async getAllArticlesBought(id) {
    return await apiClient.get("/api/v1/entity/get/items/" + id);
  },

  async deleteArticle(load) {
    return await apiClient.delete("/api/v1/entity/article/resource/" + load);
  },

  async cancelArticle(load) {
    return await apiClient.post(
      "/api/v1/entity/order/cancel/" + load.id + "/" + load.status
    );
  },

  async updateArticle(load) {
    return await apiClient.put(
      "/api/v1/entity/article/resource/" + load.id,
      load
    );
  },

  /**
   * Create Account
   */
  async createAccount(cred) {
    return await apiClient.post("/api/v1/entity/create", cred);
  },

  /**
   * Create new subscription
   */
  async createNewSubscribe(cred) {
    return await apiClient.post("/api/v1/entity/subscribe/create", cred);
  },

  /**
   * Renew  subscription
   */
  async renewSubscription(cred) {
    return await apiClient.post("/api/v1/entity/subscribe/update", cred);
  },

  /**
   * Get Subscription
   * @param cred
   * @returns {Promise<AxiosResponse<any>>}
   */
  async getSubscription() {
    return await apiClient.get("/api/v1/entity/subscribe/get");
  },

  /**
   *
   * Entity
   */

  async callEntity() {
    return await apiClient.get("/api/v1/entity/get");
  },

  async goEntityUsers() {
    return await apiClient.get("/api/v1/entity/users");
  },

  async goEntityUsersNext(id) {
    return await apiClient.get("/api/v1/entity/users?page=" + id);
  },
  async updateUser(load) {
    return await apiClient.post("/api/v1/entity/update/user", load);
  },
  async updatePwd(load) {
    return await apiClient.post("/api/v1/entity/update/pwd/user", load);
  },
  async deleteUser(load) {
    return await apiClient.delete("/api/v1/entity/delete/user/" + load);
  },

  async goAdduser(load) {
    return await apiClient.post("/api/v1/entity/create/user", load);
  },

  async goEditEntity(load) {
    return await apiClient.put("/api/v1/entity/update", load);
  },

  async goUpdateBankAccount(load) {
    return await apiClient.put("/api/v1/entity/bank/update", load);
  },

  async goGetBankAccount(load) {
    return await apiClient.get("/api/v1/entity/get/bank", load);
  },

  async addAvatarEntity(load) {
    return await apiClient.post("/api/v1/entity/add/logo", load);
  },


    async getAvatarEntity() {
        return await apiClient.get("/api/v1/entity/get/logo");
    },




  /**
   * Clients
   */

  async callAddClient(load) {
    return await apiClient.post("/api/v1/entity/client/resource", load);
  },

  async getAllClients() {
    return await apiClient.get("/api/v1/entity/client/resource");
  },

  async getAllClientsNext(id) {
    return await apiClient.get("/api/v1/entity/client/resource?page=" + id);
  },

  async deleteClient(load) {
    return await apiClient.delete("/api/v1/entity/client/resource/" + load);
  },

  async updateClient(load) {
    return await apiClient.put(
      "/api/v1/entity/client/resource/" + load.id,
      load
    );
  },

  async getAllOrder() {
    return await apiClient.get("/api/v1/entity/order/get");
  },

  async getAllOrderNext(load) {
    return await apiClient.get("/api/v1/entity/order/get?page=" + load);
  },

  async getOrder(load) {
    return await apiClient.get("/api/v1/entity/order/get/" + load.id, load);
  },

  ///entity

  /**
   * Gateway
   */

  async getPropsKyamiGateway() {
    return await apiClient.get("/api/v1/entity/gateway/kyami/get");
  },

  async goGateway(load) {
    return await apiClient.post(
      "/api/v1/entity/gateway/kyami/update/" + load.id,
      load
    );
  },
  //
  // async goGateway(load) {
  //     return await apiClient.post("/api/v1/entity/#", load);
  // },

  /**
   * AGT
   */

  async getSaftTAgt(load) {
    return await apiClientXml.post("/api/v1/entity/export/xml", load);
  },
};
