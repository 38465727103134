<template>
  <div class="mx-auto m-center m-txt-center my-12">
    <router-link to="/login"><img src="../../assets/svg_logo.png" alt="" style="width: 300px" /></router-link>
  </div>
</template>

<script>
export default {
  name: "LogoLogin",
};
</script>

<style scoped></style>
