import apiServices from "../../../../services/apiServices";
import eventBus from "../../../main";
import Vue from "vue";
// import router from "../../../router";

export default {
  state: {
    subscription: "",
  },
  mutations: {
    SET_SUBSCRIPTION(state, value) {
      state.subscription = value;
    },
  },
  actions: {
    /**
     * Subscribe new plan
     *
     */
    callCreateNewSubscribe(context, payload) {
      //if(payload) return alert("hi")
      eventBus.$emit("openSpin", true);
      apiServices
        .createNewSubscribe(payload)
        .then(function (response) {
          if (response.status === 201) {
            let obj = response.data;

            const event = {
              dialog: false,
              plan: null,
              price: 0,
            };

            let passReference = {
              dialog: true,
              entity: obj.entity,
              price: obj.price,
              reference: obj.reference,
              data: obj.exp,
            };
            eventBus.$emit("openDialogSubscribePlan", event);
            eventBus.$emit("openDialogConfirmPaymentReference", passReference);
            return;
            // router.push({ name: "login" }).catch(() => {
            //   //throw new Error("Problem - Contact Delmawave");
            // });
          }
          if (response.status === 422) {
            let message = response.data.message;
            return Vue.toasted.error(message, {
              theme: "bubble",
              position: "top-center",
              duration: 4000,
            });
          }

          if (response.status === 400) {
            let message = response.data.message;
            return Vue.toasted.error(message, {
              theme: "bubble",
              position: "top-center",
              duration: 4000,
            });
          }
          let payload = {
            color: "error",
            text: "Pedido não processado",
            errorLogin: true,
            right: "top",
          };
          eventBus.$emit("openAlert", payload); // emit the event to the bus
        })
        .catch(function () {
          let payload = {
            color: "error",
            text: "Seu pedido não foi processado",
            errorLogin: true,
            right: "right",
          };
          eventBus.$emit("openInfo", payload); // emit the event to the bus
        })
        .finally(function () {
          eventBus.$emit("openSpin", false);
        });
    },

    /**
     * Renew Subscription
     *
     */
    callRenewSubscribe(context, payload) {
      eventBus.$emit("openSpin", true);
      apiServices
        .renewSubscription(payload)
        .then(function (response) {
          if (response.status === 201) {
            let obj = response.data;
            const event = {
              dialog: false,
              plan: null,
              price: 0,
            };

            let passReference = {
              dialog: true,
              entity: obj.entity,
              price: obj.price,
              reference: obj.reference,
              data: obj.exp,
            };
            eventBus.$emit("openDialogSubscribePlan", event);
            eventBus.$emit("openDialogConfirmPaymentReference", passReference);
            return;
            // router.push({ name: "login" }).catch(() => {
            //   //throw new Error("Problem - Contact Delmawave");
            // });
          }
          if (response.status === 422) {
            let message = response.data.message;
            return Vue.toasted.error(message, {
              theme: "bubble",
              position: "top-center",
              duration: 4000,
            });
          }

          return Vue.toasted.error(response.data.message, {
            theme: "bubble",
            position: "top-center",
            duration: 4000,
          });
        })
        .catch(function () {
          return Vue.toasted.error("O seu pedido nao foi processado", {
            theme: "bubble",
            position: "top-center",
            duration: 4000,
          });
        })
        .finally(function () {
          eventBus.$emit("openSpin", false);
        });
    },

    getSubscription(context, payload) {
      // eventBus.$emit("openSpin", true);
      apiServices
        .getSubscription(payload)
        .then(function (response) {
          if (response.status === 200) {
            let obj = response.data;
            // let a = JSON.stringify(obj);
            context.commit("SET_SUBSCRIPTION", obj);
            // router.push({ name: "login" }).catch(() => {
            //   //throw new Error("Problem - Contact Delmawave");
            // });
            return;
          }
          if (response.status === 422) {
            return Vue.toasted.error(response.data.message, {
              theme: "bubble",
              position: "top-center",
              duration: 4000,
            });
          }

            if (response.status === 401 || response.status === 403) {
                return "";
            }
          return Vue.toasted.error(response.data.message, {
            theme: "bubble",
            position: "top-center",
            duration: 4000,
          });
        })
        .catch(function () {
          return Vue.toasted.error("O pedido nao foi processado", {
            theme: "bubble",
            position: "top-center",
            duration: 4000,
          });
        })
        .finally(function () {
          eventBus.$emit("openSpin", false);
        });
    },
  },
  getters: {
    getSubscriptionOnStore(state) {
      return state.subscription.victoria;
    },
  },
};
