import eventBus from "../../../main";
import apiServices from "../../../../services/apiServices";
import Vue from "vue";
import router from "../../../router";

export default {
  state: {
    modalUpdate: {
      titleArticle: "",
    },
    saveEditArticles: Object,
    saveEditArticlesBought: Object,
    articles: [],
  },
  mutations: {
    SET_STORE_ARTICLES(state, value) {
      state.articles = value;
    },

    SET_SAVE_EDIT_ARTICLE(state, value) {
      state.saveEditArticles = value;
    },

    SET_STORE_ARTICLES_BOUGHT(state, value) {
      state.saveEditArticlesBought = value;
    },
    SET_MODAL_UPDATE_ARTICLE(state, value) {
      state.modalUpdate.titleArticle = value;
    },

    SET_REMOVE_ARTICLE(state, value) {
      state.articles.victoria.splice(value, 1);
    },
  },
  actions: {
    setArticles(context, object) {
      context.commit("SET_STORE_ARTICLES", object);
    },
    setModalUpdateArticle(context, object) {
      context.commit("SET_MODAL_UPDATE_ARTICLE", object);
    },

    saveEditArticle(context, payload) {
      return context.commit("SET_SAVE_EDIT_ARTICLE", payload);
    },

    callArticles(context, payload) {
      apiServices
        .getAllArticles(payload)
        .then(function (response) {
          if (response.status === 200) {
            let obj = response.data;
            return context.commit("SET_STORE_ARTICLES", obj);
          }
          if (response.status === 422) {
            let message = response.data.message;
            return Vue.toasted.error(message, {
              theme: "bubble",
              position: "top-center",
              duration: 4000,
            });
          }

          if (response.status === 401 || response.status === 403) {
            return "";
          }
          return Vue.toasted.error("Pedido não processado", {
            theme: "bubble",
            position: "top-center",
            duration: 4000,
          });
        })
        .catch(function () {
          return Vue.toasted.error("Pedido [Artigo] não processado", {
            theme: "bubble",
            position: "top-center",
            duration: 4000,
          });
        })
        .finally(function () {
          eventBus.$emit("openSpin", false);
        });
    },

    callArticlesNext(context, payload) {
      eventBus.$emit("openSpin", true);
      apiServices
        .getAllArticlesNext(payload)
        .then(function (response) {
          if (response.status === 200) {
            let obj = response.data;
            return context.commit("SET_STORE_ARTICLES", obj);
          }
          if (response.status === 422) {
            let message = response.data.message;
            return Vue.toasted.error(message, {
              theme: "bubble",
              position: "top-center",
              duration: 4000,
            });
          }
          if (response.status === 401 || response.status === 403) {
            return "";
          }
          return Vue.toasted.error("Pedido não processado", {
            theme: "bubble",
            position: "top-center",
            duration: 4000,
          });
        })
        .catch(function () {
          return Vue.toasted.error("Pedido não processado", {
            theme: "bubble",
            position: "top-center",
            duration: 4000,
          });
        })
        .finally(function () {
          eventBus.$emit("openSpin", false);
        });
    },

    createNewArticle(context, payload) {
      eventBus.$emit("openSpin", true);
      apiServices
        .callCreateNewArticle(payload)
        .then(function (response) {
          if (response.status === 201) {
            Vue.toasted.success("Artigo adicionado com sucesso!", {
              theme: "bubble",
              position: "top-center",
              duration: 4000,
            });
            router.push({ name: "articles" });
            return;
          }
          if (response.status === 422) {
            let message = response.data.message;
            return Vue.toasted.error(message, {
              theme: "bubble",
              position: "top-center",
              duration: 4000,
            });
          }
          if (response.status === 401 || response.status === 403) {
            return "";
          }
        })
        .catch(function () {
          Vue.toasted.error("Pedido não processado", {
            theme: "bubble",
            position: "top-center",
            duration: 4000,
          });
        })
        .finally(function () {
          eventBus.$emit("openSpin", false);
        });
    },

    callCancelDocument(context, payload) {
      eventBus.$emit("openSpin", true);
      apiServices
        .cancelArticle(payload)
        .then(function (response) {
          if (response.status === 200) {
            return Vue.toasted.success(response.data.message, {
              theme: "bubble",
              position: "top-center",
              duration: 4000,
            });
          }
          if (response.status === 422) {
            let message = response.data.message;
            return Vue.toasted.error(message, {
              theme: "bubble",
              position: "top-center",
              duration: 4000,
            });
          }
          if (response.status === 401 || response.status === 403) {
            return "";
          }
          return Vue.toasted.error("Pedido não processado", {
            theme: "bubble",
            position: "top-center",
            duration: 4000,
          });
        })
        .catch(function () {
          Vue.toasted.error("Pedido não processado", {
            theme: "bubble",
            position: "top-center",
            duration: 4000,
          });
        })
        .finally(function () {
          eventBus.$emit("openSpin", false);
        });
    },

    callDeleteArticle(context, payload) {
      eventBus.$emit("openSpin", true);
      apiServices
        .deleteArticle(payload.id)
        .then(function (response) {
          if (response.status === 200) {
            context.commit("SET_REMOVE_ARTICLE", payload.index);
            return Vue.toasted.success("Artigo removido com sucesso!", {
              theme: "bubble",
              position: "top-center",
              duration: 4000,
            });
          }
          if (response.status === 422) {
            let message = response.data.message;
            return Vue.toasted.error(message, {
              theme: "bubble",
              position: "top-center",
              duration: 4000,
            });
          }
          if (response.status === 401 || response.status === 403) {
            return "";
          }
          return Vue.toasted.error("Pedido não processado", {
            theme: "bubble",
            position: "top-center",
            duration: 4000,
          });
        })
        .catch(function () {
          Vue.toasted.error("Pedido não processado", {
            theme: "bubble",
            position: "top-center",
            duration: 4000,
          });
        })
        .finally(function () {
          eventBus.$emit("openSpin", false);
        });
    },

    callUpdateArticle(context, payload) {
      eventBus.$emit("openSpin", true);
      apiServices
        .updateArticle(payload)
        .then(function (response) {
          if (response.status === 200) {
            Vue.toasted.success("Artigo actualizado com sucesso!", {
              theme: "bubble",
              position: "top-center",
              duration: 4000,
            });

            router.push({ name: "articles" });
            return;
          }
          if (response.status === 422) {
            let message = response.data.message;
            return Vue.toasted.error(message, {
              theme: "bubble",
              position: "top-center",
              duration: 4000,
            });
          }
          if (response.status === 401 || response.status === 403) {
            return "";
          }
          return Vue.toasted.error("Pedido não processado", {
            theme: "bubble",
            position: "top-center",
            duration: 4000,
          });
        })
        .catch(function () {
          return Vue.toasted.error("Pedido não processado", {
            theme: "bubble",
            position: "top-center",
            duration: 4000,
          });
        })
        .finally(function () {
          eventBus.$emit("openSpin", false);
        });
    },

    callArticlesBought(context, payload) {
      eventBus.$emit("openSpin", true);
      apiServices
        .getAllArticlesBought(payload)
        .then(function (response) {
          if (response.status === 200) {
            let obj = response.data;
            return context.commit("SET_STORE_ARTICLES_BOUGHT", obj.victoria);
          }
          if (response.status === 422) {
            let message = response.data.message;
            return Vue.toasted.error(message, {
              theme: "bubble",
              position: "top-center",
              duration: 4000,
            });
          }
          if (response.status === 401 || response.status === 403) {
            return "";
          }
          return Vue.toasted.error("Pedido não processado", {
            theme: "bubble",
            position: "top-center",
            duration: 4000,
          });
        })
        .catch(function () {
          return Vue.toasted.error("Pedido não processado", {
            theme: "bubble",
            position: "top-center",
            duration: 4000,
          });
        })
        .finally(function () {
          eventBus.$emit("openSpin", false);
        });
    },
  },
  getters: {
    getArticles(state) {
      return state.articles;
    },
    getModalUpdateArticle(state) {
      return state.modalUpdate.titleArticle;
    },

    getSaveEditArticles(state) {
      return state.saveEditArticles;
    },
    getSaveEditArticlesBought(state) {
      return state.saveEditArticlesBought;
    },
  },
};
