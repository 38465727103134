<template>
  <div class="mx-auto my-3 m-txt-center m-center background">
    <v-card elevation="0" class="transparent" min-height="200" max-width="100%">
      <v-row justify="center">
        <v-col cols="12">
          <v-row sm="4" align="center" justify="center">
            <v-card
              width="98%"
              style="border-radius: 0 !important"
              min-width="300"
              class="m-txt-center"
              elevation="0"
              min-height="70"
            >
              <card-view-create-article></card-view-create-article>
            </v-card>
          </v-row>

          <v-row sm="4" align="center" justify="center">
            <v-card
              width="98%"
              style="border-radius: 0 !important"
              min-width="300"
              class="m-txt-center"
              elevation="0"
              min-height="70"
            >
              <card-create-article></card-create-article>
            </v-card>
          </v-row>

          <v-row sm="4" class="mt-6" align="center" justify="center">
            <v-card
              width="98%"
              style="border-radius: 0 !important"
              min-width="300"
              class="m-txt-center transparent"
              elevation="0"
              min-height="70"
            >
            </v-card>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
    <open-alert></open-alert>
    <spin></spin>
  </div>
</template>

<script>
import CardCreateArticle from "../../components/articles/CardCreateArticle";
import OpenAlert from "../../components/tools/Alert";
import Spin from "../../components/tools/Spin";
import CardViewCreateArticle from "../../components/articles/CardViewCreateArticle";
export default {
  name: "PageCreateArticle",
  components: { CardViewCreateArticle, Spin, OpenAlert, CardCreateArticle },
};
</script>

<style scoped></style>
