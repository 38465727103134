<template>
  <v-snackbar
    height="50"
    :color="color"
    v-model="errorLogin"
    :timeout="timeout"
    :top="y === top"
    :right="x === right"
  >
    <div class="mx-auto m-txt-center">
      {{ text }}

      <v-btn
        class="text-right"
        color="white"
        text
        icon
        @click="errorLogin = false"
      >
        <v-icon>mdi-close-circle</v-icon>
      </v-btn>
    </div>
  </v-snackbar>
</template>

<script>
import eventBus from "../../main";
export default {
  name: "OpenAlert",
  data: () => ({
    color: "",
    text: "",
    right: "",
    top: "",
    errorLogin: false,
    timeout: 6000,
    y: "top",
    x: "right",
  }),
  created() {
    const self = this;
    eventBus.$on("openAlert", function (payload) {
      self.errorLogin = payload.errorLogin;
      self.color = payload.color;
      self.text = payload.text;
      self.right = payload.right;
      if (payload.top) self.top = payload.top;
    });
  },
};
</script>

<style scoped></style>
