<template>
  <div>
    <div class="mx-auto bg m-txt-center m-center">
      <v-card
        class="mx-auto m-txt-center transparent mt-10"
        width="700"
        elevation="0"
      >
        <div class="sans title-size mx-auto">
          <strong>Seleccione um plano ideal para si</strong>
        </div>
        <v-card-title class="description mx-auto m-center m-txt-center">
        </v-card-title>
      </v-card>
    </div>

    <div class="mx-auto bg m-txt-center m-center my-8">
      <package style="box-shadow: 0 0 0 0 !important"></package>
      <comparison-package></comparison-package>
    </div>
    <dialog-subscribe-plan
      defineFunction="callCreateNewSubscribe"
    ></dialog-subscribe-plan>
    <dialog-confirm-pay-reference></dialog-confirm-pay-reference>
  </div>
</template>

<script>
import Package from "../subscription/cards/Package";
import ComparisonPackage from "../subscription/cards/ComparisonPackage";
import DialogSubscribePlan from "../tools/DialogSubscribePlan";
import DialogConfirmPayReference from "../tools/DialogConfirmPayReference";
export default {
  name: "Wellcome",
  components: {
    DialogConfirmPayReference,
    DialogSubscribePlan,
    ComparisonPackage,
    Package,
  },
  data: () => ({}),
};
</script>

<style scoped>
.bg {
  /*background-color: white;*/
  /*margin: 12px;*/
}
.sans {
  /*font-family: "Source Sans Pro", sans-serif;*/
  font-family: "Fira Sans Condensed", sans-serif;
  /*color: #3e3e42;*/
  color: #32363b;
  line-height: 1.2;
}
.description {
  font-family: "Source Sans Pro", sans-serif;
  color: #616266;
  line-height: 1;
  font-size: 16px;
}
.spoiler-description {
  font-family: "Source Sans Pro", sans-serif;
  color: #ffffff;
  /*line-height: 1;*/
  font-size: 18px;
}
.title-size {
  font-size: 38px;
}

:root {
  --text-color: #616266;
  --header: #32363b;
  --background: #32363b;
}
</style>
