<template>
  <div style="padding: 0">
    <v-row no-gutters>
      <v-col class="">
        <v-card
          class="pa-0"
          style="background-color: #ffffff"
          tile
          elevation="0"
          width="100%"
        >
          <v-sheet color="transparent" min-height="50" elevation="0">
            <v-col cols="12" class="mx-auto m-center m-txt-center">
              <v-row sm="4" align="center" justify="center" no-gutters>
                <v-card width="300" elevation="0">
                  <v-card-text>
                    <div
                      class="text-left font-weight-bold"
                      style="border-bottom: 1px solid #22202017; width: 200px"
                    >
                      <v-icon size="16">mdi-list-box-outline</v-icon>
                      Plano Subscrito:
                    </div>
                    <div
                      class="text-left"
                      style="border-bottom: 1px solid #22202017; width: 200px"
                    >
                      {{
                        getSubscriptionOnStore.plan
                          ? getSubscriptionOnStore.plan
                          : "Inactivo"
                      }}
                    </div>
                  </v-card-text>

                  <v-card-text>
                    <div
                      class="text-left font-weight-bold"
                      style="border-bottom: 1px solid #22202017; width: 200px"
                    >
                      <v-icon size="16">mdi-cash</v-icon>
                      Preço:
                    </div>
                    <div
                      class="text-left"
                      style="border-bottom: 1px solid #22202017; width: 200px"
                    >
                      {{
                        getSubscriptionOnStore.price
                          ? getSubscriptionOnStore.price
                          : "0"
                      }}
                      Akz
                    </div>
                  </v-card-text>
                </v-card>

                <v-card width="300" elevation="0">
                  <v-card-text>
                    <div
                      class="text-left font-weight-bold"
                      style="border-bottom: 1px solid #22202017; width: 200px"
                    >
                      <v-icon size="16">mdi-clock-time-eight-outline</v-icon>
                      Data de Subscrição:
                    </div>
                    <div
                      class="text-left"
                      style="border-bottom: 1px solid #22202017; width: 200px"
                    >
                      {{
                        getSubscriptionOnStore.dataCreate
                          ? getSubscriptionOnStore.dataCreate
                          : "Inactivo"
                      }}
                    </div>
                  </v-card-text>

                  <v-card-text>
                    <div
                      class="text-left font-weight-bold"
                      style="border-bottom: 1px solid #22202017; width: 200px"
                    >
                      <v-icon size="16">mdi-clock-time-eight-outline</v-icon>
                      Expira em:
                    </div>
                    <div
                      class="text-left"
                      style="border-bottom: 1px solid #22202017; width: 200px"
                    >
                      {{
                        getSubscriptionOnStore.dataEnd
                          ? getSubscriptionOnStore.dataEnd
                          : "Inactivo"
                      }}
                    </div>
                  </v-card-text>
                </v-card>

                <v-card width="300" elevation="0">
                  <v-card-text>
                    <div
                      class="text-left font-weight-bold"
                      style="border-bottom: 1px solid #22202017; width: 200px"
                    >
                      <v-icon size="16">mdi-emoticon-happy-outline</v-icon>
                      Status:
                    </div>
                    <div
                      class="text-left"
                      style="border-bottom: 1px solid #22202017; width: 200px"
                    >
                      {{
                        getSubscriptionOnStore.price
                          ? "Activo"
                          : "Sem subscrição"
                      }}
                    </div>
                  </v-card-text>
                </v-card>
              </v-row>
            </v-col>

            <div
              style="
                border-bottom: 1px dashed #f6f6f6;
                padding: 10px;
                background-color: #ffffff;
              "
            >
              <v-btn
                v-if="getSubscriptionOnStore.price"
                color="success"
                @click="
                  renewSubscription(
                    getSubscriptionOnStore.plan
                      ? getSubscriptionOnStore.plan
                      : 'null',
                    getSubscriptionOnStore.price
                      ? getSubscriptionOnStore.price
                      : 'null',
                    getSubscriptionOnStore.ref
                      ? getSubscriptionOnStore.ref
                      : 'null'
                  )
                "
              >
                <v-icon size="16">mdi-autorenew</v-icon>Renovar</v-btn
              >

              <v-btn color="success" @click="subscribe" class="mx-2">
                <v-icon size="16" class="mx-2">mdi-account-card</v-icon>Subscrever</v-btn
              >
            </div>
            <div
              style="
                border-bottom: 1px dashed #f6f6f6;
                padding: 10px;
                background-color: #ffffff;
              "
            ></div>
          </v-sheet>
        </v-card>
      </v-col>
    </v-row>
    <dialog-subscribe-plan
      define-function="callRenewSubscribe"
    ></dialog-subscribe-plan>
    <dialog-confirm-pay-reference></dialog-confirm-pay-reference>
  </div>
</template>

<script>
import { mapHelper } from "../../store/helper";
import eventBus from "../../main";
import DialogSubscribePlan from "../tools/DialogSubscribePlan";
import DialogConfirmPayReference from "../tools/DialogConfirmPayReference";
import Vue from "vue";
import router from "../../router";

export default {
  name: "SectionSubscription",
  components: { DialogConfirmPayReference, DialogSubscribePlan },
  computed: {
    ...mapHelper,
  },
  methods: {
    renewSubscription(plan, price, ref) {
      if (plan === "null") {
        return Vue.toasted.error("Subscreva um plano", {
          theme: "bubble",
          position: "top-center",
          duration: 4000,
        });
      }
      console.log(plan);
      const payload = {
        // url: url,
        price: price,
        plan: plan,
        dialog: true,
        prd: ref,
      };
      eventBus.$emit("openDialogSubscribePlan", payload);
    },

    subscribe() {
      router.push({ name: "victoria" }).catch(() => {
        //throw new Error("Problem - Contact DIGITAL INVOICE");
      });
    },
  },
};
</script>

<style scoped></style>
