<template>
  <div>
    <v-row no-gutters>
      <v-col class="">
        <v-card
          class="pa-0"
          style="background-color: #ffffff"
          tile
          elevation="0"
          min-height="800"
          width="100%"
        >
          <v-card-text>
            <p class="font-weight-bold">
              Termos de Uso e Política de Privacidade do Victória Invoice
            </p>

            <p>Última Actualização: 12 de setembro de 2023</p>

            <p>
              Bem-vindo ao Victória Invoice, um software de faturação online
              disponibilizado pela Digital Invoice. Leia atentamente estes
              Termos de Uso e Política de Privacidade antes de utilizar o
              Victória Invoice. Ao utilizar o software Victória Invoice, você
              concorda em cumprir os termos e políticas aqui descritos. Se você
              não concordar com estes termos, não utilize o software Victória
              Invoice.
            </p>

            <p class="font-weight-bold">1. Proprietário do Software</p>

            O software Victória Invoice é de propriedade da Digital Invoice.<br><br>

            <p class="font-weight-bold">2. Actualizações e Modificações</p>

            2.1. A Digital Invoice se reserva o direito de actualizar ou
            modificar estes Termos de Uso e Política de Privacidade a qualquer
            momento, sem aviso prévio.

            2.2. As actualizações entrarão em vigor
            imediatamente após a publicação na fonte oficial do Victória
            Invoice, que pode ser acessada em victoria.ao.<br><br>

            <p class="font-weight-bold">3. Senhas e Segurança</p>
            3.1. Você é responsável por manter a confidencialidade de sua senha
            de acesso ao Victória Invoice.
            3.2. A segurança da sua senha é de
            sua responsabilidade e a Digital Invoice não se responsabiliza por
            quaisquer acções tomadas com base no uso indevido de sua senha.<br><br>

           <p class="font-weight-bold">4. Downgrade e Upgrade </p>

            4.1. O processo de downgrade (escolha de um
            plano abaixo) levará à perda dos privilégios do plano superior e não
            acumulará os dias não utilizados.
            4.2. O processo de upgrade
            (escolha de um plano superior) não acumulará os dias não utilizados
            do plano anterior.<br><br>

            <p class="font-weight-bold">5. Recolha de Dados 5.1. Os dados colectados</p>

            durante o processo de criação de conta no Victória Invoice serão
            utilizados exclusivamente para a operação do software, fins fiscais
            e comunicação entre a Digital Invoice e o Cliente.<br><br>

            <p class="font-weight-bold">6. Uso Legal </p>

              6.1. O Victória Invoice não deve ser utilizado para fins ilegais ou não
            éticos. O Cliente concorda em utilizar o software de acordo com
            todas as leis e regulamentações aplicáveis.<br><br>

            <p class="font-weight-bold">7. Reembolsos </p>

            7.1. A
            Digital Invoice não oferece reembolsos pelos serviços prestados
            através do Victória Invoice.<br><br>

            <p class="font-weight-bold"> 8. Exclusão de Conta </p>


            8.1. O Cliente tem
            o direito de solicitar a exclusão de sua conta após o encerramento
            do ano fiscal correspondente. A Digital Invoice realizará a exclusão
            dos dados de acordo com as regulamentações de proteção de dados
            aplicáveis.<br><br>

            <p class="font-weight-bold">9. Contacto </p>

            9.1. Para entrar em contacto com a Digital
            Invoice em relação a estes Termos de Uso e Política de Privacidade,
            você pode utilizar as informações de contacto fornecidas no Victória
            Invoice ou enviar um e-mail para contacto@victoria.ao. Ao utilizar o
            Victória Invoice, você concorda em cumprir todos os termos e
            políticas acima mencionados. A Digital Invoice reserva-se o direito
            de tomar medidas apropriadas em caso de violação destes termos.<br><br>


            <p>Obrigado por escolher o Victória Invoice!</p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "TermsComponent",
  data: () => ({}),
};
</script>
<style scoped>
.theme--light.v-input input,
.theme--light.v-input textarea {
  color: #ffffff !important;
}

.theme--light.v-text-field >>> .v-input__control > .v-input__slot:before {
  border-color: #ffffff4f !important;
}

.v-text-field .v-label {
  font-size: 13px !important;
}

.theme--light.v-input >>> input {
  color: #ffffff !important;
}

.theme--light.v-input >>> textarea {
  color: #ffffff !important;
}

.theme--light.v-select >>> .v-select__selections {
  color: #ffffff !important;
}

/**
Background input
 */
.theme--light.v-text-field--filled >>> .v-input__control > .v-input__slot {
  background: #81873114 !important;
}
</style>
