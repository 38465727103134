<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" max-width="290">
      <v-card class="text-center mx-auto">
        <v-card-text class="text-h5 text-center mx-auto">
          <v-icon size="48" class="mt-4" color="warning">mdi-information-outline</v-icon>
        </v-card-text>
        <v-card-text class="text-center">Pretende remover este artigo?</v-card-text>
        <v-card-text class="text-center mx-auto">
          <v-btn color="red darken-1 text-center" text @click="removeItem">
            Sim
          </v-btn>
          <v-btn color="primary darken-1" text @click="dialog = false">
            Não
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import eventBus from "../../main";

export default {
  name: "DialogAdvice",
  data: () => ({
    dialog: false,
    item: "",
  }),
  methods: {
    removeItem() {
      this.$store.dispatch("removeItemOnStore", this.item);
      this.dialog = false;
    },
  },
  created() {
    const self = this;
    eventBus.$on("openDialogAdvice", function (event) {
      self.dialog = event.dialog;
      self.item = event.item;
    });
  },
};
</script>

<style scoped></style>
