<template>
  <div class="mx-auto my-3 m-txt-center m-center background">
    <v-card elevation="0" class="transparent" min-height="200" max-width="100%">
      <v-row justify="center">
        <v-col cols="12">
          <v-row sm="4" align="center" justify="center">
            <v-card
              width="98%"
              style="border-radius: 0 !important"
              min-width="300"
              class="m-txt-center"
              elevation="0"
              min-height="70"
            >
              <div style="padding: 5px">
                <v-row no-gutters>
                  <v-col class="text-left" cols="7">
                    <v-card
                      class="pa-2"
                      style="background-color: #f6f6f6"
                      tile
                      elevation="0"
                      height="60"
                    >
                      <v-sheet color="transparent" height="50" elevation="0">
                        <v-card-title class="m-size-font-14 mt-n5"
                          >Empresa</v-card-title
                        >
                        <v-card-text class="mt-n6"
                          >Todos utilizadores</v-card-text
                        >
                      </v-sheet>
                    </v-card>
                  </v-col>

                  <v-col class="text-right">
                    <v-card
                      style="background-color: #f6f6f6"
                      tile
                      elevation="0"
                      height="60"
                    >
                      <v-sheet
                        color="transparent"
                        height="50"
                        elevation="0"
                        class=""
                      >
                        <v-btn class="ma-3" text color="teal" @click="addUser">
                          <v-icon class="mx-1">mdi-plus</v-icon
                          ><span style="text-transform: capitalize"
                            >Adicionar utilizador</span
                          >
                        </v-btn>
                      </v-sheet>
                    </v-card>
                  </v-col>
                </v-row>
              </div>
            </v-card>
          </v-row>

          <v-row sm="4" align="center" justify="center">
            <v-card
              width="98%"
              style="border-radius: 0 !important"
              min-width="300"
              class="m-txt-center"
              elevation="0"
              min-height="70"
            >
              <div v-show="!getAllUsers.victoria" style="margin-top: 60px">
                <div><v-icon size="38">mdi-information-outline</v-icon></div>
                <v-card-text class="m-size-font-12">Sem lista de utilizadores</v-card-text>
              </div>
              <card-entity-users></card-entity-users>
            </v-card>
          </v-row>

          <v-row sm="4" class="mt-6" align="center" justify="center">
            <v-card
              width="98%"
              style="border-radius: 0 !important"
              min-width="300"
              class="m-txt-center transparent"
              elevation="0"
              min-height="70"
            >
            </v-card>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
    <open-alert></open-alert>
    <spin></spin>
  </div>
</template>

<script>
import OpenAlert from "../../components/tools/Alert";
import Spin from "../../components/tools/Spin";

import CardEntityUsers from "../../components/entity/CardEntityUsers";
import {mapHelper} from "../../store/helper";
export default {
  name: "EntityUsers",
  components: { CardEntityUsers, Spin, OpenAlert },
  methods: {
    addUser() {
      return this.$router.push({ name: "user.add" });
    },
  },
  computed: {
    ...mapHelper,
  },
};
</script>

<style scoped></style>
