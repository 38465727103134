<template>
  <div>
    <v-app-bar app color="grey darken-4" dark>
      <div class="mx-auto m-center m-txt-center mt-2">
        <img src="../../assets/svg_logo.png" alt="" style="width: 150px" />
      </div>
      <v-spacer></v-spacer>
      <v-btn
        color="white"
        text
        class="mx-2 text-capitalize"
        @click="$vuetify.goTo(target, options)"
        >Preços</v-btn
      >
      <v-btn
        color="white"
        text
        class="mx-2 text-capitalize"
        @click="$vuetify.goTo(targetContact, options)"
        >Contactos</v-btn
      >
      <v-btn outlined color="white darken-4" dark @click="login">Entrar</v-btn>
    </v-app-bar>
  </div>
</template>
<!--<v-app-bar app color="grey darken-4" dark>-->
<script>
export default {
  name: "BarComponent",
  data: () => ({
    selector: "#price",
    contact: "#contact",
    duration: 700,
    offset: 0,
    easing: "easeInOutCubic",
  }),
  computed: {
    target() {
      return this.selector;
    },
    targetContact() {
      return this.contact;
    },
    options() {
      return {
        duration: this.duration,
        offset: this.offset,
        easing: this.easing,
      };
    },
  },

  methods: {
    login() {
      return this.$router.push({ name: "login" });
    },
  },
};
</script>

<style scoped></style>
