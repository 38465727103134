<template>
  <div style="padding: 5px">
    <v-row no-gutters>
      <v-col class="text-left" cols="7">
        <v-card
          class="pa-2"
          style="background-color: #f6f6f6"
          tile
          elevation="0"
          height="60"
        >
          <v-sheet color="transparent" height="50" elevation="0">
            <v-card-title class="m-size-font-14 mt-n5">Lista</v-card-title>
            <v-card-text class="mt-n6">Documentos</v-card-text>
          </v-sheet>
        </v-card>
      </v-col>

      <v-col class="text-right">
        <v-card
          style="background-color: #f6f6f6"
          tile
          elevation="0"
          height="60"
        >
          <v-sheet color="transparent" height="50" elevation="0" class="">
            <v-btn class="ma-3" text color="teal" @click="createDocument">
              <v-icon class="mx-1">mdi-plus</v-icon
              ><span style="text-transform: capitalize">Criar documento</span>
            </v-btn>
          </v-sheet>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "CardDocuments",
  methods: {
    createDocument() {
      return this.$router.push({ name: "create.invoice" });
    },
  },
};
</script>

<style scoped></style>
