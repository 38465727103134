<template>
  <v-card class="mx-auto" max-width="400" elevation="1" style="margin-bottom: 10px">
    <v-card-title class="mx-auto m-txt-center">
      <span
        class="text-h4 font-weight-bold text-header mx-auto m-txt-center color-text-header" style="font-family: 'Fira Sans Condensed', sans-serif!important;"
        >{{ this.planName }}</span
      >
    </v-card-title>

    <v-card-text class="m-size-font-14 font-weight-bold">
      {{ this.description }}
    </v-card-text>

    <v-card-actions>
      <v-card-text class="text-h3 font-weight-bold color-text">
        <v-badge bordered color="success" overlap content="AKZ" left>
          {{ this.price }}<span style="font-size: 20px">/ mês</span>
        </v-badge>
      </v-card-text>
    </v-card-actions>
    <v-card-text class="m-size-font-14 font-weight-bold">
      Subscrição anual :
      <span style="color: #1e88e5">{{ this.priceYear }} Akz</span>
    </v-card-text>

    <v-card class="m-size-font-14 font-weight-bold mt-4" elevation="0">
      <v-btn @click="subscribe(url)" large outlined color="primary" dark
        >{{ this.btn }}
      </v-btn>
    </v-card>
    <div style="height: 50px"></div>
  </v-card>
</template>

<script>
export default {
  props: {
    price: {
      type: String,
      required: true,
    },
    priceYear: {
      type: String,
    },
    planName: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
    btn: {
      type: String,
      required: true,
    },
  },
  name: "PackageComponent",
  methods: {
    subscribe(url) {
      return this.$router.push({ name: url });
    },
  },
};
</script>

<style scoped>
.text-header {
  font-family: "Fira Sans Condensed", sans-serif;
  line-height: 42px;
  font-size: 38px;
  font-weight: 500;
}
.color-text {
  color: #ae7402;
}
.color-text-header {
  color: #1e88e5;
}
.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: 4px 3px 24px -11px rgba(0, 25, 29, 17.2), 1px 1px -1px 22px rgba(0, 0, 0, 0.14), 46px 28px 30px 0px rgba(0, 13, 0, 0.12);
}
</style>
