<template>
  <div style="padding: 0; margin-top: 15px">
    <v-row no-gutters>
      <v-col class="">
        <v-card
          class="pa-0"
          style="background-color: #ffffff"
          tile
          elevation="0"
          width="100%"
        >
          <v-sheet color="transparent" min-height="50" elevation="0">
            <v-card elevation="0" width="800" class="mx-auto m-txt-center">
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-row>
                  <v-col cols="6">
                    <v-text-field
                        dense
                        outlined
                      v-model="first_name"
                      hint="Primeiro nome"
                      :label="getNamesToUpdate.first_name"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="6">
                    <v-text-field
                        dense
                        outlined
                      v-model="last_name"
                      hint="Último nome é obrigatório"
                      :label="getNamesToUpdate.last_name"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-text-field
                        dense
                        outlined
                      disabled
                      hint="Último nome é obrigatório"
                      :label="getNamesToUpdate.email"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-form>
              <div class="mt-6 text-left">
                <v-btn @click="updateUser" depressed color="success">
                  Actualizar
                </v-btn>
              </div>
            </v-card>
            <div
              style="
                border-bottom: 1px dashed #f6f6f6;
                padding: 10px;
                background-color: #ffffff;
              "
            ></div>
            <div
              style="
                border-bottom: 1px dashed #f6f6f6;
                padding: 10px;
                background-color: #ffffff;
              "
            ></div>
            <div style="padding-top: 100px"></div>
          </v-sheet>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapHelper } from "../../store/helper";

export default {
  name: "CardEditUser",
  data: () => ({
    valid: true,
    first_name: "",
    last_name: "",
  }),
  methods: {
    updateUser() {
      let obj = {
        first_name: this.first_name,
        last_name: this.last_name,
        id: this.$route.params.id,
      };
      this.$store.dispatch("callUpdateUser", obj);
    },
  },
  computed: {
    ...mapHelper,
  },
};
</script>

<style scoped></style>
