<template>
  <div style="padding: 5px">
    <v-combobox
      v-model="select"
      filled
      :items="getInvoiceType"
      :label="
        select ? 'Documento Selecionado' : 'Seleccione o tipo de Documento'
      "
      append-icon="mdi-clipboard-edit"
      chips
      @change="invoiceType"
    >
      <template v-slot:selection="data">
        <v-chip
          color="orange lighten-4"
          :key="JSON.stringify(data.item)"
          v-bind="data.attrs"
          :input-value="data.selected"
          :disabled="data.disabled"
          @click:close="data.parent.selectItem(data.item)"
        >
          <v-avatar
            class="accent white--text"
            left
            v-text="splitDoc(data.item)"
          ></v-avatar>
          {{ data.item }}
        </v-chip>
      </template>
    </v-combobox>
  </div>
</template>

<script>
import { mapHelper } from "../../store/helper";

export default {
  name: "CardInvoiceType",
  data: () => ({
    select: "Factura",
    items: [
      "Factura",
      "Factura Recibo",
      "Factura Pró-Forma",
      "Nota de Crédito",
      // "Guia de Transporte",
    ],
  }),

  methods: {
    invoiceType() {
      switch (this.select) {
        case "Factura":
          this.$store.dispatch("setViewDocFT");
          this.$store.dispatch("setStoreDocType", "Factura");
          break;
        case "Factura Recibo":
          this.$store.dispatch("setViewDocFR");
          this.$store.dispatch("setStoreDocType", "Factura Recibo");
          break;

        case "Factura Pró-Forma":
          this.$store.dispatch("setViewDocFP");
          this.$store.dispatch("setStoreDocType", "Factura Pró-Forma");
          break;

        case "Nota de Crédito":
          this.$store.dispatch("setViewDocNC");
          this.$store.dispatch("setStoreDocType", "Nota de Crédito");
          break;

        case "Guia de Transporte":
          this.$store.dispatch("setViewDocGD");
          this.$store.dispatch("setStoreDocType", "Guia de Transporte");
          break;
        default:
          this.$store.dispatch("setViewDocFT");
      }
    },
    splitDoc(txt) {
      const str = txt.split(" ");
      const weigth = str.length;
      if (weigth > 1) {
        return (
          str[0].slice(0, 1).toUpperCase() + str[1].slice(0, 1).toUpperCase()
        );
      }
      return txt.slice(0, 1).toUpperCase();
    },
  },
  computed: {
    ...mapHelper,
  },
};
</script>

<style scoped>
.theme--light.v-input input,
.theme--light.v-input textarea {
  color: #ffffff !important;
}

.theme--light.v-text-field >>> .v-input__control > .v-input__slot:before {
  border-color: #ffffff4f !important;
}
</style>
