import { mapGetters, mapActions } from "vuex";

export const mapHelper = {
  ...mapGetters([
    "getFormLogin",
    "getFormResetLogin",
    "getFormChangeLogin",
    "getYourIp",
    "getYourBrowser",
    "getCommercialNameEntity",
    "getNameEntity",
    "getNifEntity",
    "getTitleNavGlobal",
    "getClient",
    "getStoreInvoice",
    "getArticles",
    "getModalUpdateArticle",
    "getViewDocFt",
    "getViewDocFr",
    "getViewDocFp",
    "getViewDocGd",
    "getViewDocNc",
    "getInvoiceType",
    "getStoreClient",
    "getStoreDocType",
    "getTax",
    "getTaxFree",
    "getDocDisc",
    "getTotalGlobal",
    "getIdInvoiceToPrint",
    "getSubscriptionOnStore",
    "getAddressEntity",
    "getEntityCellphone",
    "getEntityDomain",
    "getAllUsers",
    "getNamesToUpdate",
    "getClientToUpdate",
    "getSaveEditArticles",
    "getTotalFinal",
    "getTotalDiscount",
    "getGatewayKyami",
    "getOrderOfStore",
    "getSaveEditArticlesBought",
    "getProfile",
    "getRole",
    "getTotalFinalFreeTax",
    "getTotalFinalTax",
    "getBankAccount",
    "getImageToInvoice",
  ]),
  ...mapActions({
    setLogout: "logout",
  }),
};
