import store from "../store";
export function genericAuth(to, from, next, roles) {
  const isAuthenticated = localStorage.getItem("cookie_vt");
  const role_ = localStorage.getItem("role_");
  let role = store.getters.getRole;
  const allowedRoles = roles;
  let userRoles;
  if (role) {
    userRoles = role;
  } else if (role_) {
    userRoles = atob(role_);
  }
  if (!isAuthenticated) {
    next({ name: "login" });
    return;
  }
  const hasPermission = allowedRoles.includes(userRoles);

  if (hasPermission) {
    next();
  } else {
    next({ name: "login" });
  }
}
