<template>
  <div>
    <v-container>
      <v-card
        class="mx-auto m-txt-center transparent mt-10"
        width="700"
        elevation="0"
      >
        <div class="sans title-size mx-auto">
          <strong>Compare os planos</strong>
        </div>
        <div class="description mx-auto m-center m-txt-center">
          Cuidamos das descrições para poder escolher o plano que melhor se ajusta ao seu negócio.
        </div>
      </v-card>
      <v-row class="fill-height mt-12" align="center" justify="center">
        <!--First Spoiller-->

        <v-col lg="12">
          <v-card
            class="pa-2 draw-svg"
            color="#ffffff"
            elevation="0"
            min-height="200"
          >
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th
                      class="text-left font-weight-bold"
                      style="color: black !important"
                    >
                      Recursos
                    </th>
                    <th
                      class="text-left font-weight-bold"
                      style="color: black !important"
                    >
                      Essencial
                    </th>
                    <th
                      class="text-left font-weight-bold"
                      style="color: black !important"
                    >
                      Master
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in desserts" :key="index">
                    <td>{{ item.resource }}</td>
                    <td><v-icon color="#ae7402">mdi-check</v-icon> {{ item.essential }}</td>
                    <td><v-icon color="#ae7402">mdi-check</v-icon> {{ item.master }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </v-col>
      </v-row>

      <v-row class="fill-height" align="center" justify="center">
        <v-col lg="12">
          <v-card
            class="pa-2 mx-auto"
            color="transparent"
            elevation="0"
            min-height="100"
          >
            <div class="m-txt-center">
              <v-btn large outlined color="blue" dark @click="createAccount">Começar a facturar</v-btn>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "ComparisonPriceComponent",
  data: () => ({
    desserts: [
      {
        resource: "Actualizações Gratuitas",
        essential: "",
        master: "",
      },
      {
        resource: "Certificado AGT",
        essential: "",
        master: "",
      },
      {
        resource: "Utilizadores",
        essential: 3,
        master: "Ilimitados",
      },
      {
        resource: "Documentos ilimitados",
        essential: "",
        master: "",
      },
      {
        resource: "Impressão documentos A4",
        essential: "",
        master: "",
      },
      {
        resource: "SAFT AO",
        essential: "",
        master: "",
      },
      {
        resource: "Permissão de utilizadores",
        essential: "",
        master: "",
      },
      {
        resource: "Logotipo na factura",
        essential: "",
        master: "",
      },
    ],
  }),
  methods: {
    createAccount(){
      return this.$router.push({ name: "create.account" });
    }
  }
};
</script>

<style scoped>
.sans {
  /*font-family: "Source Sans Pro", sans-serif;*/
  font-family: "Fira Sans Condensed", sans-serif;
  /*color: #3e3e42;*/
  color: #32363b;
  line-height: 1.2;
}
.description {
  font-family: "Source Sans Pro", sans-serif;
  color: #616266;
  line-height: 1;
  font-size: 16px;
}
.spoiler-description {
  font-family: "Source Sans Pro", sans-serif;
  color: #ffffff;
  /*line-height: 1;*/
  font-size: 18px;
}
.title-size {
  font-size: 38px;
}

:root {
  --text-color: #616266;
  --header: #32363b;
  --background: #32363b;
}
.spoiler-size {
  font-size: 40px !important;
}
.call-wid {
  /*max-width: 300px;*/
  padding: 40px;
  font-family: "Source Sans Pro", sans-serif;
  color: #2d2b2b !important;
  line-height: 1.2;
}
.draw-svg {
  /*background-image: linear-gradient(rgba(15, 15, 16, 0.5), rgb(0, 0, 0)),*/
  /*url("../../assets/img/callcenter.jpg");*/
  /*, linear-gradient(rgba(0, 0, 255, 0.5), rgba(255, 255, 0, 0.5));*/
  background-position: 30% 75%;
  background-size: cover;
  /*background-position: 50% 226%;*/
  /*background-position: 85% -68%;    */
}
.office-svg {
  /*background-image: linear-gradient(rgba(15, 15, 16, 0.5), rgb(0, 0, 0)), url("../../assets/img/server.jpg");*/
  background-size: cover;
  /*, linear-gradient(rgba(0, 0, 255, 0.5), rgba(255, 255, 0, 0.5));*/
  /*background-position: 25% 75%;*/
  /*background-position: 50% 226%;*/
  /*background-position: 85% -68%;    */
  /*background-position: -36% -126%;*/
}
.spond-color {
  color: #2d2b2b;
}
</style>
