<template>
  <div class="text-center" style="padding: 5px">
    <v-bottom-sheet v-model="sheet" inset width="700" scrollable persistent>
      <v-sheet class="text-center" height="500px">
        <div>
          <v-card elevation="0">
            <template>
              <v-row justify="center">
                <v-card-text>
                  <span class="m-size-font-18 text-uppercase">{{
                    getModalUpdateArticle
                  }}</span>
                </v-card-text>
                <v-card-text>
                  <v-container>
                    <v-form novalidate ref="form" lazy-validation>
                      <v-row>
                        <v-col cols="12">
                          <v-text-field
                            v-model="obj.qty"
                            type="number"
                            label="Quantidade"
                            min="1"
                            id="qty"
                            required
                            :rules="quantityRule"
                            hint="Define a quantidade desejada"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" class="mt-n7">
                          <v-text-field
                            v-model="obj.price"
                            id="price"
                            label="Preço"
                            :rules="priceRule"
                            required
                            hint="Edite o preço"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" class="mt-n7">
                          <v-select
                            v-model="obj.tax"
                            id="iva"
                            :items="getTax"
                            item-text="tax"
                            item-value="value"
                            hint="% - Imposto"
                            persistent-hint
                            label="Imposto"
                            single-line
                            required
                          ></v-select>
                        </v-col>
                        <v-col cols="12" class="mt-n7">
                          <v-select
                            :items="getTaxFree"
                            label="Motivo de isenção"
                            id="taxFree"
                            required
                            hint="Escolha o Motivo de Isenção"
                          ></v-select>
                        </v-col>
                        <v-col cols="12" sm="6" class="mt-n7">
                          <v-text-field
                            v-model="obj.percentageDiscount"
                            id="pDiscount"
                            label="Desconto percentual"
                            hint="%"
                            type="number"
                            min="0"
                            :rules="globalZeroRule"
                            required
                            persistent-hint
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" class="mt-n7">
                          <v-text-field
                            disabled
                            v-model="obj.discount"
                            id="discount"
                            label="Desconto em dinheiro"
                            hint="Akz"
                            persistent-hint
                            required
                            :rules="globalZeroRule"
                            type="number"
                            min="0"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <!--                                    <v-btn color="blue darken-1" text @click="sheet = false">-->
                  <!--                                      Cancelar-->
                  <!--                                    </v-btn>-->
                  <v-btn color="success darken-1" @click="updateArticle">
                    Sair e Guardar
                  </v-btn>
                </v-card-actions>
              </v-row>
            </template>
          </v-card>
        </div>
      </v-sheet>
    </v-bottom-sheet>
  </div>
</template>

<script>
import eventBus from "../../main";
import { mapHelper } from "../../store/helper";
export default {
  name: "ModalUpdateArticle",
  data: () => ({
    sheet: false,
    obj: Object,
    globalZeroRule: [
      (v) => {
        if (parseInt(v) >= 0) return true;
        return "Valor é requerido";
      },
    ],
    quantityRule: [
      (v) => !!v || "Quantidade é requerida",
      (v) => (v && v >= 1) || "Quantidade mínima é 1",
    ],
    priceRule: [
      (v) => !!v || "Preço é requerido",
      (v) => (v && v >= 0) || "O preço mínimo deve ser 0",
    ],
  }),

  methods: {
    // insertArticle(item) {
    //   if (item) {
    //     this.$store.dispatch("setStoreInvoice", item);
    //     eventBus.$emit("openInsertArticle", false);
    //   }
    // },

    cancel() {
      this.sheet = false;
    },
    updateArticle() {
      //this.$refs.form.validate()
      let qty = document.getElementById("qty");
      let price = document.getElementById("price");
      let pDiscount = document.getElementById("pDiscount");
      let discount = document.getElementById("discount");
      if (
        qty.value === "" ||
        qty.value < 1 ||
        price.value === "" ||
        price.value < 0 ||
        pDiscount.value === "" ||
        pDiscount.value < 0 ||
        discount.value === "" ||
        discount.value < 0
      ) {
        return false;
      }
      this.sheet = false;
    },
  },
  computed: {
    ...mapHelper,
  },
  created() {
    const self = this;
    eventBus.$on("openModalUpdateArticle", function (event) {
      self.sheet = event.dialog;
      self.obj = event.object;
    });
  },
};
</script>

<style scoped></style>
