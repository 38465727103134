<template>
  <div>
    <v-app>
      <bar-component></bar-component>
      <!-- Sizes your content based upon application components -->
      <v-main>
        <header-video></header-video>
        <header-content></header-content>
        <v-container fluid>
          <div style="margin-top: 50px; margin-bottom: 50px">
            <section-plan></section-plan>
          </div>
          <div style="margin-top: 50px; margin-bottom: 50px">
            <comparison-price-component></comparison-price-component>
          </div>
        </v-container>
        <div>
          <spoiler-component></spoiler-component>
        </div>
        <div>
          <contact></contact>
        </div>
      </v-main>
      <v-footer dark padless>
        <v-card
          flat
          tile
          class="black lighten-1 white--text text-center"
          width="100%"
        >
          <v-card-text>
            <v-btn
              v-for="icon in icons"
              :key="icon"
              class="mx-4 white--text"
              icon
            >
              <v-icon size="24px">
                {{ icon.icon }}
              </v-icon>
            </v-btn>
          </v-card-text>

          <v-card-text class="white--text pt-0"> </v-card-text>

<!--          <v-divider></v-divider>-->

          <v-card-text class="white--text">
            {{ new Date().getFullYear() }} — <strong>Digital Invoice</strong>
          </v-card-text>
        </v-card>
      </v-footer>
    </v-app>
  </div>
</template>

<script>
import BarComponent from "../../components/website/BarComponent";
import HeaderVideo from "../../components/website/HeaderVideo";
import HeaderContent from "../../components/website/HeaderContent";
import SectionPlan from "../../components/website/SectionPlan";
import ComparisonPriceComponent from "../../components/website/ComparisonPriceComponent";
import SpoilerComponent from "../../components/website/SpoilerComponent";
import Contact from "../../components/website/Contact";
export default {
  name: "LandingPage",
  components: {
    Contact,
    SpoilerComponent,
    ComparisonPriceComponent,
    SectionPlan,
    HeaderContent,
    HeaderVideo,
    BarComponent,
  },
  data: () => ({
    icons: [
        {icon: "mdi-linkedin", link: "#"},
        {icon: "mdi-youtube", link: "#"},
        {icon: "mdi-facebook", link: "#"},
        {icon: "mdi-instagram", link: "#"}
    ],
  }),
};
</script>

<style scoped>
/*.text-header {*/
/*  font-family: "Fira Sans Condensed", sans-serif;*/
/*  line-height: 42px;*/
/*  font-size: 38px;*/
/*  font-weight: 500;*/
/*}*/
</style>
