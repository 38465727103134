<template>
  <div style="padding: 0; margin-top: 15px">
    <v-row no-gutters>
      <v-col class="">
        <v-card
          class="pa-0"
          style="background-color: #ffffff"
          tile
          elevation="0"
          width="100%"
        >
          <v-sheet color="transparent" min-height="50" elevation="0">
            <v-card elevation="0" width="800" class="mx-auto m-txt-center">
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-row>
                  <v-col cols="6">
                    <v-text-field
                      dense
                      outlined
                      v-model="first_name"
                      :rules="nameRules"
                      hint="Primeiro nome"
                      label="Digite o Primeiro nome"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      dense
                      outlined
                      v-model="last_name"
                      :rules="nameRules"
                      hint="Último nome é obrigatório"
                      label="Digite o Último nome"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="6">
                    <v-text-field
                      dense
                      outlined
                      v-model="email"
                      :rules="emailRules"
                      hint="E-mail"
                      label="Digite o e-mail"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="6">
                    <v-text-field
                      dense
                      outlined
                      v-model="pwd"
                      :rules="pwdRules"
                      hint="Palavra-passe"
                      label="Digite a palavra-passe"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="6">
                    <v-combobox
                      dense
                      outlined
                      :rules="roleRules"
                      v-model="select"
                      :items="items"
                      label="Escolha a responsabilidade"
                    ></v-combobox>
                  </v-col>
                </v-row>
              </v-form>
              <v-card-text class="text-left">
                <b>Administrador</b>: Principal Gestor do Software
              </v-card-text>
              <v-card-text class="text-left mt-n8">
                <b>Gestor</b>: Operador com privilégios de inserir produtos,
                gerar documentos e inserir clientes
              </v-card-text>
              <v-card-text class="text-left mt-n8">
                <b>Operador</b>: Operador com privilégios de gerar docmunentos e
                inserir clientes
              </v-card-text>
              <v-card-text class="text-left mt-n8">
                <b>Contabilista</b>: Operador com privilégios de listar
                documentos (facturas)
              </v-card-text>
<!--              <v-card-text class="text-left mt-n8">-->
<!--                <b>Financeiro</b>: Operador com privilégios de listar ou-->
<!--                visualizar a saúde financeira (vendas) - Opção futura-->
<!--              </v-card-text>-->
              <div class="mt-6 text-left">
                <v-btn @click="updateUser" depressed color="success">
                  Adicionar
                </v-btn>
              </div>
            </v-card>
            <div
              style="
                border-bottom: 1px dashed #f6f6f6;
                padding: 10px;
                background-color: #ffffff;
              "
            ></div>
            <div
              style="
                border-bottom: 1px dashed #f6f6f6;
                padding: 10px;
                background-color: #ffffff;
              "
            ></div>
            <div style="padding-top: 100px"></div>
          </v-sheet>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapHelper } from "../../store/helper";

export default {
  name: "CardEntityAddUser",
  data: () => ({
    valid: true,
    first_name: "",
    last_name: "",
    pwd: "",
    email: "",
    select: "",
    items: [
      "Administrador",
      "Operador",
      "Contabilista",
      // "Financeiro",
      "Gestor",
    ],

    nameRules: [
      (v) => v.length <= 25 || "Máximo 25 caracteres",
      (v) => !!v || "Campo obrigatório",
    ],

    roleRules: [(v) => !!v || "Campo obrigatório"],

    pwdRules: [
      (v) => v.length >= 6 || "Mínimo 6 caracteres",
      (v) => !!v || "Campo obrigatório",
    ],
    emailRules: [
      (v) => v.trim().split(" ").length <= 40 || "Máximo 40 caracteres",
      (v) => /.+@.+\..+/.test(v) || "Correio inválido",
      (v) => !!v || "Campo obrigatório",
    ],
  }),
  methods: {
    updateUser() {
      if (this.$refs.form.validate()) {
        let obj = {
          first_name: this.first_name,
          last_name: this.last_name,
          pwd: this.pwd,
          email: this.email,
          role: this.select,
        };
        this.$store.dispatch("callAddUser", obj);
      }
    },
  },
  computed: {
    ...mapHelper,
  },
};
</script>

<style scoped></style>
